import { ROLE_NAMES } from '../../RBAC/config/roleNames.js';
import {
  SET_USERS,
  SET_IS_LOADING,
  SET_HAS_LOADING_ERROR,
  RESET_CURRENT_USER,
  SET_CURRENT_USER,
  SET_CURRENT_USER_IS_UPDATING,
  SET_CURRENT_USER_HAS_ERROR_UPDATING,
  SET_ROLE_COUNT,
  SET_ROLE_COUNT_IS_LOADING,
  SET_ROLE_COUNT_HAS_LOADING_ERROR,
  SET_CURRENT_USER_UPDATE,
  SET_REMEMBER_ME,
} from './actions.js';
import { initialState } from './initialState.js';

export const usersReducer = (state = initialState, action = {}) => {
  const stateCopy = { ...state };

  switch (action.type) {
    case SET_USERS:
      stateCopy.usersList = action.payload;
      return stateCopy;

    case SET_IS_LOADING:
      stateCopy.isLoading = action.payload;
      return stateCopy;

    case SET_HAS_LOADING_ERROR:
      stateCopy.hasLoadingError = action.payload;
      return stateCopy;

    case SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.payload,
      };

    case RESET_CURRENT_USER:
      return {
        ...state,
        currentUser: {},
        isSuperAdmin: false,
      };

    case SET_CURRENT_USER:
      const tenantRolesMap = action.payload.tenantRoles.reduce(
        (acc, role) => ({ ...acc, [role.name]: role.id }),
        {}
      );
      return {
        ...state,
        currentUser: {
          ...action.payload.user,
          tenantId: action.payload.tenantId,
          tenantRoles: action.payload.tenantRoles,
          tenantRolesMap,
        },
        isSuperAdmin: ROLE_NAMES.SUPER_ADMIN in tenantRolesMap,
      };

    case SET_CURRENT_USER_UPDATE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload.user,
        },
      };

    case SET_CURRENT_USER_IS_UPDATING:
      stateCopy.currentUserIsUpdating = action.payload;
      return stateCopy;

    case SET_CURRENT_USER_HAS_ERROR_UPDATING:
      stateCopy.currentUserHasUpdatingError = action.payload;
      return stateCopy;

    case SET_ROLE_COUNT:
      return {
        ...state,
        roleCount: action.payload,
        roleCountMap: action.payload.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.count }), {}),
      };

    case SET_ROLE_COUNT_IS_LOADING:
      return {
        ...state,
        roleCountIsLoading: action.payload,
      };

    case SET_ROLE_COUNT_HAS_LOADING_ERROR:
      return {
        ...state,
        roleCountHasLoadingError: action.payload,
      };

    default:
      return state;
  }
};
