import { PayloadAction } from '@reduxjs/toolkit';

import { ICollectionsState } from '../collections.interface';

const vizByCollectionIdReduxSlice = {
  initiateFetchVizByCollectionId: (state: ICollectionsState) => {
    state.vizByCollection.sortableArray = [];
    state.vizByCollection.hasErrors = false;
    state.vizByCollection.loading = true;
    state.vizByCollection.isReodered = false;
  },

  fetchVizByCollectionIdSuccess: (state: ICollectionsState, action: PayloadAction<any[]>) => {
    state.vizByCollection.sortableArray = action.payload;
    state.vizByCollection.hasErrors = false;
    state.vizByCollection.loading = false;
  },

  fetchVizByCollectionIdError: (state: ICollectionsState) => {
    state.vizByCollection.hasErrors = true;
    state.vizByCollection.loading = false;
  },

  setIsReordered: (state: ICollectionsState, action: PayloadAction<boolean>) => {
    state.vizByCollection.isReodered = action.payload;
  },

  updateSortableArray: (state: ICollectionsState, action: PayloadAction<any[]>) => {
    state.vizByCollection.isReodered = true;
    state.vizByCollection.sortableArray = action.payload;
  },

  initiateReorderArray: (state: ICollectionsState) => {
    state.vizByCollection.updatingOrderError = false;
    state.vizByCollection.updatingOrder = true;
  },

  reorderArraySuccess: (state: ICollectionsState) => {
    state.vizByCollection.updatingOrder = false;
    state.vizByCollection.isReodered = false;
  },

  reorderArrayError: (state: ICollectionsState) => {
    state.vizByCollection.updatingOrderError = true;
    state.vizByCollection.updatingOrder = false;
  },
};

export default vizByCollectionIdReduxSlice;
