import React, { Component } from 'react';

import ErrorMessageRefresh from './ErrorMessageRefresh';
import ErrorMessageRetry from './ErrorMessageRetry';
class ErrorBoundry extends Component {
  state = {
    error: false,
    info: '',
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const {
      callback,
      children,
      hasError,
      containerClass,
      errorMessageWidth = 492,
      accessDeniedWidth,
      testId,
    } = this.props;
    const { error } = this.state;

    const accessDenied = (
      <div
        test-id={testId || 'access-denied-error-boundry'}
        className={'flex flex-column items-center ' + (containerClass ? containerClass : ' mv4')}
      >
        <div
          className={'br3 pa4 bg-white ' + (accessDeniedWidth ? accessDeniedWidth : 'w-30')}
          style={{ boxShadow: '0 0 20px 0 rgba(0,0,0,0.2)' }}
        >
          <p className="cerise-red f2 mv2 tc">Access Denied</p>
          <p className="tc fw3">Sorry, you don't have permission to access this page</p>
        </div>
      </div>
    );

    if (error || hasError) {
      if (error.message == 401) {
        return accessDenied;
      } else {
        return callback ? (
          <ErrorMessageRetry
            containerProps={{ className: containerClass }}
            errorMessageProps={{
              style: {
                width: errorMessageWidth,
              },
            }}
            callback={callback}
            resetState={() => this.setState({ error: false, info: '' })}
          />
        ) : (
          <ErrorMessageRefresh
            containerProps={{ className: containerClass }}
            errorMessageProps={{
              style: {
                width: errorMessageWidth,
              },
            }}
          />
        );
      }
    } else {
      return children;
    }
  }
}

export default ErrorBoundry;
