import {
  FETCH_TENANTS,
  FETCH_TENANTS_SUCCESS,
  FETCH_TENANTS_ERROR,
  FETCH_TENANT_ADMINS,
  FETCH_TENANT_ADMINS_SUCCESS,
  FETCH_TENANT_ADMINS_ERROR,
  setTenants,
  setIsLoading,
  setHasLoadingError,
  setTenantAdmins,
  setAdminIsLoading,
  setAdminHasLoadingError,
} from './actions';

import { apiRequest } from '../Api/actions';
import url from '../../api-config';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_TENANTS:
        dispatch(setHasLoadingError(false));
        dispatch(setIsLoading(true));
        dispatch(apiRequest('GET', url.tenants, {}, FETCH_TENANTS_SUCCESS, FETCH_TENANTS_ERROR));
        break;

      case FETCH_TENANT_ADMINS:
        dispatch(setHasLoadingError(false));
        dispatch(setIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            url.tenantAdmins,
            {},
            FETCH_TENANT_ADMINS_SUCCESS,
            FETCH_TENANT_ADMINS_ERROR
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_TENANTS_SUCCESS:
        dispatch(setIsLoading(false));
        dispatch(setTenants(action.payload));
        break;

      case FETCH_TENANTS_ERROR:
        dispatch(setIsLoading(false));
        dispatch(setHasLoadingError(true));
        break;

      case FETCH_TENANT_ADMINS_SUCCESS:
        dispatch(setAdminIsLoading(false));
        dispatch(setTenantAdmins(action.payload));
        break;

      case FETCH_TENANT_ADMINS_ERROR:
        dispatch(setAdminIsLoading(false));
        dispatch(setAdminHasLoadingError(true));
        break;

      default:
        break;
    }
  };

export const tenantsModel = [fetchData, processCollections];
