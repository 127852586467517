import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  IRelationOptionItem,
  IRelationOptions,
  existingRelationsMapType,
  outGoingConcepts,
} from '../constants/relations.interface';
import {
  IQuestionDefinition,
  IConcept,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';

interface IRelationsModalState {
  isAdvancedMode: boolean;
  removeAdvancedModeWarning: boolean;
  existingRelationsMap: existingRelationsMapType;
  relationOptions: outGoingConcepts;
  addedConcepts: IQuestionDefinition;
  addedRelationInstances: IRelationOptionItem[];
}

export const initialState: IRelationsModalState = {
  isAdvancedMode: false,
  removeAdvancedModeWarning: false,
  existingRelationsMap: { parent: {}, subQueries: {} },
  relationOptions: { parent: [], subQueries: {} },
  addedConcepts: {},
  addedRelationInstances: [],
};

export const relationsModalReducer = {
  resetState: (state: IRelationsModalState) => {
    state.isAdvancedMode = initialState.isAdvancedMode;
    state.removeAdvancedModeWarning = initialState.removeAdvancedModeWarning;
    state.existingRelationsMap = initialState.existingRelationsMap;
    state.relationOptions = initialState.relationOptions;
    state.addedConcepts = initialState.addedConcepts;
    state.addedRelationInstances = initialState.addedRelationInstances;
  },

  initState: (
    state: IRelationsModalState,
    action: PayloadAction<{
      rels: IRelationOptions;
      isAdvancedMode: boolean;
    }>
  ) => {
    const { rels, isAdvancedMode } = action.payload;

    state.isAdvancedMode = isAdvancedMode;
    state.existingRelationsMap = rels.existingRelationsMap;
    state.relationOptions = rels.relationOptions;
  },

  toggleAdvancedMode: (state: IRelationsModalState) => {
    state.isAdvancedMode = !state.isAdvancedMode;
  },

  toggleRemoveAdvancedModeWarning: (state: IRelationsModalState) => {
    state.removeAdvancedModeWarning = !state.removeAdvancedModeWarning;
  },

  removeAdvancedMode: (state: IRelationsModalState) => {
    state.isAdvancedMode = !state.isAdvancedMode;
    state.removeAdvancedModeWarning = !state.removeAdvancedModeWarning;
  },

  addNewConcept: (
    state: IRelationsModalState,
    action: PayloadAction<{
      newConceptId: string;
      newConcept: IConcept;
      newRelation: IRelationOptionItem;
    }>
  ) => {
    const { newConcept, newConceptId, newRelation } = action.payload;

    state.addedConcepts = {
      ...state.addedConcepts,
      [newConceptId]: newConcept,
    };
    state.addedRelationInstances.push(newRelation);
  },
};

export const relationsModalSlice = createSlice({
  name: 'relationsModal',
  initialState: initialState,
  reducers: relationsModalReducer,
});

export const {
  resetState,
  removeAdvancedMode,
  toggleAdvancedMode,
  toggleRemoveAdvancedModeWarning,
  initState,
  addNewConcept,
} = relationsModalSlice.actions;

export default relationsModalSlice.reducer;
