import { IndexRouteObject, NonIndexRouteObject } from 'react-router-dom-v5-compat';

import { HomeRoutes } from '../../../../../RouteConstants';

export const HomeLayoutRoutes: (IndexRouteObject | NonIndexRouteObject)[] = [
  {
    id: 'welcome',
    index: true,
    async lazy() {
      const HomeLayoutNonLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutNonLoginRoutes')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;
      const Welcome = (await import('../../../../../../Modules/Home/Welcome/Welcome')).default;

      return {
        errorElement: <ErrorPage />,
        element: <HomeLayoutNonLoginRoutes content={<Welcome />} />,
      };
    },
  },
  {
    path: HomeRoutes.terms,
    async lazy() {
      const HomeLayoutNonLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutNonLoginRoutes')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;
      const TermsAndConditions = (
        await import('../../../../../../Modules/Home/TermsAndConditions/TermsAndConditions')
      ).default;

      return {
        errorElement: <ErrorPage />,
        element: <HomeLayoutNonLoginRoutes content={<TermsAndConditions />} />,
      };
    },
  },
  {
    path: HomeRoutes.support,
    async lazy() {
      const HomeLayoutNonLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutNonLoginRoutes')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;
      const Support = (await import('../../../../../../Modules/Home/Support/Support')).default;

      return {
        errorElement: <ErrorPage />,
        element: <HomeLayoutNonLoginRoutes content={<Support />} />,
      };
    },
  },
];
