import checkTokenExpiry from './checkTokenExpiry';
import { app_json_content_type, getAuthToken } from './getAuthHeaders';
import handleResponseBlob from './handleResponseBlob';
import handleResponseText from './handleResponseText';
import parseResponse from './parseResponse';

const getOptions = (opts = { headers: {} }, omitContentTypeHeader) => {
  const AuthHeader = {
    Authorization: getAuthToken(),
  };

  if (omitContentTypeHeader) {
    return {
      ...opts,
      headers: {
        ...AuthHeader,
        ...opts?.headers,
      },
    };
  } else {
    return {
      ...opts,
      headers: {
        ...AuthHeader,
        'Content-Type': opts?.headers?.['Content-Type'] || app_json_content_type,
        ...opts?.headers,
      },
    };
  }
};

const makeRequest = async (
  url,
  opts = { headers: {} },
  returnRequestAsText = false,
  omitContentTypeHeader = false
) => {
  const options = getOptions(opts, omitContentTypeHeader);

  const response = await fetch(url, options);

  const res = await handleResponseText(response);
  if (returnRequestAsText) {
    return res;
  } else {
    return parseResponse(res);
  }
};

const makeRequestBlob = async (url, opts = { headers: {} }) => {
  const response = await fetch(url, {
    ...opts,
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/zip',
      ...opts?.headers,
    },
  });

  return await handleResponseBlob(response);
};

export const fetchInterceptBlob = async (url, opts = {}) => {
  await checkTokenExpiry();
  return await makeRequestBlob(url, opts);
};

export const fetchIntercept = async (url, opts = {}, returnRequestAsText) => {
  await checkTokenExpiry();
  return await makeRequest(url, opts, returnRequestAsText, false);
};

export const fetchInterceptFile = async (url, opts = {}, returnRequestAsText) => {
  await checkTokenExpiry();
  return await makeRequest(url, opts, returnRequestAsText, true);
};
