import handleResponseError from './handleResponseError';
import { handleInvalidToken } from '../../utils/Auth';

/**
 * handleResponseText - helper function to resolve a text response
 * @param {Object} response
 * @returns Promise
 */
export default function handleResponseText(response) {
  if (response.ok) {
    return response.text();
  } else {
    handleInvalidToken(response);
    handleResponseError(response);
  }
}
