import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  columnNegativeOverrideInitialState,
  columnStyleInitialState,
  listDataColumn,
  listDataColumnConditionsInitialState,
  listDataColumnsInitialState,
} from '../constants/conditions.constant';
import { IListColumnData, IListColumnsState } from '../constants/conditions.interface';
import { updateListDataColumnsFormattingReduxSlice } from './list-data-column-formatting-redux-slice';
import { updateListDataColumnConditionsReduxSlice } from './list-data-columns-conditions-redux-slice';

const updateListDataColumnsReduxSlice = {
  resetListDataColumns: (state: IListColumnsState) => {
    state.columns = [];
  },

  initListDataColumns: (
    state: IListColumnsState,
    action: PayloadAction<{ columns: IListColumnData[] }>
  ) => {
    if (action.payload.columns.length === 0) {
      state.columns = [listDataColumn];
    } else {
      state.columns = action.payload.columns.map((item) => ({
        ...item,
        // set conditions, for existing list viz's, that were created before this feature
        conditions: item.conditions || listDataColumnConditionsInitialState.conditions,
        columnStyle: item.columnStyle || columnStyleInitialState,
        columnNegativeOverride: item.columnNegativeOverride || columnNegativeOverrideInitialState,
      }));
    }
  },

  addDataColumn: (state: IListColumnsState) => {
    state.columns.push(listDataColumn);
  },

  removeDataCol: (state: IListColumnsState, action: PayloadAction<{ index: number }>) => {
    if (action.payload.index === 0 && state.columns.length === 1) {
      state.columns = [listDataColumn];
    } else {
      const updatedCols = state.columns.filter(
        (item, itemIndex) => itemIndex !== action.payload.index
      );
      state.columns = updatedCols;
    }
  },

  updateColumnPropsAtIndex: (
    state: IListColumnsState,
    action: PayloadAction<{ index: number; column: IListColumnData }>
  ) => {
    state.columns[action.payload.index] = action.payload.column;
  },
};

const ListDataColumnsReduxSlice = createSlice({
  name: 'listDataColumns',
  initialState: listDataColumnsInitialState,
  reducers: {
    ...updateListDataColumnsReduxSlice,
    ...updateListDataColumnConditionsReduxSlice,
    ...updateListDataColumnsFormattingReduxSlice,
  },
});

export const {
  resetListDataColumns,
  updateColumnPropsAtIndex,
  addDataColumn,
  removeDataCol,
  initListDataColumns,

  updateUseCondition,
  resetConditions,
  updateCompareToType,
  deleteCondition,
  updateValue,
  updateCondition,
  updateTextColor,
  updateBackgroundColor,
  updateStyles,
  updateNegativeOverride,
  updateActiveTab,

  toggleApplyColumnStyle,
  updateColumnColor,
  updateColumnBackgroundColor,
  updateColumnAlignment,
  updateColumnStyle,

  toggleNegativeFormatting,
  updateNegativeFormattingColor,
  updateNegativeFormattingBackgroundColor,
  updateUseAccountingStyle,
  updateNegativeFormattingStyle,
} = ListDataColumnsReduxSlice.actions;

export default ListDataColumnsReduxSlice.reducer;
