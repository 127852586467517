import {
  FETCH_API_LIST,
  FETCH_API_LIST_SUCCESS,
  FETCH_API_LIST_ERROR,
  fetchApiList,
  setApiList,
  setApiListIsLoading,
  setApiListHasLoadingError,
  FETCH_API_DETAILS,
  FETCH_API_DETAILS_SUCCESS,
  FETCH_API_DETAILS_ERROR,
  setApiDetails,
  setApiDetailsIsLoading,
  setApiDetailsHasLoadingError,
  PUBLISH_API,
  PUBLISH_API_SUCCESS,
  PUBLISH_API_ERROR,
  PUBLISH_QUERY_API,
  PUBLISH_QUERY_API_SUCCESS,
  PUBLISH_QUERY_API_ERROR,
  setPublishingApi,
  setPublishApiSuccess,
  setUnPublishApiSuccess,
  setPublishApiError,
  UPDATE_API_KEY,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_ERROR,
  setUpdatingApiKey,
  setUpdateApiKeySuccess,
  setUpdateApiKeyError,
  FETCH_QUERY_API_ID,
  FETCH_QUERY_API_ID_SUCCESS,
  FETCH_QUERY_API_ID_ERROR,
  fetchQueryApiId,
  setQueryApiId,
  setQueryApiIdIsLoading,
  setQueryApiIdHasLoadingError,
} from './actions';

import { apiRequest } from '../../Api/actions';
import url from '../../../api-config';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_API_LIST:
        dispatch(setApiListHasLoadingError(false));
        dispatch(setApiListIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            url.studioApi,
            action.data,
            FETCH_API_LIST_SUCCESS,
            FETCH_API_LIST_ERROR
          )
        );
        break;

      case FETCH_API_DETAILS:
        dispatch(setApiDetailsHasLoadingError(false));
        dispatch(setApiDetailsIsLoading(true));

        dispatch(setPublishApiSuccess(false));
        dispatch(setPublishApiError(false));

        dispatch(setUpdateApiKeySuccess(false));
        dispatch(setUpdateApiKeyError(false));

        // TODO: update url
        dispatch(
          apiRequest(
            'GET',
            url.studioApi,
            action.data,
            FETCH_API_DETAILS_SUCCESS,
            FETCH_API_DETAILS_ERROR
          )
        );
        break;

      case PUBLISH_API:
        dispatch(setPublishApiSuccess(false));
        dispatch(setUnPublishApiSuccess(false));
        dispatch(setPublishApiError(false));
        dispatch(setPublishingApi(true));
        const baseUrl = `${url.studioApi}/${action.apiId}`;
        const isUnPublish = !action.publish ? 'un' : '';
        const data = !action.publish ? { apiId: action.apiId } : action.data;
        dispatch(
          apiRequest(
            'PUT',
            `${baseUrl}/${isUnPublish}publish`,
            data,
            PUBLISH_API_SUCCESS,
            PUBLISH_API_ERROR,
            {
              queryId: action.data ? action.data.queryId : undefined,
              isUnPublish: !action.publish,
            }
          )
        );
        break;

      case PUBLISH_QUERY_API:
        dispatch(setPublishApiSuccess(false));
        dispatch(setPublishApiError(false));
        dispatch(setPublishingApi(true));
        dispatch(
          apiRequest(
            'POST',
            `${url.studioApi}/${action.queryId}`,
            { queryId: action.queryId },
            PUBLISH_QUERY_API_SUCCESS,
            PUBLISH_QUERY_API_ERROR
          )
        );
        break;

      case UPDATE_API_KEY:
        dispatch(setUpdateApiKeySuccess(false));
        dispatch(setUpdateApiKeyError(false));
        dispatch(setUpdatingApiKey(true));
        const isRemove = action.isRemove ? 'remove' : 'add';
        dispatch(
          apiRequest(
            'PUT',
            `${url.studioApi}/${action.apiId}/${isRemove}ApiKey`,
            { apiId: action.apiId },
            UPDATE_API_KEY_SUCCESS,
            UPDATE_API_KEY_ERROR,
            { queryId: action.queryId }
          )
        );
        break;

      case FETCH_QUERY_API_ID:
        dispatch(setQueryApiId({}));
        dispatch(setQueryApiIdIsLoading(true));
        dispatch(setQueryApiIdHasLoadingError(false));
        dispatch(
          apiRequest(
            'GET',
            `${url.studioApi}/query/${action.payload}`,
            action.data,
            FETCH_QUERY_API_ID_SUCCESS,
            FETCH_QUERY_API_ID_ERROR
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_API_LIST_SUCCESS:
        dispatch(setApiListIsLoading(false));
        dispatch(setApiList(action.payload));
        break;

      case FETCH_API_LIST_ERROR:
        dispatch(setApiListIsLoading(false));
        dispatch(setApiListHasLoadingError(true, action.payload.status));
        break;

      case FETCH_API_DETAILS_SUCCESS:
        dispatch(setApiDetailsIsLoading(false));
        dispatch(setApiDetails(action.payload));
        break;

      case FETCH_API_DETAILS_ERROR:
        dispatch(setApiDetailsIsLoading(false));
        dispatch(setApiDetailsHasLoadingError(true));
        break;

      case PUBLISH_API_SUCCESS:
        // re-fetch queryApi to update apiKey in model
        if (action.props.queryId !== undefined) {
          dispatch(fetchQueryApiId(action.props.queryId));
        } else {
          dispatch(setPublishApiSuccess(true));
          dispatch(setPublishingApi(false));
        }
        dispatch(fetchApiList());

        if (action.props.isUnPublish) {
          dispatch(setUnPublishApiSuccess(true));
        }
        break;

      case PUBLISH_API_ERROR:
        dispatch(setPublishApiError(true));
        dispatch(setPublishingApi(false));
        break;

      case PUBLISH_QUERY_API_SUCCESS:
        dispatch(setPublishApiSuccess(true));
        dispatch(setPublishingApi(false));
        dispatch(setQueryApiId(action.payload));
        break;

      case PUBLISH_QUERY_API_ERROR:
        dispatch(setPublishApiError(true));
        dispatch(setPublishingApi(false));
        break;

      case UPDATE_API_KEY_SUCCESS:
        // re-fetch queryApi to update apiKey in model
        if (action.props.queryId !== undefined) {
          dispatch(fetchQueryApiId(action.props.queryId));
        } else {
          dispatch(setUpdatingApiKey(false));
          dispatch(setUpdateApiKeySuccess(true));
        }
        dispatch(fetchApiList());
        break;

      case UPDATE_API_KEY_ERROR:
        dispatch(setUpdatingApiKey(false));
        dispatch(setUpdateApiKeySuccess(false));
        dispatch(setUpdateApiKeyError(true));
        break;

      case FETCH_QUERY_API_ID_SUCCESS:
        // used in query creation screen
        dispatch(setUpdateApiKeySuccess(false));
        dispatch(setPublishApiSuccess(true));
        dispatch(setPublishingApi(false));

        dispatch(setQueryApiIdIsLoading(false));
        dispatch(setQueryApiId(action.payload));
        break;

      case FETCH_QUERY_API_ID_ERROR:
        dispatch(setQueryApiIdIsLoading(false));
        dispatch(setQueryApiIdHasLoadingError(true));
        dispatch(setQueryApiId({}));
        break;

      default:
        break;
    }
  };

export const studioApiModel = [fetchData, processCollections];
