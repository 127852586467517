import Features from '../../../../../Features/feature-flags';
import { ILinks } from '../../../../../components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';
import { BlossomRoutes } from '../../../../RouteConstants';

export const links: ILinks[] = [
  {
    tabItem: {
      to: `/${BlossomRoutes.tenantAdmin.base}/${BlossomRoutes.tenantAdmin.user.base}`,
      label: 'Users',
      variant: 'primary',
      testId: 'customer-management-users-tab',
    },
    permissionRef: BlossomRoutes.tenantAdmin.user.permissions,
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${BlossomRoutes.tenantAdmin.base}/${BlossomRoutes.tenantAdmin.dataSecurity.base}`,
      label: 'Data Security',
      variant: 'primary',
      testId: 'data-security-tab',
    },
    permissionRef: BlossomRoutes.tenantAdmin.dataSecurity.permissions,
    featureFlag: (Features as any).clientAccess,
  },
  {
    tabItem: {
      to: `/${BlossomRoutes.tenantAdmin.base}/${BlossomRoutes.tenantAdmin.recovery.base}`,
      label: 'Recovery',
      variant: 'primary',
      testId: 'recovery-tab',
    },
    permissionRef: BlossomRoutes.tenantAdmin.recovery.permissions,
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${BlossomRoutes.tenantAdmin.base}/${BlossomRoutes.tenantAdmin.security.base}`,
      label: 'Security',
      variant: 'primary',
      testId: 'security-tab',
    },
    permissionRef: BlossomRoutes.tenantAdmin.security.permissions,
    featureFlag: true,
  },
];
