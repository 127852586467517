import { FC, ReactElement, useContext } from 'react';

import KobaiDefaultLogo from './Theme/KobaiDefaultLogo';
import ErrorBoundry from '../../../components/ErrorBoundry/ErrorBoundry';
import HomeContent from './HomeContent';
import ThemedSpinner from './Theme/ThemedSpinner';
import ThemedGradient from './Theme/ThemedGradient';
import { context } from '../../../components/Theme/Context';
import './HomeContainer.css';

const AppLoading: FC = (): ReactElement => {
  const theme = useContext(context);

  return (
    <div className={`home is-loading w-100 vh-100 flex flex-column items-center bg-fafafa Roboto`}>
      <section data-testid="loading-container" className="home-container bg-white mt5 flex">
        <article
          className="home-messaging pa4 relative overflow-hidden flex flex-column items-center"
          style={{
            color: theme.colors.foreground,
          }}
        >
          <KobaiDefaultLogo />

          <ThemedSpinner isVisible={true} />

          <ThemedGradient />
        </article>
        <ErrorBoundry>
          <HomeContent>
            <></>
          </HomeContent>
        </ErrorBoundry>
      </section>

      <footer className="w-100 flex justify-center mt4 fw1">
        <span>© {new Date().getFullYear()} Kobai Inc.</span>
      </footer>
    </div>
  );
};
export default AppLoading;
