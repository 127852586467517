const policyList = Object.freeze({
  viewStudio: 0,
  writeStudio: 1,
  viewDataMapping: 2,
  writeDataMapping: 3,
  viewUserManagement: 4,
  writeUserManagement: 5,
  viewApi: 6,
  writeApi: 7,
  viewTenant: 8,
  writeTenant: 9,
  viewAccountInfo: 10,
  editAccountInfo: 11,
  viewQueries: 12,
  writeQueries: 13,
  viewSecurity: 14,
  writeSecurity: 15,
  viewAdmins: 16,
  writeAdmins: 17,
  viewProfile: 18,
  writeProfile: 19,
  writeTower360: 20,
  editQuestionOwner: 21,
  0: 'viewStudio',
  1: 'writeStudio',
  2: 'viewDataMapping',
  3: 'writeDataMapping',
  4: 'viewUserManagement',
  5: 'writeUserManagement',
  6: 'viewApi',
  7: 'writeApi',
  8: 'viewTenant',
  9: 'writeTenant',
  10: 'viewAccountInfo',
  11: 'editAccountInfo',
  12: 'viewQueries',
  13: 'writeQueries',
  14: 'viewSecurity',
  15: 'writeSecurity',
  16: 'viewAdmins',
  17: 'writeAdmins',
  18: 'viewProfile',
  19: 'writeProfile',
  20: 'writeTower360',
  21: 'editQuestionOwner',
});

const statements = (policy) => {
  switch (policy) {
    //viewStudio
    case 0:
    case policyList[0]:
      return {
        models: 'readAll',
      };

    //writeStudio
    case 1:
    case policyList[1]:
      return {
        models: 'all',
      };

    //viewDataMapping
    case 2:
    case policyList[2]:
      return {
        mappings: 'readAll',
        datasources: 'readAll',
        datasets: 'readAll',
      };

    //writeDataMapping
    case 3:
    case policyList[3]:
      return {
        mappings: 'all',
        datasources: 'all',
        datasets: 'all',
        ingestions: 'all',
      };

    //viewUserManagement
    case 4:
    case policyList[4]:
      return {
        users: 'readAll',
      };

    //writeUserManagement
    case 5:
    case policyList[5]:
      return {
        users: 'all',
      };

    //viewApi
    case 6:
    case policyList[6]:
      return {
        apis: 'readAll',
      };

    //writeApi
    case 7:
    case policyList[7]:
      return {
        apis: 'all',
      };

    //viewTenant
    case 8:
    case policyList[8]:
      return {
        tenants: 'readAll',
      };

    //writeTenant
    case 9:
    case policyList[9]:
      return {
        tenants: 'all',
      };

    //viewAccountInfo
    case 10:
    case policyList[10]:
      return {
        accounts: 'readAll',
      };

    //writeAccountInfo
    case 11:
    case policyList[11]:
      return {
        accounts: 'all',
      };

    //viewQueries
    case 12:
    case policyList[12]:
      return {
        queries: 'readAll',
      };

    //writeQueries
    case 13:
    case policyList[13]:
      return {
        queries: 'all',
      };

    //viewSecurity
    case 14:
    case policyList[14]:
      return {
        security: 'readAll',
      };

    //writeSecurity
    case 15:
    case policyList[15]:
      return {
        security: 'all',
      };

    //readAdmins
    case 16:
    case policyList[16]:
      return {
        admins: 'readAll',
      };

    //writeAdmins
    case 17:
    case policyList[17]:
      return {
        admins: 'all',
      };

    //viewProfile
    case 18:
    case policyList[18]:
      return {
        profiles: 'readAll',
      };

    //writeProfile
    case 19:
    case policyList[19]:
      return {
        profiles: 'all',
      };

    // write tower360 config
    case 20:
    case policyList[20]:
      return {
        tower360: 'all',
      };

    // change question owner
    case 21:
    case policyList[21]:
      return {
        questionOwner: 'all',
      };

    default:
      return [];
  }
};
const Policies = {
  list: policyList,
  statements,
};
export default Policies;
