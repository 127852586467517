const rolesList = Object.freeze({
  enterpriseOwner: 0,
  administrator: 1,
  studioEditor: 2,
  dataMappingExpert: 3,

  0: 'enterpriseAdministrator',
  1: 'administrator',
  2: 'studioEditor',
  3: 'dataMappingExpert',
});

const description = (role) => {
  switch (rolesList[role]) {
    //Enterprise Owner
    case 0:
    case rolesList[0]:
      return 'The Enterprise Owner is the org owner. They have ful and complete rights and are able to create tenants.';

    //Administrator
    case 1:
    case rolesList[1]:
      return 'The Administrator is the tenant administrator. They have ful and complete rights to a tenant instance.';

    //Studio Editor
    case 2:
    case rolesList[2]:
      return 'The Studio Editor is able to create and edit studio models. They also are able to create and edit data mapping options.';

    //Data Mapping Expert
    case 3:
    case rolesList[3]:
      return 'The Studio Editor is able to create and edit studio models.';

    //Guest
    default:
      return 'Guests have access to certain APIs';
  }
};

const assignedPolicies = (role) => {
  switch (rolesList[role]) {
    //Enterprise Owner
    case 0:
    case rolesList[0]:
      // [viewTenant, writeTenant, viewSecurity, writeSecurity, readAdmins, writeAdmins]
      return [8, 9, 14, 15, 16, 17];

    //Administrator
    case 1:
    case rolesList[1]:
      // [ viewStudio, writeStudio, viewDataMapping, writeDataMapping, viewUserManagement, writeUserManagement, viewApi, writeApi, viewQueries, writeQueries, viewProfile, writeProfile, writeTower360, editQuestionOwner]
      return [0, 1, 2, 3, 4, 5, 6, 7, 12, 13, 18, 19, 20, 21];

    //Studio Editor
    case 2:
    case rolesList[2]:
      // [ viewStudio, writeStudio, viewDataMapping, viewApi, writeApi, viewQueries, writeQueries, viewProfile, writeProfile]
      return [0, 1, 2, 6, 7, 12, 13, 18, 19];

    //Data Mapping Expert
    case 3:
    case rolesList[3]:
      // [ viewStudio, viewDataMapping, writeDataMapping, viewApi, viewQueries, viewProfile, writeProfile ]
      return [0, 2, 3, 6, 12, 18, 19];

    //Guest
    default:
      // [viewApi, viewProfile, writeProfile]
      return [6, 18, 19];
  }
};

const Roles = {
  list: rolesList,
  description,
  assignedPolicies,
};

export default Roles;
