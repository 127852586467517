import axios from 'axios';

export const setAuthToken = (rememberMe, token) => {
  rememberMe ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token);
};

export const initAuthToken = (response, rememberMe, refreshToken = '') => {
  if (response.ok) {
    const token = response.headers.get('Authorization');

    setAuthToken(rememberMe, token);

    if (refreshToken) {
      rememberMe
        ? localStorage.setItem('refreshToken', refreshToken)
        : sessionStorage.setItem('refreshToken', refreshToken);
    }

    axios.defaults.headers.common['Authorization'] = token;
    return;
  } else {
    throw new Error(`Requesting auth token failed.`);
  }
};
