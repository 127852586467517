import { FC, ReactElement } from 'react';
import { AdditionalProps } from './constants/errorBoundary.interface';

const Retry: FC<AdditionalProps> = ({ resetState, callback }): ReactElement => {
  return (
    <>
      <span>There was an error. Please</span>,
      <span
        className="link-blue pointer"
        onClick={() => {
          if (resetState) {
            resetState();
          }
          if (callback) {
            callback();
          }
        }}
      >
        {' '}
        click here to try again.
      </span>
    </>
  );
};

export default Retry;
