import {
  FETCH_API_DETAILS,
  FETCH_API_DETAILS_SUCCESS,
  FETCH_API_DETAILS_ERROR,
  setApiDetails,
  setIsLoading,
  setHasLoadingError,
} from './actions';

import { apiRequest } from '../Api/actions';
import url from '../../api-config';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_API_DETAILS:
        dispatch(setHasLoadingError(false));
        dispatch(setIsLoading(true));
        dispatch(
          apiRequest('GET', url.apiDetails, {}, FETCH_API_DETAILS_SUCCESS, FETCH_API_DETAILS_ERROR)
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_API_DETAILS_SUCCESS:
        dispatch(setIsLoading(false));
        dispatch(setApiDetails(action.payload));
        break;

      case FETCH_API_DETAILS_ERROR:
        dispatch(setIsLoading(false));
        dispatch(setHasLoadingError(true));
        break;

      default:
        break;
    }
  };

export const apiDetailsModel = [fetchData, processCollections];
