import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_ERROR,
  FILTER_USERS_BY_ROLE_ID,
  setUsers,
  setIsLoading,
  setHasLoadingError,
  setCurrentUser,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_ERROR,
  setCurrentUserIsUpdating,
  setCurrentUserHasErrorUpdating,
  FETCH_ROLE_COUNT,
  FETCH_ROLE_COUNT_ERROR,
  FETCH_ROLE_COUNT_SUCCESS,
  setRoleCount,
  setRoleCountHasLoadingError,
  setRoleCountIsLoading,
  setCurrentUserUpdate,
} from './actions';

import { apiRequest } from '../Api/actions';
import url from '../../api-config';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_USERS:
        dispatch(setHasLoadingError(false));
        dispatch(setIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            `${url.mgmtUsers}/roles${
              action.payload ? `?search=${encodeURIComponent(action.payload)}` : ''
            }`,
            {},
            FETCH_USERS_SUCCESS,
            FETCH_USERS_ERROR
          )
        );
        break;

      case FILTER_USERS_BY_ROLE_ID:
        dispatch(setHasLoadingError(false));
        dispatch(setIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            `${url.mgmtUsers}/tenant/role/${action.payload}`,
            {},
            FETCH_USERS_SUCCESS,
            FETCH_USERS_ERROR
          )
        );
        break;

      case FETCH_CURRENT_USER:
        dispatch(
          apiRequest(
            'GET',
            url.currentUserInfo,
            {},
            FETCH_CURRENT_USER_SUCCESS,
            FETCH_CURRENT_USER_ERROR
          )
        );
        break;

      case UPDATE_CURRENT_USER:
        dispatch(setCurrentUserHasErrorUpdating(false));
        dispatch(setCurrentUserIsUpdating(true));
        dispatch(
          apiRequest(
            'PUT',
            `${url.mgmtUsers}/${action.payload.id}`,
            action.payload,
            UPDATE_CURRENT_USER_SUCCESS,
            UPDATE_CURRENT_USER_ERROR
          )
        );
        break;

      case FETCH_ROLE_COUNT:
        dispatch(setRoleCountHasLoadingError(false));
        dispatch(setRoleCountIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            `${url.mgmtUsers}/roles/count`,
            {},
            FETCH_ROLE_COUNT_SUCCESS,
            FETCH_ROLE_COUNT_ERROR
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_USERS_SUCCESS:
        dispatch(setIsLoading(false));
        dispatch(setUsers(action.payload));
        break;

      case FETCH_USERS_ERROR:
        dispatch(setIsLoading(false));
        dispatch(setHasLoadingError(true));
        break;

      case FETCH_CURRENT_USER_SUCCESS:
        dispatch(setCurrentUser(action.payload));
        break;

      case FETCH_CURRENT_USER_ERROR:
        console.warn('FETCH_CURRENT_USER_ERROR:', action.payload);
        break;

      case UPDATE_CURRENT_USER_SUCCESS:
        dispatch(setCurrentUserUpdate(action.payload));
        dispatch(setCurrentUserIsUpdating(false));
        break;

      case UPDATE_CURRENT_USER_ERROR:
        dispatch(setCurrentUserHasErrorUpdating(true));
        dispatch(setCurrentUserIsUpdating(false));
        break;

      case FETCH_ROLE_COUNT_SUCCESS:
        dispatch(setRoleCount(action.payload));
        dispatch(setRoleCountIsLoading(false));
        break;

      case FETCH_ROLE_COUNT_ERROR:
        dispatch(setRoleCountHasLoadingError(true));
        dispatch(setRoleCountIsLoading(false));
        break;

      default:
        break;
    }
  };

export const usersModel = [fetchData, processCollections];
