import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Tower360ChartDataMapInitialState } from '../../constants/tower360.constants';
import {
  ITower360ChartDataMap,
  ITower360ChartDataMapType,
  IVizId,
} from '../../constants/tower360.interfaces';
import { VisualDefV2 } from '../../../../utils/Viz/@types/visualDefV2';
import { VisualDefV1 } from '../../../../utils/Viz/@types/visualDefV1';

interface ICardInfo {
  questionId: number;
  chartOptions: VisualDefV1 | VisualDefV2;
  version: number;
}
interface PayloadArgs extends IVizId, ITower360ChartDataMapType {
  data?: any[];
  accessDenied?: boolean;
  abortController?: AbortController | null;
  isStaleData?: boolean;
}

interface LoadingDataArgs extends PayloadArgs, ICardInfo {}

const updateTower360ChartDataMap = {
  resetChartDataMap: (state: ITower360ChartDataMap) => {
    // This gets called from the useEffect return function, in tower360.tsx
    // Cancel any pending requests when user navigates away from the collection
    Object.keys(state).forEach((vizIdItem: any) => {
      if (state?.[vizIdItem]?.original?.abortController !== null) {
        state?.[vizIdItem]?.original?.abortController?.abort();
      }
      if (state?.[vizIdItem]?.simulated?.abortController !== null) {
        state?.[vizIdItem]?.simulated?.abortController?.abort();
      }

      // reset state
      if (state?.[vizIdItem]) {
        delete state?.[vizIdItem];
      }
    });
    state = {};
  },

  resetSimulatedData: (state: ITower360ChartDataMap, action: PayloadAction<IVizId>) => {
    state[action.payload.vizId] = {
      ...(state[action.payload.vizId] || {}),
      simulated: {
        data: [],
        loading: false,
        hasErrors: false,
        accessDenied: false,
        abortController: null,
      },
    };
  },

  setIsLoadingData: (state: ITower360ChartDataMap, action: PayloadAction<LoadingDataArgs>) => {
    state[action.payload.vizId] = {
      ...(state[action.payload.vizId] || {}),

      version: action.payload.version,
      chartOptions: action.payload.chartOptions,
      questionId: action.payload.questionId,

      [action.payload.type]: {
        data: [],
        loading: true,
        hasErrors: false,
        accessDenied: false,
        abortController: action.payload.abortController,
      },
    };
  },

  setHasError: (state: ITower360ChartDataMap, action: PayloadAction<PayloadArgs>) => {
    state[action.payload.vizId] = {
      ...(state[action.payload.vizId] || {}),
      [action.payload.type]: {
        data: [],
        loading: false,
        hasErrors: true,
        accessDenied: action.payload.accessDenied,
        abortController: null,
      },
    };
  },

  setData: (state: ITower360ChartDataMap, action: PayloadAction<PayloadArgs>) => {
    state[action.payload.vizId] = {
      ...(state[action.payload.vizId] || {}),
      [action.payload.type]: {
        data: action.payload.data,
        loading: false,
        hasErrors: false,
        accessDenied: false,
        abortController: null,
      },
    };
  },
};

const tower360ChartDataMapSlice = createSlice({
  name: 'tower360ChartDataMap',
  initialState: Tower360ChartDataMapInitialState,
  reducers: {
    ...updateTower360ChartDataMap,
  },
});

export const { setIsLoadingData, setHasError, setData, resetChartDataMap, resetSimulatedData } =
  tower360ChartDataMapSlice.actions;

export default tower360ChartDataMapSlice.reducer;
