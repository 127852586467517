import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import FormatListBulletIcon from '@mui/icons-material/FormatListBulleted';
import { Bento } from '@mui/icons-material';

import StringIcon from '../../assets/images/studio/card-builder/chart-type-icons/text.svg';
import SankeyIcon from '../../assets/images/studio/card-builder/chart-type-icons/sankey.svg';
import BarLineComboIcon from '../../assets/images/studio/card-builder/chart-type-icons/bar_line_combo.svg';
import DualYAxisIcon from '../../assets/images/studio/card-builder/chart-type-icons/dual_y_axis.svg';

import StringIconLight from '../../assets/images/studio/card-builder/chart-type-icons/text-light.svg';
import SankeyIconLight from '../../assets/images/studio/card-builder/chart-type-icons/sankey-light.svg';
import BarLineComboIconLight from '../../assets/images/studio/card-builder/chart-type-icons/bar_line_combo-light.svg';
import DualYAxisIconLight from '../../assets/images/studio/card-builder/chart-type-icons/dual_y_axis-light.svg';

import config from 'card-builder-constants';
import chartConstants from './chartConstants';

const { bar, coxcomb, line, list, pie, text, timeline, treemap, scatter } =
  config.chartTypes.values;
const { chartNames } = chartConstants;

export default function getChartIcon(
  type: string = '',
  style: React.CSSProperties | undefined,
  variant: 'dark' | 'light' = 'dark'
) {
  switch (type) {
    case pie:
      return <PieChartIcon style={style} />;

    case bar:
      return <BarChartIcon style={style} />;

    case line:
      return <ShowChartIcon style={style} />;

    case list:
      return <FormatListBulletIcon style={style} />;

    case timeline:
      return <TimelineIcon style={style} />;

    case coxcomb:
      return <DonutSmallIcon style={style} />;

    case treemap:
      return <Bento style={style} />;

    case scatter:
      return <ScatterPlotIcon style={style} />;

    case chartNames.sankey:
      return (
        <img
          data-testid={`sankey-chart-icon-${variant}`}
          src={variant === 'dark' ? SankeyIcon : SankeyIconLight}
          style={style}
          alt="sankey chart"
        />
      );

    case chartNames.barLineCombo:
      return (
        <img
          data-testid={`bar-line-combo-chart-${variant}`}
          src={variant === 'dark' ? BarLineComboIcon : BarLineComboIconLight}
          style={style}
          alt="bar line combo"
        />
      );

    case chartNames.dualYAxis:
      return (
        <img
          data-testid={`dual-y-axis-chart-icon-${variant}`}
          src={variant === 'dark' ? DualYAxisIcon : DualYAxisIconLight}
          style={style}
          alt="dual y chart"
        />
      );

    case text:
    default:
      return (
        <img
          data-testid={`text-chart-icon-${variant}`}
          src={variant === 'dark' ? StringIcon : StringIconLight}
          alt="string chart type"
          style={{ width: 16, height: 16, ...style }}
        />
      );
  }
}
