import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IOutputItem } from '../../SubQuestions/redux/constants/subQuestions.interface';
import {
  IDependencyErrors,
  depErrorsInitialState,
} from '@components/Studio/QueryCreationTwo/Props/modals/RuntimeDependencies/helpers/getRuntimeSettingsRefs';
import { ICalcs } from '@redux/Studio/QueryCreation/constants/queryCreation.interface';

export interface IPropertiesListModalsState {
  isRelationsModalOpen: boolean;
  isFilterModalOpen: boolean;
  isAggregatesModalOpen: boolean;
  isWindowFunctionsOpen: boolean;
  isSelfJoinModalOpen: boolean;
  showSettingsModal: boolean;
  showConfirmDeleteProperty: boolean;
  selectedProp: IOutputItem | null;
  selectedCalc: ICalcs | undefined;
  isPropSubQuery: boolean;
  dependencyErrors: IDependencyErrors;
}

export type modalTypes =
  | 'settings'
  | 'aggregates'
  | 'filters'
  | 'windowFunctions'
  | 'selfJoin'
  | 'relations'
  | 'showConfirmDeleteProperty'
  | '';

const initialState: IPropertiesListModalsState = {
  isRelationsModalOpen: false,
  isFilterModalOpen: false,
  isAggregatesModalOpen: false,
  isWindowFunctionsOpen: false,
  isSelfJoinModalOpen: false,
  showSettingsModal: false,
  showConfirmDeleteProperty: false,
  selectedProp: null,
  selectedCalc: undefined,
  isPropSubQuery: false,
  dependencyErrors: depErrorsInitialState,
};

export const propertiesListModalsReducer = {
  resetState: (state: IPropertiesListModalsState) => {
    state.isAggregatesModalOpen = initialState.isAggregatesModalOpen;
    state.isFilterModalOpen = initialState.isFilterModalOpen;
    state.isRelationsModalOpen = initialState.isRelationsModalOpen;
    state.isWindowFunctionsOpen = initialState.isWindowFunctionsOpen;
    state.isSelfJoinModalOpen = initialState.isSelfJoinModalOpen;
    state.showSettingsModal = initialState.showSettingsModal;
    state.showConfirmDeleteProperty = initialState.showConfirmDeleteProperty;
    state.selectedProp = initialState.selectedProp;
    state.isPropSubQuery = initialState.isPropSubQuery;
    state.selectedCalc = initialState.selectedCalc;
  },

  toggleModal: (
    state: IPropertiesListModalsState,
    action: PayloadAction<{
      type: modalTypes;
      selectedProp: IOutputItem | null;
      selectedCalc?: ICalcs | undefined;
      isPropSubQuery?: boolean;
      dependencyErrors?: IDependencyErrors;
    }>
  ) => {
    state.selectedCalc = action.payload.selectedCalc || undefined;
    state.selectedProp = action.payload.selectedProp;
    state.isPropSubQuery = action.payload.isPropSubQuery || false;
    state.dependencyErrors = action.payload.dependencyErrors || depErrorsInitialState;

    switch (action.payload.type) {
      case 'settings':
        state.showSettingsModal = !state.showSettingsModal;
        return;

      case 'aggregates':
        state.isAggregatesModalOpen = !state.isAggregatesModalOpen;
        return;

      case 'filters':
        state.isFilterModalOpen = !state.isFilterModalOpen;
        return;

      case 'windowFunctions':
        state.isWindowFunctionsOpen = !state.isWindowFunctionsOpen;
        return;

      case 'selfJoin':
        state.isSelfJoinModalOpen = !state.isSelfJoinModalOpen;
        return;

      case 'relations':
        state.isRelationsModalOpen = !state.isRelationsModalOpen;
        return;

      case 'showConfirmDeleteProperty':
        state.showConfirmDeleteProperty = !state.showConfirmDeleteProperty;
        return;

      default:
        return;
    }
  },
};

export const propertiesListModalsSlice = createSlice({
  name: 'propertiesListModals',
  initialState: initialState,
  reducers: propertiesListModalsReducer,
});

export const { resetState, toggleModal } = propertiesListModalsSlice.actions;

export default propertiesListModalsSlice.reducer;
