export const SET_TENANTS = '[Tenants] Set Tenants';
export const setTenants = (tenants) => ({ type: SET_TENANTS, payload: tenants });

export const SET_IS_LOADING = '[Tenants] Set Is Loading';
export const setIsLoading = (status) => ({ type: SET_IS_LOADING, payload: status });

export const SET_HAS_LOADING_ERROR = '[Tenants] Set Has Loading Error';
export const setHasLoadingError = (status) => ({ type: SET_HAS_LOADING_ERROR, payload: status });

export const FETCH_TENANTS = '[Tenants] Fetch Tenants';
export const FETCH_TENANTS_SUCCESS = '[Tenants] Fetch Tenants Success';
export const FETCH_TENANTS_ERROR = '[Tenants] Fetch Tenants Error';
export const fetchTenants = () => ({ type: FETCH_TENANTS });

export const FETCH_TENANT_ADMINS = '[Tenants] Fetch Tenant Admins';
export const FETCH_TENANT_ADMINS_SUCCESS = '[Tenants] Fetch Tenant Admins Success';
export const FETCH_TENANT_ADMINS_ERROR = '[Tenants] Fetch Tenant Admins Error';
export const fetchTenantAdmins = () => ({ type: FETCH_TENANT_ADMINS });

export const SET_TENANT_ADMINS = '[Tenants] Set Tenant Admins';
export const setTenantAdmins = (admins) => ({ type: SET_TENANT_ADMINS, payload: admins });

export const SET_TENANT_ADMIN_IS_LOADING = '[Tenants] Set Tenat Admin Is Loading';
export const setAdminIsLoading = (status) => ({
  type: SET_TENANT_ADMIN_IS_LOADING,
  payload: status,
});

export const SET_TENANT_ADMIN_HAS_LOADING_ERROR = '[Tenants] Set Tenant Admin Has Loading Error';
export const setAdminHasLoadingError = (status) => ({
  type: SET_TENANT_ADMIN_HAS_LOADING_ERROR,
  payload: status,
});

export const SEARCH_ADMIN_LIST = '[Tenants] Search admin list';
export const searchAdminList = (value) => ({ type: SEARCH_ADMIN_LIST, payload: { value } });

export const FILTER_ADMIN_LIST_BY_TENANT = '[Tenants] Filter admin list by tenant';
export const filterAdminListByTenant = (value) => ({
  type: FILTER_ADMIN_LIST_BY_TENANT,
  payload: { value },
});

export const RESET_LISTS = '[Tenants] reset admin list';
export const resetLists = () => ({ type: RESET_LISTS });
