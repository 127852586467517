export const initialState = {
  // used to determine if the user is authenticated
  isAuth: false,
  appIsLoading: true,

  didLoginFail: false,

  // used in pod configuration > security tab
  authSetup: {},
  isAuthSetupLoading: false,
  authSetupHasError: false,

  // used in App.js, when the user first comes to the app
  authType: '',
  authTypeIsLoading: true,
  authTypeHasError: false,
};
