import React, { FC, ReactElement, ReactNode, useMemo } from 'react';

interface IActionButtonsContainer extends React.HTMLAttributes<HTMLDivElement> {
  isLegacy?: boolean;
  children: ReactNode;
}

const ActionButtonsContainer: FC<IActionButtonsContainer> = ({
  className = '',
  style = {},
  children,
  isLegacy = true,
}): ReactElement => {
  const height = useMemo(() => (isLegacy ? 84 : 56), [isLegacy]);

  return (
    <div
      className={`flex items-center bt ${
        isLegacy ? 'b--nobel bg-whisper' : 'b--tints-default-medium bg-tints-default-medium-light'
      } br2 br--bottom pa-24-right ${className}`}
      style={{
        height,
        minHeight: height,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default ActionButtonsContainer;
