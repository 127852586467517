/**
 * Uses string manipulation to get the concept name & property name,
 * and return the format that the BE returns, for question data
 * This format is used for mapping question data in the list viz
 * @param concept
 * @param prop
 * @returns string ConceptName_PropertyName
 */
export const getConceptPropName = (concept: string, prop: string): string => {
  const conceptName = concept.substring(concept.lastIndexOf('#') + 1, concept.length);
  const propName = prop.substring(prop.lastIndexOf('#') + 1, prop.length);

  return `${conceptName}_${propName}`;
};
