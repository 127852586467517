import { createSlice } from '@reduxjs/toolkit';
import { CardBuilderInitialState } from './cardBuilder-initialState';

import fetchDataReduxSlice from './CardData/cardData-redux-slice';
import fetchCardsListReduxSlice from './CardsList/cardsList-redux-slice';
import cardModalReduxSlice from './CardModal/cardModal-redux-slice';
import cardConfigReduxSlice from './CardConfig/cardConfig-redux-slice';

const cardBuilderSlice = createSlice({
  name: 'cardBuilder',
  initialState: CardBuilderInitialState,
  reducers: {
    ...fetchDataReduxSlice,
    ...fetchCardsListReduxSlice,
    ...cardModalReduxSlice,
    ...cardConfigReduxSlice,
  },
});

export const {
  initiateFetchCardData,
  fetchCardDataSuccess,
  fetchCardDataError,

  initiateFetchCardsList,
  fetchCardsListSuccess,
  fetchCardsListError,

  setCardModalStep,
  toggleConfigModal,
  setIsEditMode,
  resetCardModalState,
  setV1SelectedConfig,

  updateCardTitle,
  updateChartType,
  updateCardVersion,
  initCardTitleAndTypeAndOwner,
  initEditCard,
  initEditCardV1,
  updateSelectedChartConfig,
  updateCollections,
  updateCardHeight,
  updateCardWidth,
  updateDynamicFilters,
  removeDynamicFilters,
  updateAxisData,
  resetCardConfigState,
} = cardBuilderSlice.actions;

export default cardBuilderSlice.reducer;
