import { ROLE_NAMES } from '../../../../RBAC/config/roleNames';

/**
 * Process multi-select functionality
 * @param {number | string} selectedQuestion
 * @param {array} selectedQuestionList
 * @returns
 */
export const processSelect = (selectedQuestion, selectedQuestionList) => {
  // Select and unselect single item
  let index = selectedQuestionList.findIndex((question) => selectedQuestion.id === question.id);

  const newSelectedList = [...selectedQuestionList];
  if (index === -1) {
    newSelectedList.push(selectedQuestion);
  } else {
    newSelectedList.splice(index, 1);
  }
  return newSelectedList;
};

/**
 * filterQuestionsList
 * - for the TENANT_ADMIN role, returns all questions that match the search string
 * - for NON TENANT_ADMIN role, returns all questions that match the search string
 * AND are either shared OR have been created by the user
 * @param {Array} list
 * @param {Object} currentUser
 * @param {String} searchStr
 * @param {Boolean} isSelectMode
 * @returns Array
 */
export const filterQuestionsList = (list, currentUser, searchStr, ownerStr, isSelectMode) => {
  let filteredList = list.filter((query) => {
    let match = true;
    try {
      const matchDescription =
        query.description.toLowerCase().indexOf(searchStr.toLowerCase()) > -1;

      const matchOwner =
        `${query?.firstName || ''} ${query?.lastName || ''}`.toLowerCase() ===
        ownerStr.toLowerCase();

      if (searchStr.length > 0 && ownerStr.length > 0) {
        match = matchDescription && matchOwner;
      } else if (searchStr.length > 0 && ownerStr.length === 0) {
        match = matchDescription;
      } else if (searchStr.length === 0 && ownerStr.length > 0) {
        match = matchOwner;
      }

      if (isSelectMode) {
        if (ROLE_NAMES.TENANT_ADMIN in currentUser.tenantRolesMap) {
          return match;
        } else {
          return match && (query.userId === currentUser.id || query.shared);
        }
      } else {
        return match;
      }
    } catch (e) {
      console.warn(e);
      return match;
    }
  });

  return filteredList;
};
