import { allCSVDataSourceTypes } from '../../../Modules/Studio/DataSources/components/Datasets/IngestSettings/components/IncrementalIngestionSettings/utilities/IncrementalIngestionsSettings.constants';

import {
  SET_DATA_SOURCES,
  SET_DATA_SOURCES_STANDALONE,
  FILTER_DATA_SOURCES_STANDALONE,
  SET_DATA_SOURCE_TYPES,
  SET_DATA_SOURCE_TABLES,
  SET_IS_LOADING,
  SET_IS_LOADING_DATA_SOURCE_STANDALONE,
  SET_DATA_SOURCE_TYPES_IS_LOADING,
  SET_TABLES_IS_LOADING,
  SET_ERROR_LOADING_DATA_SOURCES,
  SET_ERROR_LOADING_DATA_SOURCE_TYPES,
  SET_ERROR_LOADING_DATA_SOURCE_TABLES,
  RESET_SELECTED_DATA_SOURCE,
  SET_COLUMNS,
  SET_COLUMNS_IS_LOADING,
  SET_ERROR_LOADING_COLUMNS,
  SET_ACTIVE_DATA_SOURCE_TABLE,
  SET_ACTIVE_DATA_SOURCE_TABLE_COUNTS,
  SET_ERROR_LOADING_DATA_SOURCES_STANDALONE,
  FILTER_DATA_SOURCES_BY_NAME,
  FILTER_DATA_SOURCES_BY_TYPE,
  UPDATE_DATA_SOURCE_TABLES_PAGINATION_PAGE_LIMIT,
  UPDATE_DATA_SOURCE_TABLES_PAGINATION_CURRENT_PAGE,
  UPDATE_DATA_SOURCE_TABLES_PAGINATION_SEARCH_TERM,
  RESET_DATA_SOURCE_TABLES_PAGINATION,
  TOGGLE_DATA_SOURCE_MODAL,
  SET_SELECTED_DATA_SOURCE_FOR_MODAL,
  CLOSE_DATA_SOURCE_MODAL,
} from './actions.js';

const dataSourceTablesPaginationInitialState = {
  pageLimit: 10,
  currentPage: 1,
  searchTerm: '',
};

const initialState = {
  activeTableName: null,
  activeDataSourceTotalCols: 0,
  activeDataSourceSelectedColCount: 0,

  dataSourceList: {},
  filteredDataSourceList: {
    // current: [],
    // other: [],
    // none: [],
  },
  dataSourceListIsEmpty: false,
  dataSourceStandAlone: {
    isLoading: false,
    hasLoadingError: false,
    errorCode: -1,
    dataSourceStandAloneList: {},
    filteredList: {},
  },
  dataSourceTablesList: {},
  dataSourceTablesRaw: [],
  dataSourceTablesPagination: dataSourceTablesPaginationInitialState,
  dataSourceTypesList: [],
  csvDataSourceTypeIds: [],
  dataSourceTypesMap: {},
  dataSourceTableColumnsList: [],
  totalTables: 0,
  tablePageLimit: 10,

  isLoading: true,
  hasLoadingError: false,
  errorCode: -1,

  isTablesLoading: false,
  isTablesError: false,
  isDataSourceTypesLoading: true,
  isDataSourceTypesError: false,

  // dataSource table columns
  tableColumns: {
    data: [],
    searchCount: 0,
    selectedCount: 0,
    totalCount: 8,
  },
  isColumnsLoading: false,
  isColumnsError: false,
  isColumnsErrorCode: -1,

  dataSourceModalIsOpen: false,
  selectedDataSourceForModal: null,
};

export function studioDataSourcesReducer(state = initialState, action) {
  const stateCopy = { ...state };

  switch (action.type) {
    case SET_DATA_SOURCES:
      const list = Object.keys(action.payload).reduce(
        (acc, type) => {
          return {
            ...acc,
            dataSources: {
              ...acc.dataSources,
              [type]: action.payload[type].map((ds) => ({
                ...ds,
                // init empty object - later used in selectedDatasource
                userName: '',
                password: '',
                connectionUrl: '',
                props: {},
              })),
            },
            count: acc.count + action.payload[type].length,
          };
        },
        {
          dataSources: {},
          count: 0,
        }
      );

      return {
        ...state,
        dataSourceList: list.dataSources,
        filteredDataSourceList: list.dataSources,
        dataSourceListIsEmpty: list.count === 0,
      };

    case FILTER_DATA_SOURCES_BY_NAME:
      let filteredDataSourceListByName = state.dataSourceList;

      if (action.payload !== '') {
        filteredDataSourceListByName = Object.keys(state.dataSourceList).reduce((acc, type) => {
          return {
            ...acc,
            [type]: state.dataSourceList[type].filter(
              (item) => item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
            ),
          };
        }, {});
      }

      return {
        ...state,
        filteredDataSourceList: filteredDataSourceListByName,
      };

    case FILTER_DATA_SOURCES_BY_TYPE:
      let filteredDataSourceListByType = state.dataSourceList;

      if (action.payload !== 0) {
        filteredDataSourceListByType = Object.keys(state.dataSourceList).reduce((acc, type) => {
          return {
            ...acc,
            [type]: state.dataSourceList[type].filter(
              (item) => item.dataSourceType === action.payload
            ),
          };
        }, {});
      }

      return {
        ...state,
        filteredDataSourceList: filteredDataSourceListByType,
      };

    case SET_DATA_SOURCES_STANDALONE:
      const standAloneList = Object.keys(action.payload).reduce(
        (acc, type) => {
          return {
            ...acc,
            datasourcesStandalone: {
              ...acc.datasourcesStandalone,
              [type]: action.payload[type].map((ds) => ({
                ...ds,
                // init empty object - later used in selectedDatasource
                userName: '',
                password: '',
                connectionUrl: '',
                props: {},
              })),
            },
            count: acc.count + action.payload[type].length,
          };
        },
        {
          datasourcesStandalone: {},
        }
      );

      return {
        ...state,
        dataSourceStandAlone: {
          ...state.dataSourceStandAlone,
          dataSourceStandAloneList: standAloneList.datasourcesStandalone,
          filteredList: standAloneList.datasourcesStandalone,
        },
      };

    case FILTER_DATA_SOURCES_STANDALONE:
      let filteredList = [];
      const value = action.payload;
      const dataSourceList = state.dataSourceStandAlone.dataSourceStandAloneList;

      if (value !== '') {
        filteredList = Object.keys(dataSourceList).reduce((acc, type) => {
          return {
            ...acc,
            [type]: dataSourceList[type].filter(
              (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            ),
          };
        }, {});
      } else {
        filteredList = dataSourceList;
      }
      return {
        ...state,
        dataSourceStandAlone: {
          ...state.dataSourceStandAlone,
          filteredList,
        },
      };

    case SET_DATA_SOURCE_TABLES:
      let activeDataSourceTotalCols = state.activeDataSourceTotalCols;
      let activeDataSourceSelectedColCount = state.activeDataSourceSelectedColCount;

      const indexedTables = action.payload.data.reduce((dataSourceTables, dataSourceTable) => {
        if (dataSourceTable.tableName === state.activeTableName) {
          activeDataSourceTotalCols = dataSourceTable.total;
          activeDataSourceSelectedColCount = dataSourceTable.selectedCount;
        }
        return {
          ...dataSourceTables,
          [dataSourceTable.tableName]: dataSourceTable,
        };
      }, {});

      return {
        ...state,
        dataSourceTablesRaw: action.payload.data,
        dataSourceTablesList: indexedTables,
        totalTables: action.payload.totalCount,
        activeDataSourceTotalCols,
        activeDataSourceSelectedColCount,
      };

    case SET_DATA_SOURCE_TYPES:
      const dataSourceTypesList = action.payload;

      return {
        ...state,
        dataSourceTypesList: dataSourceTypesList,
        csvDataSourceTypeIds: dataSourceTypesList
          .filter((type) => allCSVDataSourceTypes.includes(type.id))
          .map((item) => item.id),
        dataSourceTypesMap: dataSourceTypesList.reduce(
          (acc, curr) => ({ ...acc, [curr.id]: curr }),
          {}
        ),
      };

    case SET_COLUMNS:
      return {
        ...stateCopy,
        tableColumns: action.payload,
        dataSourceTableColumnsList: action.payload.data,
        activeDataSourceSelectedColCount: action.payload.selectedCount,
      };

    case SET_IS_LOADING:
      stateCopy.isLoading = action.payload;
      return stateCopy;

    case SET_IS_LOADING_DATA_SOURCE_STANDALONE:
      return {
        ...stateCopy,
        dataSourceStandAlone: {
          ...stateCopy.dataSourceStandAlone,
          isLoading: action.payload,
        },
      };

    case SET_DATA_SOURCE_TYPES_IS_LOADING:
      stateCopy.isDataSourceTypesLoading = action.payload;
      return stateCopy;

    case SET_TABLES_IS_LOADING:
      stateCopy.isTablesLoading = action.payload;
      return stateCopy;

    case SET_COLUMNS_IS_LOADING:
      stateCopy.isColumnsLoading = action.payload;
      return stateCopy;

    case SET_ERROR_LOADING_DATA_SOURCES:
      return {
        ...state,
        hasLoadingError: action.payload.status,
        errorCode: action.payload.error.status,
      };

    case SET_ERROR_LOADING_DATA_SOURCES_STANDALONE:
      return {
        ...state,
        dataSourceStandAlone: {
          ...state.dataSourceStandAlone,
          hasLoadingError: action.payload.status,
          errorCode: action.payload.error.status,
        },
      };

    case SET_ERROR_LOADING_COLUMNS:
      stateCopy.isColumnsError = action.payload.status;
      stateCopy.isColumnsErrorCode = action.payload.errorCode;
      return stateCopy;

    case RESET_SELECTED_DATA_SOURCE:
      stateCopy.dataSourceTables = {};
      return stateCopy;

    case SET_ERROR_LOADING_DATA_SOURCE_TYPES:
      stateCopy.isDataSourceTypesError = action.payload;
      return stateCopy;

    case SET_ERROR_LOADING_DATA_SOURCE_TABLES:
      stateCopy.isTablesError = action.payload;
      return stateCopy;

    case SET_ACTIVE_DATA_SOURCE_TABLE:
      stateCopy.activeTableName = action.payload;
      return stateCopy;

    case SET_ACTIVE_DATA_SOURCE_TABLE_COUNTS:
      return {
        ...state,
        activeDataSourceTotalCols: action.payload.total,
        activeDataSourceSelectedColCount: action.payload.selectedCount,
      };

    case UPDATE_DATA_SOURCE_TABLES_PAGINATION_PAGE_LIMIT:
      return {
        ...state,
        dataSourceTablesPagination: {
          ...state.dataSourceTablesPagination,
          pageLimit: action.payload,
        },
      };

    case UPDATE_DATA_SOURCE_TABLES_PAGINATION_CURRENT_PAGE:
      return {
        ...state,
        dataSourceTablesPagination: {
          ...state.dataSourceTablesPagination,
          currentPage: action.payload,
        },
      };

    case UPDATE_DATA_SOURCE_TABLES_PAGINATION_SEARCH_TERM:
      return {
        ...state,
        dataSourceTablesPagination: {
          ...state.dataSourceTablesPagination,
          searchTerm: action.payload,
        },
      };

    case RESET_DATA_SOURCE_TABLES_PAGINATION:
      return {
        ...state,
        dataSourceTablesPagination: dataSourceTablesPaginationInitialState,
      };

    case TOGGLE_DATA_SOURCE_MODAL:
      return {
        ...state,
        dataSourceModalIsOpen: action.payload,
      };

    case SET_SELECTED_DATA_SOURCE_FOR_MODAL:
      return {
        ...state,
        selectedDataSourceForModal: action.payload,
      };

    case CLOSE_DATA_SOURCE_MODAL:
      return {
        ...state,
        dataSourceModalIsOpen: false,
        selectedDataSourceForModal: null,
      };

    default:
      return state;
  }
}

export const getDataSourceTablesList = (dataSourceTableList = {}) =>
  Object.keys(dataSourceTableList).map((tableName) => {
    return {
      tableName: dataSourceTableList[tableName].tableName,
      total: dataSourceTableList[tableName].total || 0,
      selectedCount: dataSourceTableList[tableName].selectedCount || 0,
    };
  });

export const getDataSourceTableCount = (dataSourceTableList) => {
  try {
    return Object.keys(dataSourceTableList).length;
  } catch (err) {
    return 'n/a';
  }
};
