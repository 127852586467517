import { IPropertiesRootState } from './properties.interface';

export const mappingFunctionDef = {
  type: null,
  column: [],
};

export const relationLookupsDefaults = {
  typeId: 0,
  function: mappingFunctionDef,
  columnName: null,
  tableName: null,
  datasourceId: null,
};

export const mappingDefBase = {
  id: null,
  queryId: null,
  solutionId: null,
  label: '',
  nullOverride: {},

  columnName: null,
  conceptUri: '',
  conceptTypeUri: '',
  dataSetId: null,
  filters: [],
  function: mappingFunctionDef,
  idColumnName: null,
  idFunction: mappingFunctionDef,
  mappingPropTypeId: 0, //default to direct mapping
  mappingIdTypeId: 0,
  mappingOverlapIdTypeId: 0,
  overlapIdFunction: mappingFunctionDef,
  overlapColumnName: null,
  overlapTableName: null,
  overlapDatasourceId: null,
  propertyUri: '',
  propertyTypeUri: '',
  timeseries: {
    conceptIdColumn: null,
    dataSetId: null,
    idColumn: null,
    timestampColumn: null,
    timestampFormat: null,
    valueColumn: null,
  },
  timestampProps: {
    timestampFormat: null,
    timeZone: null,
    alignTo: null,
  },
  relationLookups: relationLookupsDefaults,
};

export const mappingTabsDefaults = {
  overlapChecked: false,
  overlapSelectedDataset: null,
};

export const initialState: IPropertiesRootState = {
  defsErrorCode: -1,
  selectedConcept: null,
  selectedProperty: null,
  selectedMapping: null,
  selectedMappingQueryDesc: '',

  selectedMappingDefIsSaving: false,
  selectedMappingDefHasSavingError: false,

  selectedMappingDefIsLoading: false,
  selectedMappingDefHasLoadingError: false,

  conceptIdSettings: mappingTabsDefaults,
  generalSettings: mappingTabsDefaults,

  conceptLookups: [],
  conceptLookupsIsLoading: true,
  conceptLookupsHasLoadingError: false,

  conceptIdOverlaps: [],
  overlapsMapByTablename: {},
  overlapColumnNameOptions: [],
  conceptIdOverlapsIsLoading: true,
  conceptIdOverlapsHasLoadingError: false,

  propMappingList: [],
  propMappingIsLoading: false,
  propMappingHasLoadingError: false,

  // datasets
  list: [],
  isLoading: false,
  hasLoadingError: false,
  dataSetErrorCode: -1,

  dataSetMap: [],
  timeseriesDataSetMap: [],

  dataSetColumns: [],
  mappingColumns: [],
  dataSetColumnsIsLoading: false,
  dataSetColumnsHadLoadingError: false,

  timeseriesDataSetColumns: [],
  timeseriesDataSetColumnsIsLoading: false,
  timeseriesDataSetColumnsHadLoadingError: false,
};
