import {
  fetchIntercept,
  fetchInterceptBlob,
  fetchInterceptFile,
  handleResponseText,
  parseResponse,
} from './utils';

const getNoAuth = async (url, opts = {}) => {
  const response = await fetch(url, opts);
  const res = await handleResponseText(response);

  return parseResponse(res);
};

const get = async (url, opts = {}) => {
  const options = {
    method: 'GET',
    ...opts,
  };
  return await fetchIntercept(url, options);
};

const getFile = async (url, opts = {}) => {
  return await fetchInterceptBlob(url, opts);
};

const postFile = async (url, file) => {
  const options = {
    method: 'POST',
    body: file,
  };

  return await fetchInterceptFile(url, options, true);
};

const putFile = async (url, file) => {
  const options = {
    method: 'PUT',
    body: file,
  };

  return await fetchInterceptFile(url, options, true);
};

const post = async (url, data = {}, opts = {}) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    ...opts,
  };
  return await fetchIntercept(url, options);
};

const put = async (url, data = {}) => {
  const opts = {
    method: 'PUT',
    body: JSON.stringify(data),
  };
  return await fetchIntercept(url, opts);
};

const _delete = async (url, data = {}) => {
  const opts = {
    method: 'DELETE',
    body: JSON.stringify(data),
  };
  return await fetchIntercept(url, opts);
};

const multiRequest = (requests) => {
  const requestTypesMap = {
    GET: get,
    GETFILE: getFile,
    PUT: put,
    POST: post,
    POSTFILE: postFile,
    PUTFILE: putFile,
    DELETE: _delete,
  };

  const requestArray = requests.map((request) => {
    return requestTypesMap[request.method](request.url, request.body);
  });

  return Promise.all(requestArray);
};

const Api = {
  get,
  getNoAuth,
  getFile,
  put,
  post,
  postFile,
  putFile,
  delete: _delete,
  multiRequest,
};

export default Api;
