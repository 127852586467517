import React, { useContext } from 'react';

import { context } from '../../../../components/Theme/Context';

function KobaiDefaultLogo() {
  const theme = useContext(context);

  return !theme.useCustomTheme ? (
    <header className={'w-100 flex flex-column items-center pb4 relative z-1'}>
      <img src={theme.logos.company} alt="company logo" width="150" />
    </header>
  ) : (
    <></>
  );
}

export default KobaiDefaultLogo;
