import {
  CREATE_NEW_DATASET,
  CREATE_NEW_DATASET_SUCCESS,
  CREATE_NEW_DATASET_ERROR,
  setNewDataset,
  setNewDatasetIsSaving,
  setNewDatasetHasSavingError,
} from './actions';

import url from '../../../api-config';
import { apiRequest } from '../../Api/actions';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case CREATE_NEW_DATASET:
        dispatch(setNewDatasetIsSaving(true));
        dispatch(setNewDatasetHasSavingError(false));
        dispatch(
          apiRequest(
            'POST',
            url.dataSet,
            action.data,
            CREATE_NEW_DATASET_SUCCESS,
            CREATE_NEW_DATASET_ERROR
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case CREATE_NEW_DATASET_SUCCESS:
        dispatch(setNewDatasetIsSaving(false));
        dispatch(setNewDataset(action.payload));
        break;

      case CREATE_NEW_DATASET_ERROR:
        dispatch(setNewDatasetIsSaving(false));
        dispatch(setNewDatasetHasSavingError(true));
        break;

      default:
        break;
    }
  };

export const datasetsModel = [fetchData, processCollections];
