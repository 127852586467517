const crud = { c: false, r: false, u: false, d: false };

export const queries = Object.freeze({
  query: { ...crud },
  aggregates: { ...crud },
  filters: { ...crud },
  concept: { ...crud },
  properties: { ...crud },
  timeProps: { ...crud },
  queryResults: { ...crud },
  share: { ...crud },
});
