import {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  setRoles,
  setIsLoading,
  setHasLoadingError,
  setUserRoles,
} from './actions';

import { appIsFinishedLoading } from '../Auth/actions';

import { apiRequest, apiRequestNoAuth } from '../Api/actions';
import url from '../../api-config';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_ROLES:
        dispatch(setHasLoadingError(false));
        dispatch(setIsLoading(true));
        dispatch(
          apiRequestNoAuth(
            'GET',
            url.roles,
            {},
            FETCH_ROLES_SUCCESS,
            FETCH_ROLES_ERROR,
            action.payload
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_ROLES_SUCCESS:
        dispatch(setIsLoading(false));
        dispatch(setRoles(action.payload));
        dispatch(setUserRoles(action.props));
        dispatch(appIsFinishedLoading());
        break;

      case FETCH_ROLES_ERROR:
        dispatch(setIsLoading(false));
        dispatch(setHasLoadingError(true));
        dispatch(setUserRoles(action.props));
        break;

      default:
        break;
    }
  };

export const rolesModel = [fetchData, processCollections];
