import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SimulationListItem, Tower360SimulationState } from './../../constants/tower360.constants';
import {
  IActiveScenario,
  ITower360SimulationInitialState,
  ITower360SimulationList,
} from './../../constants/tower360.interfaces';
import { generateUUID } from '../../../../utils';
import { getActiveSimulations } from './utils/getActiveSimulations';

// Simulations
const updateTowerSimulationReduxSlice = {
  resetSimulationState: (state: ITower360SimulationInitialState) => {
    state.showConfig = Tower360SimulationState.showConfig;
    state.loading = Tower360SimulationState.loading;
    state.hasErrors = Tower360SimulationState.hasErrors;
    state.list = Tower360SimulationState.list;
    state.activeSimulations = Tower360SimulationState.activeSimulations;
    state.compareToBaseline = Tower360SimulationState.compareToBaseline;
    state.activeTabIndex = Tower360SimulationState.activeTabIndex;
    state.activeScenario = Tower360SimulationState.activeScenario;
  },

  toggleShowSimulation: (state: ITower360SimulationInitialState) => {
    state.showConfig = !state.showConfig;
  },

  closeShowSimulation: (state: ITower360SimulationInitialState) => {
    state.showConfig = false;
  },

  addSimulation: (state: ITower360SimulationInitialState) => {
    const id = generateUUID();
    state.list[id] = { ...SimulationListItem, id, isNew: true };
  },

  editSimulation: (state: ITower360SimulationInitialState, action: PayloadAction<any>) => {
    state.list[action.payload.id] = action.payload.update;
  },

  editDuplicateSimulation: (state: ITower360SimulationInitialState, action: PayloadAction<any>) => {
    state.list[action.payload.id1] = action.payload.update1;
    state.list[action.payload.id2] = action.payload.update2;
  },

  deleteSimulation: (state: ITower360SimulationInitialState, action: PayloadAction<any>) => {
    delete state.list[action.payload];
  },

  updateActiveSimulations: (state: ITower360SimulationInitialState) => {
    // Get active simulations where useNow = true &
    // pass an empty scenario, so that simulations will be used, instead of scenarios
    // This will reset scenarios to empty
    const reducedSimulationList = getActiveSimulations(
      Object.values(state.list),
      Tower360SimulationState.activeScenario
    );

    state.activeScenario = Tower360SimulationState.activeScenario;
    state.activeSimulations = reducedSimulationList;
  },

  toggleCompareToBaseline: (
    state: ITower360SimulationInitialState,
    action: PayloadAction<boolean>
  ) => {
    state.compareToBaseline = action.payload;
  },

  updateTabIndex: (state: ITower360SimulationInitialState, action: PayloadAction<number>) => {
    state.activeTabIndex = action.payload;
  },

  updateActiveScenario: (
    state: ITower360SimulationInitialState,
    action: PayloadAction<IActiveScenario>
  ) => {
    state.activeScenario = action.payload;

    if (action.payload.id !== null) {
      // iterate simulation list and toggle useNow's to false
      const updatedSimulationList = Object.keys(state.list).reduce(
        (acc: ITower360SimulationList, item: string) => {
          return {
            ...acc,
            [item]: {
              ...state.list[item as keyof ITower360SimulationList],
              useNow: false,
            },
          };
        },
        {}
      );

      const reducedSimulationList = getActiveSimulations(
        Object.values(updatedSimulationList),
        action.payload
      );

      // reset simulatedProperties
      state.list = updatedSimulationList;
      state.activeSimulations = reducedSimulationList;
    } else {
      // remove simulation tags and active vizids
      state.activeSimulations.simulations.simulatedTag = null;
      state.activeSimulations.vizids = '';
    }
  },
};

const tower360SimulationsSlice = createSlice({
  name: 'tower360Simulations',
  initialState: Tower360SimulationState,
  reducers: {
    ...updateTowerSimulationReduxSlice,
  },
});

export const {
  toggleShowSimulation,
  addSimulation,
  editSimulation,
  editDuplicateSimulation,
  deleteSimulation,
  updateActiveSimulations,
  resetSimulationState,
  closeShowSimulation,
  toggleCompareToBaseline,
  updateTabIndex,
  updateActiveScenario,
} = tower360SimulationsSlice.actions;

export default tower360SimulationsSlice.reducer;
