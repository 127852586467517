import {
  SET_QUERIES_LIST,
  SET_QUERIES_LIST_IS_LOADING,
  SET_QUERIES_LIST_HAS_LOADING_ERROR,
  FILTER_QUERIES_LIST,
  UPDATE_OWNER_STR,
  UPDATE_QUERIES_SEARCH_STR,
  RESET_QUERIES_LIST_STATE,
  UPDATE_SELECT_MODE,
  UPDATE_SELECTED_QUESTIONS,
  SET_READ_DOMAINS_LIST,
  SET_READ_DOMAINS_LIST_PENDING,
  SET_READ_DOMAINS_LIST_FAILED,
} from './actions.js';
import { initialState } from './initialState';
import { filterQuestionsList } from './utilities/question-list-helpers.js';

let filteredList = [];

export function queriesReducer(state = initialState, action) {
  switch (action.type) {
    // QueriesList
    case SET_QUERIES_LIST:
      const queriesList = action.payload.queriesList;
      const currentUserForInitialSet = action.payload.currentUser;
      let questionOwners = [];
      let sortedList = queriesList;

      try {
        sortedList = queriesList.sort((a, b) => {
          if (a.description < b.description) {
            return -1;
          }
          if (a.description > b.description) {
            return 1;
          }
          return 0;
        });

        try {
          questionOwners = [
            ...new Set(
              queriesList.map((item) => `${item?.firstName || ''} ${item?.lastName || ''}`)
            ),
          ].sort();
        } catch (e) {
          console.warn(e);
        }

        filteredList = sortedList;
        if (state.searchStr.length > 0 || state.ownerStr.length > 0 || state.selectMode) {
          filteredList = filterQuestionsList(
            queriesList,
            currentUserForInitialSet,
            state.searchStr,
            state.ownerStr,
            state.selectMode
          );
        }
      } catch (e) {
        console.warn(e);
      }

      return {
        ...state,
        queriesList: sortedList,
        filteredList,
        questionsAreSharedWithUser: queriesList.some((question) => question.shared),
        questionOwners,
      };

    case SET_QUERIES_LIST_IS_LOADING:
      return {
        ...state,
        queriesListIsLoading: action.payload,
      };

    case SET_QUERIES_LIST_HAS_LOADING_ERROR:
      return {
        ...state,
        queriesListHasLoadingError: action.payload,
      };

    case FILTER_QUERIES_LIST:
      const { currentUser, searchStr, ownerStr, selectMode } = action.payload;
      filteredList = filterQuestionsList(
        state.queriesList,
        currentUser,
        searchStr,
        ownerStr,
        selectMode
      );

      return {
        ...state,
        filteredList,
      };

    case UPDATE_OWNER_STR:
      return {
        ...state,
        ownerStr: action.payload,
      };

    case UPDATE_QUERIES_SEARCH_STR:
      return {
        ...state,
        searchStr: action.payload,
      };

    case UPDATE_SELECT_MODE:
      return {
        ...state,
        selectMode: action.payload,
      };

    case UPDATE_SELECTED_QUESTIONS:
      return {
        ...state,
        selected: action.payload,
      };

    case RESET_QUERIES_LIST_STATE:
      // keep domains state, which has already loaded
      // Only reset questions list
      return {
        ...initialState,
        domains: state.domains,
      };

    case SET_READ_DOMAINS_LIST:
      const listSortedByName = action.payload.domains.sort((a, b) => a.name.localeCompare(b.name));

      const list = listSortedByName.reduce(
        (acc, item) => {
          if (item.qcount === 0) {
            return {
              ...acc,
              empties: [...acc.empties, item],
            };
          } else {
            return {
              ...acc,
              nonEmpties: [...acc.nonEmpties, item],
            };
          }
        },
        { empties: [], nonEmpties: [] }
      );

      return {
        ...state,
        domains: {
          ...state.domains,
          domainsExist: action.payload.domains.length > 0,
          domainsList: list.nonEmpties,
          emptyDomainsList: list.empties,
          draftsList: action.payload.drafts.sort((a, b) =>
            a.description.localeCompare(b.description)
          ),
        },
      };

    case SET_READ_DOMAINS_LIST_PENDING:
      return {
        ...state,
        domains: {
          ...state.domains,
          isLoading: action.payload,
        },
      };

    case SET_READ_DOMAINS_LIST_FAILED:
      return {
        ...state,
        domains: {
          ...state.domains,
          hasError: action.payload.status,
          errorMessage: action.payload.error,
        },
      };

    default:
      return state;
  }
}
