const CARD_BUILDER_CONFIG_VERSION = 2;

export const CardConfigVersions = {
  current: CARD_BUILDER_CONFIG_VERSION,
  v1_5: 1.5,
};

export const isNewVizVersion: (version: number | undefined) => boolean = (version) => {
  return version === CardConfigVersions.current;
};
