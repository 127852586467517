import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'react-router-redux';
import { useSelector as useReduxSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';

import { reducers } from './reducers';
import { authModel } from './Auth/middleware';
import { api } from './Api/middleware';
import { tenantsModel } from './Tenants/middleware';
import { usersModel } from './Users/middleware';
import { rolesModel } from './Roles/middleware';
import { queriesModel } from './Studio/Queries/middleware';
import { queryCreationModel } from './Studio/QueryCreation/middleware';
import { studioApiModel } from './Studio/Api/middleware';
import { dataSourcesModel } from './Studio/DataSources/middleware';
import { propertiesModel } from './Studio/Properties/middleware';
import { datasetsModel } from './Studio/Datasets/middleware';
import { apiDetailsModel } from './ApiDetails/middleware';
import { createBrowserHistory } from 'history';

// Note: this is only used by redux middleware, routerMiddleware
const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: false,
    }).concat([
      ...authModel,
      ...tenantsModel,
      ...usersModel,
      ...rolesModel,
      ...queriesModel,
      ...queryCreationModel,
      ...studioApiModel,
      ...propertiesModel,
      ...datasetsModel,
      ...dataSourcesModel,
      ...apiDetailsModel,
      routeMiddleware,
      api,
    ]),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export type RootState = ReturnType<typeof store.getState>;
