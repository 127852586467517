import Features from '../../../../../Features/feature-flags';
import { ILinks } from '../../../../../components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';
import { BlossomRoutes } from '../../../../RouteConstants';

export const links: ILinks[] = [
  {
    tabItem: {
      to: `/${BlossomRoutes.podManagement.base}/${BlossomRoutes.podManagement.security.base}`,
      label: 'Security',
      variant: 'primary',
      testId: 'customer-management-security-tab',
    },
    permissionRef: BlossomRoutes.podManagement.security.permissions,
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${BlossomRoutes.podManagement.base}/${BlossomRoutes.podManagement.tenant.base}`,
      label: 'Tenants',
      variant: 'primary',
      testId: 'customer-management-tenants-tab',
    },
    permissionRef: BlossomRoutes.podManagement.tenant.permissions,
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${BlossomRoutes.podManagement.base}/${BlossomRoutes.podManagement.admin.base}`,
      label: 'Admins',
      variant: 'primary',
      testId: 'customer-management-admins-tab',
    },
    permissionRef: BlossomRoutes.podManagement.admin.permissions,
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${BlossomRoutes.podManagement.base}/${BlossomRoutes.podManagement.branding.base}`,
      label: 'Branding',
      variant: 'primary',
      testId: 'branding-settings-tab',
    },
    permissionRef: BlossomRoutes.podManagement.branding.permissions,
    featureFlag: (Features as any).podBranding,
  },
];
