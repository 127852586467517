import { createSlice } from '@reduxjs/toolkit';

import vizByCollectionIdReduxSlice from './vizByCollectionId/vizByCollectionId-redux-slice';
import { collectionsInitialState } from './collections-initialState';

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: collectionsInitialState,
  reducers: {
    ...vizByCollectionIdReduxSlice,
  },
});

export const {
  initiateFetchVizByCollectionId,
  fetchVizByCollectionIdSuccess,
  fetchVizByCollectionIdError,

  setIsReordered,
  initiateReorderArray,
  reorderArraySuccess,
  reorderArrayError,

  updateSortableArray,
} = collectionsSlice.actions;

export default collectionsSlice.reducer;
