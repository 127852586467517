export const initialState = {
  queriesList: [],
  filteredList: [],
  questionOwners: [],
  queriesListIsLoading: false,
  queriesListHasLoadingError: false,
  questionsAreSharedWithUser: false,

  searchStr: '',
  ownerStr: '',
  selectMode: false,
  selected: [],

  domains: {
    isLoading: true,
    hasError: false,
    errorMessage: '',
    domainsExist: false,
    domainsList: [],
    emptyDomainsList: [],
    draftsList: [],
  },
};
