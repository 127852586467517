import { PROPERTY_CARD_MENU_ITEM_TEXT } from 'Modules/Studio/Questions/EditQuestion/PropertiesList/PropCardsMoreMenu/MenuItems.constant';

/**
 * Sorts the selected properties alphabetically
 * @param props - the selected properties
 * @returns the sorted properties
 */
export const sortSelectedProps = (props: any[] = []) => {
  return props.sort((a: any, b: any) => {
    let propAName = a?.isSelfJoin
      ? PROPERTY_CARD_MENU_ITEM_TEXT.joinConcepts
      : a?.label?.toLowerCase();
    let propBName = b?.isSelfJoin
      ? PROPERTY_CARD_MENU_ITEM_TEXT.joinConcepts
      : b?.label?.toLowerCase();

    if (propAName < propBName) {
      return -1;
    }
    if (propAName > propBName) {
      return 1;
    }
    return 0;
  });
};
