import { Navigate } from 'react-router-dom-v5-compat';

import { BlossomRoutes, HomeRoutes } from '../../../../RouteConstants';
import Features from '../../../../../Features/feature-flags';
import { IRoutes } from '../../../../../components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';

export const routes: IRoutes[] = [
  {
    routeItem: {
      path: `${BlossomRoutes.podManagement.security.base}`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const SecurityManager = (
          await import('../../../../../Modules/PodManagement/SecurityManager/SecurityManager')
        ).default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={SecurityManager}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={BlossomRoutes.podManagement.security.permissions}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: BlossomRoutes.podManagement.security.permissions,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${BlossomRoutes.podManagement.tenant.base}`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const TenantManager = (
          await import('../../../../../Modules/PodManagement/TenantManager/TenantManager')
        ).default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={TenantManager}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={BlossomRoutes.podManagement.tenant.permissions}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: BlossomRoutes.podManagement.tenant.permissions,
    featureFlag: true,
  },

  {
    routeItem: {
      path: `${BlossomRoutes.podManagement.admin.base}`,
      async lazy() {
        const AdminManager = (
          await import('../../../../../Modules/PodManagement/AdminManager/AdminManager')
        ).default;
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={AdminManager}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={BlossomRoutes.podManagement.admin.permissions}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: BlossomRoutes.podManagement.admin.permissions,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${BlossomRoutes.podManagement.branding.base}`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const Branding = (await import('../../../../../Modules/PodManagement/Branding/Branding'))
          .default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={Branding}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={BlossomRoutes.podManagement.branding.permissions}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: BlossomRoutes.podManagement.branding.permissions,
    featureFlag: (Features as any).podBranding,
  },
];
