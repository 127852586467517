import { RouteObject } from 'react-router-dom-v5-compat';
import { TowerRoutes } from '../../../RouteConstants';

export const TowerChildRoutes: RouteObject[] = [
  {
    path: `${TowerRoutes.dashboard.base}/*`,
    async lazy() {
      const ErrorPage = (await import('../../../../components/ErrorBoundry/ErrorPage')).default;
      const Tower360 = (await import('../../../../Apps/Tower360/Tower360')).default;

      return {
        element: <Tower360 />,
        errorElement: <ErrorPage />,
      };
    },
    children: [
      {
        path: `${TowerRoutes.dashboard.decisionBoards.base}`,
        async lazy() {
          const ErrorPage = (await import('../../../../components/ErrorBoundry/ErrorPage')).default;
          const DecisionBoards = (
            await import('../../../../Apps/Tower360/DecisionBoard/DecisionBoard')
          ).default;

          return {
            element: <DecisionBoards />,
            errorElement: <ErrorPage />,
          };
        },
      },
    ],
  },
];
