import { NonIndexRouteObject } from 'react-router-dom-v5-compat';

import { HomeRoutes } from '../../../../../RouteConstants';

export const SuperAdminLoginPublicRoutes: NonIndexRouteObject[] = [
  {
    id: 'admin-login',
    path: HomeRoutes.adminLogin,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorBoundry = (await import('../../../../../../components/ErrorBoundry/ErrorBoundry'))
        .default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const Login = (await import('../../../../../../Modules/Authentication/Login/Login')).default;
      const { LoginAdminAction } = await import(
        '../../../../../../Modules/Authentication/Login/utils/login.admin.action'
      );

      return {
        element: (
          <HomeLayoutLoginRoutes
            content={
              <ErrorBoundry>
                <Login isAdminLogin={true} />
              </ErrorBoundry>
            }
          />
        ),
        errorElement: <ErrorPage />,
        action: LoginAdminAction,
      };
    },
  },
];
