import { functionValue } from '../../../../../utils/Studio/SelectOptions';

const getOverlapsIdentifierOptions = (overlapsMapByTablename) => {
  const overlapsArray = Object.keys(overlapsMapByTablename);

  let overlapColumnNameOptions = overlapsArray.reduce(
    (overlapsArrAcc, overlapUniqueId) => [
      ...overlapsArrAcc,
      ...overlapsMapByTablename[overlapUniqueId].columnNames.reduce((acc, columnName) => {
        const dataSourceId = Number.parseInt(overlapUniqueId);
        const idColumnName =
          overlapsMapByTablename[overlapUniqueId].idColumnName.indexOf(functionValue) > -1
            ? 'Function'
            : overlapsMapByTablename[overlapUniqueId].idColumnName;

        // TODO: this was old logic to filter out duplicates (I think); unable to test at this time :(
        // const datasetUniqueId = `${dataSetMap[selectedMapping.dataSetId].dataSourceId}.${dataSetMap[selectedMapping.dataSetId].tableName}`;
        // if(overlapUniqueId !== datasetUniqueId) {

        // prevent adding the idColumnName that is already being used
        if (idColumnName !== columnName) {
          return [
            ...acc,
            {
              columnName,
              dataSourceId,
              dataSourceName: overlapsMapByTablename[overlapUniqueId].dataSourceName,
              idColumnName,
              tableName: overlapsMapByTablename[overlapUniqueId].tableName,
            },
          ];
        } else {
          return acc;
        }
      }, []),
    ],
    []
  );

  if (overlapColumnNameOptions.length === 0) {
    overlapColumnNameOptions = [
      { value: null, label: 'There are no lookups available', isDisabled: true },
    ];
  }

  return overlapColumnNameOptions;
};

export default getOverlapsIdentifierOptions;
