import { FC, ReactElement } from 'react';

import { ErrorBoundaryProps } from './constants/errorBoundary.interface';
import ErrorMessage from './ErrorMessage';
import Retry from './Retry';

const ErrorMessageRetry: FC<ErrorBoundaryProps> = (props): ReactElement => {
  return (
    <ErrorMessage {...props}>
      <Retry callback={props.callback} resetState={props.resetState} />
    </ErrorMessage>
  );
};

export default ErrorMessageRetry;
