export const tsParamsInitialState = {
  fetchTypes: null,
  latestValue: null,
  rollup: null,
  timeRange: null,
};

export const initialState = {
  domains: [],
  domainsIsLoading: false,
  domainsHasLoadingError: false,

  domainsWithConceptRelations: [],
  domainsWithConceptRelationsIsLoading: false,
  domainsWithConceptRelationsHasLoadingError: false,

  nodesMap: {},
  superClassMap: {},

  query: {},
  queryIsLoading: true,
  queryHasLoadingError: false,
  queryErrorStatus: -1,

  queryMap: {},
  queryGraphOptions: {},
  conceptInstanceIdMap: {},

  selectedConcept: {},
  selectedConceptColor: '',
  selectedConceptId: null,

  queryIsSaving: false,
  queryHasSavingError: false,
  queryIsDoneSaving: false,

  calcsList: [],
  filteredCalcsList: [],
  calcsListIsLoading: false,
  calcsListHasError: false,

  calc: {},
  calcIsLoading: false,
  calcHasError: false,

  isModelGraphOpen: false,
  isQueryEmpty: true,

  runtimeSettingsModalStatus: false,

  isConfirmDeleteModalOpen: false,
  mappingInfo: {},
  isPropertyEntity: false,
  hasConfirmedEntityRemoval: false,
  isConceptSettingsModalOpen: false,
};
