import { store } from '@redux/store';
import { getPropertyName } from 'utils/Studio/getPropertyName/getPropertyName';
import { ElementTypes } from '@components/Studio/QueryCreationTwo/ActionButtons/utils';

import { RuntimeDependencies, Prop, CalcCounts, SortCounts } from '../constants/runtime.interfaces';

interface ElementProps {
  concept: string;
  property: string;
  aggrType: string;
  calcId?: number;
}

interface Setting {
  elementProps: ElementProps;
  elementType: string;
}

interface CalcsList {
  arguments: ElementProps[];
  description: string;
  id: number;
  propName?: string;
}

interface IDependencyCalc {
  aggrTypes: string[];
  id: number;
  name: string;
  propName: string;
}
export interface IDependencyErrors {
  calcs: IDependencyCalc[];
  isSorted: boolean;
}

export const depErrorsInitialState = {
  calcs: [],
  isSorted: false,
};

/**
 * iterates sortSettings to get counts of prop or aggregate
 * references in the runtimeSettings.sort
 * @param sortSettings
 * @param properties
 * @returns SortCounts
 */
const getSortCounts = (sortSettings: Setting[], properties: Prop[]) => {
  let prop = 0;
  let aggr = 0;
  let aggrTypes: string[] = [];

  properties.forEach((property) => {
    sortSettings.forEach((setting) => {
      const propAndConceptIdMatch =
        setting?.elementProps?.concept === property.conceptId &&
        setting?.elementProps?.property === property.uri;

      const isProp = setting.elementType === ElementTypes.isProp;
      const isAggr = setting.elementType === ElementTypes.isAggr;

      if (isProp && propAndConceptIdMatch) {
        prop += 1;
      } else if (isAggr && propAndConceptIdMatch) {
        aggr += 1;
        aggrTypes.push(setting?.elementProps?.aggrType);
      }
    });
  });

  return {
    prop,
    aggr,
    aggrTypes,
  } as SortCounts;
};

/**
 * Iterate the calc arguments to get a count of how many times the
 * prop or aggregate gets referenced in the questions calculation arguments
 * @param calcArgs
 * @param conceptId
 * @param propUri
 * @returns object
 */
const getCalcArgCounts = (calcArgs: ElementProps[], conceptId: string, propUri: string) => {
  let propCount = 0;
  let aggrCount = 0;
  let aggrTypes: string[] = [];

  calcArgs.forEach((arg) => {
    const propAndConceptIdMatch = arg.concept === conceptId && arg.property === propUri;
    const isProp = arg.aggrType === null;
    const isAggr = arg.aggrType !== null && arg.calcId === null;

    if (isProp && propAndConceptIdMatch) {
      propCount += 1;
    } else if (isAggr && propAndConceptIdMatch) {
      aggrCount += 1;
      aggrTypes.push(arg.aggrType);
    }
  });

  return {
    propCount,
    aggrCount,
    aggrTypes,
  };
};

/**
 * Get counts of calcs referencing the prop or aggregate in the calcList
 * @param calcsList
 * @param props
 * @returns CalcCounts
 */
const getCalcDependencies = (calcsList: CalcsList[], props: Prop[]) => {
  const finalResult: CalcCounts = {
    prop: [],
    aggr: [],
  };

  // Iterate over each pp in the pps array
  props.forEach((property) => {
    const { conceptId, uri: propUri } = property;

    // Reduce calcsList for each property
    const calcDependencies = calcsList.reduce(
      (acc, list) => {
        const { propCount, aggrCount, aggrTypes } = getCalcArgCounts(
          list.arguments,
          conceptId,
          propUri
        );

        // if propCount > 0, append to the array
        const propDependency =
          propCount > 0
            ? [
                ...acc.prop,
                {
                  name: list.description,
                  id: list.id,
                  aggrTypes: [],
                  propName: getPropertyName(propUri),
                },
              ]
            : acc.prop;

        // if aggrCount > 0, append to the array
        const aggrDependency =
          aggrCount > 0
            ? [
                ...acc.aggr,
                {
                  name: list.description,
                  id: list.id,
                  aggrTypes,
                },
              ]
            : acc.aggr;

        return {
          ...acc,
          prop: propDependency,
          aggr: aggrDependency,
        };
      },
      {
        prop: [],
        aggr: [],
      } as CalcCounts
    );

    // Merge the results from this property into the finalResult
    finalResult.prop.push(...calcDependencies.prop);
    finalResult.aggr.push(...calcDependencies.aggr);
  });

  return finalResult;
};

/**
 * returns the various runtime sorting & calc deps for a given property
 * @param prop
 * @returns RuntimeDependencies
 */
export default function getRuntimeSettingsRefs(props: Prop[]) {
  const state = store.getState();

  const calcsList = state?.queryCreation?.calcsList || [];
  const sortSettings = state?.queryCreation?.query?.runtimeParams?.sort || [];

  const sortCounts = getSortCounts(sortSettings, props);
  const calcCounts = getCalcDependencies(calcsList, props);

  return {
    sortCounts,
    calcCounts,
  } as RuntimeDependencies;
}
