import cloneDeep from 'lodash/cloneDeep';

import {
  SET_IS_AUTHORIZED,
  LOG_OUT,
  APP_IS_LOADED,
  APP_IS_LOADING,
  LOGIN_FAILURE,
  SET_AUTH_SETUP_DETAILS,
  SET_AUTH_SETUP_IS_LOADING,
  SET_AUTH_SETUP_HAS_ERROR,
  SET_AUTH_TYPE,
  SET_AUTH_TYPE_HAS_ERROR,
  SET_AUTH_TYPE_IS_LOADING,
} from './actions';
import { removeAuthToken } from '../../utils/Auth';
import { initialState } from './initialState';

export const authReducer = (state = initialState, action = {}) => {
  const stateCopy = cloneDeep(state);

  switch (action.type) {
    case SET_IS_AUTHORIZED:
      stateCopy.isAuth = true;
      stateCopy.didLoginFail = false;
      return stateCopy;

    case LOG_OUT:
      removeAuthToken();
      return {
        ...state,
        isAuth: false,
      };

    case APP_IS_LOADED:
      stateCopy.appIsLoading = false;
      return stateCopy;

    case LOGIN_FAILURE:
      stateCopy.didLoginFail = true;
      return stateCopy;

    case APP_IS_LOADING:
      stateCopy.appIsLoading = true;
      return stateCopy;

    case SET_AUTH_SETUP_DETAILS:
      stateCopy.authSetup = action.payload;
      return stateCopy;

    case SET_AUTH_SETUP_IS_LOADING:
      stateCopy.isAuthSetupLoading = action.payload;
      return stateCopy;

    case SET_AUTH_SETUP_HAS_ERROR:
      stateCopy.authSetupHasError = action.payload;
      return stateCopy;

    case SET_AUTH_TYPE:
      return {
        ...state,
        authType: action.payload.authType ? action.payload.authType : 'ERROR',
      };

    case SET_AUTH_TYPE_HAS_ERROR:
      return {
        ...state,
        authTypeHasError: action.payload,
      };

    case SET_AUTH_TYPE_IS_LOADING:
      return {
        ...state,
        authTypeIsLoading: action.payload,
      };

    default:
      return state;
  }
};
