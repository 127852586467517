import React, { ReactElement } from 'react';
import ActionButtonsContainer from '../ActionButtons/ActionButtonsContainer';
import { IMenuBar } from './constants/MenuBar.interface';

const MenuBar: React.FC<IMenuBar> = ({
  label = '',
  labelProps = {},
  mode = 'accent',
  variant = 'dark',
  testId = '',
  className = '',
  actionButtons = [],
  style,
  paddingLeft = true,
}): ReactElement => {
  return (
    <div
      className={`bt bb b--shade-dark-25 h-40 ttc fw4 flex items-center justify-between ${
        mode === 'accent' || mode === 'shade' ? 'tints-default-ultra-light' : 'shade-default'
      } bg-${mode}-${variant}${className && ' ' + className} ${paddingLeft ? 'pa-24-left' : ''}`}
      data-testid={testId}
      style={{ minHeight: '40px', ...style }}
    >
      <div {...labelProps}>{label}</div>
      <ActionButtonsContainer actionButtons={actionButtons} />
    </div>
  );
};

export default MenuBar;
