export const API_REQUEST = '[App] API Request';
export const apiRequest = (
  method,
  url,
  body,
  onSuccess,
  onError,
  props,
  requestOpts,
  intendedLocation
) => ({
  type: API_REQUEST,
  payload: body,
  meta: { method, url, onSuccess, onError, props, requestOpts },
  intendedLocation,
});

export const API_REQUEST_NO_AUTH = '[App] API Request without auth token';
export const apiRequestNoAuth = (method, url, body, onSuccess, onError, props, requestOpts) => ({
  type: API_REQUEST_NO_AUTH,
  payload: body,
  meta: { method, url, onSuccess, onError, props, requestOpts },
});

export const API_REQUEST_MULTI = '[App] API Request Multi';
export const apiRequestMulti = (requests, onSuccess, onError, props) => ({
  type: API_REQUEST_MULTI,
  requests,
  meta: { onSuccess, onError, props },
});

export const API_LOGIN_REQUEST = '[App] API Login Request';
export const apiLoginRequest = (
  method,
  url,
  body,
  onSuccess,
  onError,
  rememberMe,
  additionalParams = {}
) => ({
  type: API_LOGIN_REQUEST,
  payload: body,
  meta: { method, url, onSuccess, onError, rememberMe, additionalParams },
});
