import { IButtonVariant } from '../constants/Button.interface';

type Args = {
  variant: IButtonVariant;
};

const getButtonClass = ({
  variant,
  disabled,
  className = '',
}: Args & React.ButtonHTMLAttributes<HTMLButtonElement>): string => {
  return `kobai-button button--${variant} h-40 pa-24-horizontal flex items-center fs-14 lh-16 fw4 bn${
    disabled ? ' o-50' : ' pointer'
  }${className ? ` ${className}` : ''}`;
};

export default getButtonClass;
