import {
  SET_API_LIST,
  SET_API_LIST_IS_LOADING,
  SET_API_LIST_HAS_LOADING_ERROR,
  SET_API_DETAILS,
  SET_API_DETAILS_LOADING,
  SET_API_DETAILS_LOADING_ERROR,
  SET_PUBLISHING_API,
  SET_PUBLISH_API_SUCCESS,
  SET_PUBLISH_API_ERROR,
  SET_UNPUBLISH_API_SUCCESS,
  SET_UPDATING_API_KEY,
  SET_UPDATE_API_KEY_SUCCESS,
  SET_UPDATE_API_KEY_ERROR,
  SET_QUERY_API_ID,
  SET_QUERY_API_ID_IS_LOADING,
  SET_QUERY_API_ID_HAS_LOADING_ERROR,
  RESET_PROGRESS_INDICATORS,
} from './actions.js';

const initialState = {
  apiList: [],
  apiListIsLoading: false,
  apiListHasLoadingError: false,
  apiListErrorCode: -1,

  apiDetails: {},
  apiDetailsIsLoading: false,
  apiDetailsHasLoadingError: false,

  publishingApi: false,
  publishApiSuccess: false,
  unPublishSuccess: false,
  publishApiError: false,

  updatingApiKey: false,
  updateApiKeySuccess: false,
  updateApiKeyError: false,

  queryApiId: {},
  queryApiIdIsLoading: false,
  queryApiIdHasLoadingError: false,
};

export function studioApiReducer(state = initialState, action) {
  switch (action.type) {
    // ApiList
    case RESET_PROGRESS_INDICATORS:
      return {
        ...state,
        publishingApi: false,
        publishApiSuccess: false,
        publishApiError: false,

        updatingApiKey: false,
        updateApiKeySuccess: false,
        updateApiKeyError: false,
      };

    case SET_API_LIST:
      return {
        ...state,
        apiList: action.payload,
      };

    case SET_API_LIST_IS_LOADING:
      return {
        ...state,
        apiListIsLoading: action.payload,
      };

    case SET_API_LIST_HAS_LOADING_ERROR:
      return {
        ...state,
        apiListHasLoadingError: action.payload.status,
        apiListErrorCode: action.payload.errorCode === 401 ? 401 : -1,
      };

    case SET_API_DETAILS:
      return {
        ...state,
        apiDetails: action.payload,
      };

    case SET_API_DETAILS_LOADING:
      return {
        ...state,
        apiDetailsIsLoading: action.payload,
      };

    case SET_API_DETAILS_LOADING_ERROR:
      return {
        ...state,
        apiDetailsHasLoadingError: action.payload,
      };

    case SET_PUBLISHING_API:
      return {
        ...state,
        publishingApi: action.payload,
      };

    case SET_PUBLISH_API_SUCCESS:
      return {
        ...state,
        publishApiSuccess: action.payload,
      };

    case SET_UNPUBLISH_API_SUCCESS:
      return {
        ...state,
        unPublishSuccess: action.payload,
      };

    case SET_PUBLISH_API_ERROR:
      return {
        ...state,
        publishApiError: action.payload,
      };

    case SET_UPDATING_API_KEY:
      return {
        ...state,
        updatingApiKey: action.payload,
      };

    case SET_UPDATE_API_KEY_SUCCESS:
      return {
        ...state,
        updateApiKeySuccess: action.payload,
      };

    case SET_UPDATE_API_KEY_ERROR:
      return {
        ...state,
        updateApiKeyError: action.payload,
      };

    case SET_QUERY_API_ID:
      return {
        ...state,
        queryApiId: action.payload,
      };

    case SET_QUERY_API_ID_IS_LOADING:
      return {
        ...state,
        queryApiIdIsLoading: action.payload,
      };

    case SET_QUERY_API_ID_HAS_LOADING_ERROR:
      return {
        ...state,
        queryApiIdHasLoadingError: action.payload,
      };

    default:
      return state;
  }
}
