import { getOverlapsIdentifierOptions } from '../../../components/Studio/QueryMapping/Properties/helpers';
import { getColorSchemes } from '../../../utils/ColorSchemes';

import {
  // edit query mapping
  SET_SELECTED_PROP,
  CLEAR_MAPPING_FOR_PROP,
  // create new mapping def
  SET_MAPPING_DEF_LOCAL,
  SET_MAPPING_DEF,
  SET_MAPPING_DEF_IS_SAVING,
  SET_MAPPING_DEF_HAS_SAVING_ERROR,
  SET_MAPPING_DEF_IS_LOADING,
  SET_MAPPING_DEF_HAS_LOADING_ERROR,

  // conceptIdOverlaps
  SET_CONCEPT_ID_OVERLAPS,
  SET_CONCEPT_ID_OVERLAPS_IS_LOADING,
  SET_CONCEPT_ID_OVERLAPS_HAS_LOADING_ERROR,

  // conceptLookups
  SET_CONCEPT_LOOKUPS,
  SET_CONCEPT_LOOKUPS_IS_LOADING,
  SET_CONCEPT_LOOKUPS_HAS_LOADING_ERROR,

  // fetch prop mappings
  SET_PROP_MAPPING,
  SET_PROP_MAPPING_IS_LOADING,
  SET_PROP_MAPPING_HAS_LOADING_ERROR,

  // datasets
  SET_DATASETS,
  SET_DATASETS_IS_LOADING,
  SET_DATASETS_HAS_LOADING_ERROR,
  SET_DATASET_COLUMNS,
  SET_DATASET_COLUMNS_IS_LOADING,
  SET_DATASET_COLUMNS_HAS_LOADING_ERROR,
  SET_TIMESERIES_DATASET_COLUMNS,
  SET_TIMESERIES_DATASET_COLUMNS_IS_LOADING,
  SET_TIMESERIES_DATASET_COLUMNS_HAS_LOADING_ERROR,
  UPDATE_OVERLAP_CHECKED_FOR_CONCEPT_ID,
  UPDATE_OVERLAP_SELECTED_DATASET_FOR_CONCEPT_ID,

  // relation overlaps
  UPDATE_OVERLAP_CHECKED_FOR_RELATIONS,
  UPDATE_OVERLAP_SELECTED_DATASET_FOR_RELATIONS,
} from './actions.js';
import {
  mappingTabsDefaults,
  mappingDefBase,
  initialState,
  mappingFunctionDef,
  relationLookupsDefaults,
} from './initialState';
import {
  getFormDefaultsFromOverlaps,
  getLookupDefaultValuesForNewMappings,
  getOverlapsMapByTableName,
} from './utils';

const ColorSchemes = getColorSchemes('models');

let updatedSelectedMapping = {};
let updatedConceptIdSettings = mappingTabsDefaults;

export function studioPropertiesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MAPPING_DEF_LOCAL:
      return {
        ...state,
        selectedMapping: {
          ...state.selectedMapping,
          ...action.payload,
        },
      };

    case SET_MAPPING_DEF:
      const dataSets = action.payload.dataSets;
      const dataSetMap = dataSets.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: {
            ...currentValue,
            columns: [],
          },
        }),
        {}
      );

      const dataSetTimestampFormat = dataSetMap[action.payload.datasetId].dataSetProps.timeFormat;
      const dataSetTimeZone = dataSetMap[action.payload.datasetId].dataSetProps.timezone;

      const mappingDef = action.payload.mappingDef;

      return {
        ...state,
        selectedMapping: {
          // set mappingDefBase as defaults
          ...mappingDefBase,

          // merge in mappingDef from the BE
          ...mappingDef,

          // convert to 0, if it used to be 1 (old logic)
          mappingIdTypeId: mappingDef.mappingIdTypeId === 1 ? 0 : mappingDef.mappingIdTypeId,

          // keep existing properties used for labeling
          modalLabels: state.selectedMapping
            ? state.selectedMapping.modalLabels
            : {
                propName: '',
                conceptName: '',
              },

          // override defaults from dataSets
          timestampProps: {
            timestampFormat:
              dataSetTimestampFormat !== null
                ? dataSetTimestampFormat
                : mappingDef.timestampProps.timestampFormat,
            timeZone:
              dataSetTimeZone !== null ? dataSetTimeZone : mappingDef.timestampProps.timeZone,
            alignTo: mappingDef.timestampProps?.alignTo,
          },
        },
        list: dataSets,
        dataSetMap,
        generalSettings: {
          ...state.generalSettings,
          overlapChecked: mappingDef?.relationLookups?.datasourceId ?? false,
        },
        conceptIdSettings: {
          ...state.conceptIdSettings,
          overlapChecked:
            mappingDef.overlapDatasourceId !== null && mappingDef.overlapDatasourceId !== undefined,
        },
      };

    case SET_MAPPING_DEF_IS_SAVING:
      return {
        ...state,
        selectedMappingDefIsSaving: action.payload,
      };

    case SET_MAPPING_DEF_HAS_SAVING_ERROR:
      return {
        ...state,
        selectedMappingDefHasSavingError: action.payload,
      };

    case SET_MAPPING_DEF_IS_LOADING:
      return {
        ...state,
        defsErrorCode: -1,
        selectedMappingDefIsLoading: action.payload,
      };

    case SET_MAPPING_DEF_HAS_LOADING_ERROR:
      return {
        ...state,
        selectedMappingDefHasLoadingError: action.payload.status,
        defsErrorCode: action.payload.errorCode,
      };

    // edit query mappings
    case SET_SELECTED_PROP:
      const selectedMapping = {
        ...mappingDefBase,
        queryId: action.payload.queryId,
        solutionId: action.payload.solutionId,
        conceptUri: action.payload.selectedConcept.uri,
        conceptTypeUri: action.payload.selectedConcept.conceptTypeUri,
        propertyUri: action.payload.selectedProp.uri,
        // NOTE: when fetching props, key name is propTypeUri BUT when saving a def, BE requires 'propertyTypeUri'
        propertyTypeUri: action.payload.selectedProp.propTypeUri,

        // used for displaying prop name & concept
        modalLabels: {
          propName: action.payload.selectedProp.label,
          conceptName: action.payload.selectedConcept.label,
        },
      };

      return {
        ...state,
        selectedMapping,
        selectedMappingQueryDesc: action.payload.queryDescription,
      };

    case CLEAR_MAPPING_FOR_PROP:
      return {
        ...state,
        selectedConcept: null,
        selectedProperty: null,
        selectedMapping: null,
        selectedMappingQueryDesc: '',
        conceptIdSettings: mappingTabsDefaults,
        generalSettings: mappingTabsDefaults,
        conceptLookups: [],
        selectedMappingDefHasSavingError: false,
      };

    case SET_CONCEPT_ID_OVERLAPS:
      const conceptIdOverlaps = action.payload;

      let formDefaultsForNewMapping = {};
      updatedSelectedMapping = state.selectedMapping;

      // pre-populate defaults if un-mapped property that has overlaps
      if (conceptIdOverlaps.length > 0 && state.selectedMapping.id === null) {
        formDefaultsForNewMapping = getFormDefaultsFromOverlaps(
          conceptIdOverlaps,
          state.dataSetMap,
          state.selectedMapping
        );

        updatedSelectedMapping = {
          ...state.selectedMapping,
          idColumnName: formDefaultsForNewMapping.idColumnName,
          mappingIdTypeId: formDefaultsForNewMapping.mappingIdTypeId,
          idFunction: formDefaultsForNewMapping.idFunction,
        };
      }

      const overlapsMapByTablename = getOverlapsMapByTableName(conceptIdOverlaps);
      const overlapColumnNameOptions = getOverlapsIdentifierOptions(overlapsMapByTablename);

      return {
        ...state,
        conceptIdOverlaps,
        overlapsMapByTablename,
        overlapColumnNameOptions,
        selectedMapping: updatedSelectedMapping,
      };

    case SET_CONCEPT_LOOKUPS:
      const conceptLookups = action.payload;

      updatedConceptIdSettings = state.conceptIdSettings;
      updatedSelectedMapping = state.selectedMapping;
      let overlapSelectedDataset = null;

      // populate the Lookups defaults ONLY For new mappings
      if (state.selectedMapping.id === null) {
        const { overlapDefaultValues, overlapChecked } =
          getLookupDefaultValuesForNewMappings(conceptLookups);

        updatedSelectedMapping = {
          ...state.selectedMapping,
          ...overlapDefaultValues,
        };

        updatedConceptIdSettings = {
          ...state.conceptIdSettings,
          overlapChecked,
        };
      }

      if (conceptLookups.length > 0) {
        overlapSelectedDataset = state.conceptIdOverlaps.find(
          (item) => item.dataSourceId === updatedSelectedMapping.overlapDatasourceId
        );
      }

      return {
        ...state,
        conceptLookups,
        selectedMapping: updatedSelectedMapping,
        conceptIdSettings: {
          ...updatedConceptIdSettings,
          overlapSelectedDataset: overlapSelectedDataset || null,
        },
      };

    case SET_CONCEPT_LOOKUPS_IS_LOADING:
      return {
        ...state,
        conceptLookupsIsLoading: action.payload,
      };

    case SET_CONCEPT_LOOKUPS_HAS_LOADING_ERROR:
      return {
        ...state,
        conceptLookupsHasLoadingError: action.payload,
      };

    case SET_CONCEPT_ID_OVERLAPS_IS_LOADING:
      return {
        ...state,
        conceptIdOverlapsIsLoading: action.payload,
      };

    case SET_CONCEPT_ID_OVERLAPS_HAS_LOADING_ERROR:
      return {
        ...state,
        conceptIdOverlapsHasLoadingError: action.payload,
      };

    case SET_PROP_MAPPING:
      return {
        ...state,
        propMappingList: action.payload.map((concept, index) => ({
          ...concept,
          cardHeaderBgColor: ColorSchemes[index],
        })),
      };

    case SET_PROP_MAPPING_IS_LOADING:
      return {
        ...state,
        propMappingIsLoading: action.payload,
      };

    case SET_PROP_MAPPING_HAS_LOADING_ERROR:
      return {
        ...state,
        propMappingHasLoadingError: action.payload,
      };

    case SET_DATASETS:
      return {
        ...state,
        list: action.payload,
        dataSetMap: action.payload.reduce(
          (accumulator, currentValue, index) => ({
            ...accumulator,
            [currentValue.id]: { ...currentValue, columns: [] },
          }),
          {}
        ),
      };

    case SET_DATASET_COLUMNS:
      // TODO: remove sorting once it comes back sorted from BE
      const dataSetColumns = action.payload.columns.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );
      return {
        ...state,
        dataSetColumns,
        mappingColumns: dataSetColumns.map((col) => ({
          value: col,
          label: col,
        })),
        dataSetMap: {
          ...state.dataSetMap,
          [action.payload.dataSetId]: {
            ...state.dataSetMap[action.payload.dataSetId],
            // TODO: remove sorting once it comes back sorted from BE
            columns: action.payload.columns.sort((a, b) =>
              a.toLowerCase().localeCompare(b.toLowerCase())
            ),
          },
        },
      };

    case SET_DATASET_COLUMNS_IS_LOADING:
      return {
        ...state,
        dataSetColumns: [],
        mappingColumns: [],
        dataSetColumnsIsLoading: action.payload,
      };

    case SET_DATASET_COLUMNS_HAS_LOADING_ERROR:
      return {
        ...state,
        dataSetColumns: [],
        mappingColumns: [],
        dataSetColumnsHadLoadingError: action.payload,
      };

    case SET_TIMESERIES_DATASET_COLUMNS:
      return {
        ...state,
        timeseriesDataSetColumns: action.payload.columns,
        timeseriesDataSetMap: {
          ...state.timeseriesDataSetMap,
          [action.payload.dataSetId]: {
            ...state.timeseriesDataSetMap[action.payload.dataSetId],
            // TODO: remove sorting once it comes back sorted from BE
            columns: action.payload.columns.sort((a, b) =>
              a.toLowerCase().localeCompare(b.toLowerCase())
            ),
          },
        },
      };

    case SET_TIMESERIES_DATASET_COLUMNS_IS_LOADING:
      return {
        ...state,
        timeseriesDataSetColumns: [],
        timeseriesDataSetColumnsIsLoading: action.payload,
      };

    case SET_TIMESERIES_DATASET_COLUMNS_HAS_LOADING_ERROR:
      return {
        ...state,
        timeseriesDataSetColumns: [],
        timeseriesDataSetColumnsHadLoadingError: action.payload,
      };

    case SET_DATASETS_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_DATASETS_HAS_LOADING_ERROR:
      return {
        ...state,
        hasLoadingError: action.payload.status,
        dataSetErrorCode: action.payload.error.status,
      };

    case UPDATE_OVERLAP_CHECKED_FOR_RELATIONS:
      const isSelected = action.payload === true;
      return {
        ...state,
        generalSettings: isSelected
          ? {
              ...state.generalSettings,
              overlapChecked: action.payload,
            }
          : mappingTabsDefaults,

        // reset defaults when the user selects removes the overlap
        selectedMapping: !isSelected
          ? {
              ...state.selectedMapping,
              relationLookups: relationLookupsDefaults,
            }
          : state.selectedMapping,
      };

    case UPDATE_OVERLAP_CHECKED_FOR_CONCEPT_ID:
      updatedConceptIdSettings =
        action.payload === true
          ? {
              ...state.conceptIdSettings,
              overlapChecked: action.payload,
            }
          : mappingTabsDefaults;

      updatedSelectedMapping =
        action.payload === true
          ? state.selectedMapping
          : {
              ...state.selectedMapping,
              // reset all overlap values
              mappingOverlapIdTypeId: 0,
              overlapIdFunction: mappingFunctionDef,
              overlapColumnName: null,
              overlapTableName: null,
              overlapDatasourceId: null,
            };

      return {
        ...state,
        conceptIdSettings: updatedConceptIdSettings,
        selectedMapping: updatedSelectedMapping,
      };

    case UPDATE_OVERLAP_SELECTED_DATASET_FOR_CONCEPT_ID:
      return {
        ...state,
        conceptIdSettings: {
          ...state.conceptIdSettings,
          overlapSelectedDataset: action.payload,
        },
      };

    case UPDATE_OVERLAP_SELECTED_DATASET_FOR_RELATIONS:
      return {
        ...state,
        generalSettings: {
          ...state.generalSettings,
          overlapSelectedDataset: action.payload,
        },
      };

    default:
      return state;
  }
}
