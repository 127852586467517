export const labelColor = '#182027';

export const getConceptName = (uri) => {
  try {
    let conceptName = uri.split('/');
    return conceptName[conceptName.length - 1].split('#')[0];
  } catch (e) {
    return '';
  }
};
/**
 * Gets the domain name from a propertyUri string, the domain name is on the second to the last property after a split of the property string.
 * @param {string} propertyUri
 * @returns {string} domainName
 */
export const getDomainName = (propertyUri) => {
  try {
    let propertyUriParts = propertyUri.split('/');
    return propertyUriParts[propertyUriParts.length - 2];
  } catch (e) {
    return '';
  }
};
