export const PROPERTY_CARD_MENU_ITEM_TEXT = {
  filters: 'Filters',
  settings: 'Settings',
  relations: 'Relations',
  aggregates: 'Aggregates',
  joinConcepts: 'Join concepts',
  removeProperty: 'Remove',
  windowFunctions: 'Window Functions',
};

export type handleRemovePropType = () => void;
