import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tower360CollectionsInitialState } from '../../constants/tower360.constants';
import { ITower360Collections, ITower360SelectedViz } from '../../constants/tower360.interfaces';

const updateTowerCollectionsReduxSlice = {
  resetCollectionsState: (state: ITower360Collections) => {
    state.showDownloadView = Tower360CollectionsInitialState.showDownloadView;
    state.showExpandedView = Tower360CollectionsInitialState.showExpandedView;
    state.selectedViz = Tower360CollectionsInitialState.selectedViz;
  },

  openExpandedView: (state: ITower360Collections, action: PayloadAction<ITower360SelectedViz>) => {
    state.selectedViz.item = action.payload.item;
    state.selectedViz.id = action.payload.id;
    state.showExpandedView = true;
  },

  closeExpandedView: (state: ITower360Collections) => {
    state.showExpandedView = false;
    state.selectedViz = Tower360CollectionsInitialState.selectedViz;
  },

  openDownloadView: (state: ITower360Collections, action: PayloadAction<ITower360SelectedViz>) => {
    state.selectedViz.item = action.payload.item;
    state.selectedViz.id = action.payload.id;
    state.showDownloadView = true;
  },

  closeDownloadView: (state: ITower360Collections) => {
    state.showDownloadView = false;
    state.selectedViz = Tower360CollectionsInitialState.selectedViz;
  },
};

const tower360CollectionsSlice = createSlice({
  name: 'tower360Collections',
  initialState: Tower360CollectionsInitialState,
  reducers: {
    ...updateTowerCollectionsReduxSlice,
  },
});

export const {
  resetCollectionsState,
  openExpandedView,
  closeExpandedView,
  openDownloadView,
  closeDownloadView,
} = tower360CollectionsSlice.actions;

export default tower360CollectionsSlice.reducer;
