import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subQuestionsModalInitialState as initialState } from './constants/subQuestionsModal.initialState';
import {
  ISubQuestionsModalState,
  modalViewType,
  QuestionOutputsType,
} from './constants/subQuestionsModal.interface';
import {
  ICalcs,
  IQuestionById,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';

const updateSubQuestionsModalReduxSlice = {
  resetSubQuestionModalState: (state: ISubQuestionsModalState) => {
    state.isModalOpen = initialState.isModalOpen;
    state.modalView = initialState.modalView;
    state.selectedQuestion = initialState.selectedQuestion;
  },

  openModal: (state: ISubQuestionsModalState) => {
    state.isModalOpen = true;
  },

  closeModal: (state: ISubQuestionsModalState) => {
    state.isModalOpen = false;
  },

  updateView: (state: ISubQuestionsModalState, action: PayloadAction<modalViewType>) => {
    state.modalView = action.payload;
  },

  updateSelectedQuestionPayload: (
    state: ISubQuestionsModalState,
    action: PayloadAction<IQuestionById>
  ) => {
    state.selectedQuestion.payload = {
      ...state.selectedQuestion.payload,
      ...action.payload,
    };
  },

  updateSelectedQuestionQueryMap: (state: ISubQuestionsModalState, action: PayloadAction<any>) => {
    state.selectedQuestion.queryMap = action.payload;
  },

  updateSelectedQuestionGraph: (state: ISubQuestionsModalState, action: PayloadAction<any>) => {
    state.selectedQuestion.graphOptions = action.payload;
  },

  updateSelectedQuestionProperties: (
    state: ISubQuestionsModalState,
    action: PayloadAction<QuestionOutputsType[]>
  ) => {
    state.selectedQuestion.propertiesList = action.payload;
  },

  updateSelectedQuestionCalcs: (
    state: ISubQuestionsModalState,
    action: PayloadAction<ICalcs[]>
  ) => {
    state.selectedQuestion.calcs.list = action.payload;
  },

  initializeQuestionCalcsFetch: (state: ISubQuestionsModalState) => {
    state.selectedQuestion.calcs.loading = true;
    state.selectedQuestion.calcs.hasErrors = false;
  },

  questionCalcsFetchSuccess: (state: ISubQuestionsModalState) => {
    state.selectedQuestion.calcs.loading = false;
    state.selectedQuestion.calcs.hasErrors = false;
  },

  questionCalcsFetchError: (state: ISubQuestionsModalState) => {
    state.selectedQuestion.calcs.loading = false;
    state.selectedQuestion.calcs.hasErrors = true;
  },

  resetSelectedQuestion: (state: ISubQuestionsModalState) => {
    state.selectedQuestion = initialState.selectedQuestion;
  },

  initializeQuestionFetch: (state: ISubQuestionsModalState) => {
    state.selectedQuestion.loading = true;
    state.selectedQuestion.hasErrors = false;
  },

  questionFetchSuccess: (state: ISubQuestionsModalState) => {
    state.selectedQuestion.loading = false;
    state.selectedQuestion.hasErrors = false;
  },

  questionFetchError: (state: ISubQuestionsModalState) => {
    state.selectedQuestion.loading = false;
    state.selectedQuestion.hasErrors = true;
  },
};

const subQuestionsModalSlice = createSlice({
  name: 'subQuestionsModal',
  initialState: initialState,
  reducers: {
    ...updateSubQuestionsModalReduxSlice,
  },
});

export const {
  resetSubQuestionModalState,
  openModal,
  closeModal,
  updateView,
  updateSelectedQuestionPayload,
  resetSelectedQuestion,
  initializeQuestionFetch,
  questionFetchSuccess,
  questionFetchError,
  updateSelectedQuestionGraph,
  updateSelectedQuestionProperties,
  updateSelectedQuestionQueryMap,

  // calcs
  updateSelectedQuestionCalcs,
  initializeQuestionCalcsFetch,
  questionCalcsFetchSuccess,
  questionCalcsFetchError,
} = subQuestionsModalSlice.actions;

export default subQuestionsModalSlice.reducer;
