import React, { useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import WarningRounded from '@mui/icons-material/WarningRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { BackButton } from '@components/Blackbird/Buttons';

type Props = {
  modalName?: string;
  showHelp?: boolean;
  showBackBtn?: boolean;
  hideHelpBtn?: boolean;
  closeIconTestId?: string;
  showWarning?: boolean;
  showError?: boolean;
  hideCloseButton?: boolean;

  closeModal?: () => void;
  handleGoBack?: () => void;
  toggleShowHelp?: (show: boolean) => void;
  setHelpButtonRef?: (ref: HTMLButtonElement) => void;
};

function CloseAndHelpBtns({
  modalName,
  showHelp,
  showError,
  showBackBtn = false,
  hideHelpBtn,
  showWarning,
  closeIconTestId,
  hideCloseButton,

  closeModal,
  handleGoBack,
  toggleShowHelp,
  setHelpButtonRef,
}: Props) {
  const helpButtonRef = useRef(null);

  useEffect(() => {
    if (setHelpButtonRef && helpButtonRef.current) {
      setHelpButtonRef(helpButtonRef.current);
    }
  }, [setHelpButtonRef]);

  const handleGoBackBtn = () => {
    if (handleGoBack) {
      handleGoBack();
    } else {
      return;
    }
  };

  return (
    <div
      test-id="close-and-help-btns"
      className="ttc fs-24 lh-28 b flex items-center justify-between h-60"
    >
      {(showBackBtn || showWarning || showError) && (
        <div
          className="h-inherit"
          style={{
            backgroundColor: 'rgba(234,234,237, 0.2)',
          }}
        >
          {showBackBtn && (
            <BackButton
              buttonProps={{
                'test-id': 'handle-go-back',
                onClick: handleGoBackBtn,
              }}
            />
          )}
          {(showWarning || showError) && (
            <div className="flex items-center justify-center br b--nobel h-inherit width-60">
              {showError ? (
                <CancelRoundedIcon className="error-red" style={{ height: 24, width: 24 }} />
              ) : (
                <WarningRounded className="fire-bush" style={{ height: 24, width: 24 }} />
              )}
            </div>
          )}
        </div>
      )}
      <div className="flex flex-auto items-center justify-center">{modalName}</div>
      <div className="flex items-center h-inherit">
        {!hideHelpBtn && (
          <div
            ref={helpButtonRef}
            test-id="toggle-show-help"
            className={
              'help-btn relative flex items-center justify-center h-inherit pointer br bl b--nobel width-60 ' +
              (showHelp ? ' active bg-lavender' : '')
            }
            onClick={() => toggleShowHelp && toggleShowHelp(!showHelp)}
          >
            <HelpIcon
              style={{
                fontSize: 28,
              }}
            />
          </div>
        )}
        <div
          className={hideCloseButton ? 'dn' : 'flex items-center justify-center h-inherit pointer'}
          test-id={closeIconTestId}
          data-testid={closeIconTestId}
          style={{
            width: 60,
          }}
          onClick={closeModal}
        >
          <CloseIcon
            style={{
              fontSize: 28,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CloseAndHelpBtns;
