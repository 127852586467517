import React, { ReactElement, useContext } from 'react';

import '../ButtonGeneralStyles.css';
import './Button.css';
import { context } from '../../../Theme/Context';
import { getButtonClass, getButtonStyle } from './helpers';
import { IButtonProps } from './constants/Button.interface';

const Button: React.FC<IButtonProps> = ({
  testId,
  type = 'button',
  variant,
  label,
  disabled,
  className,
  ...rest
}): ReactElement => {
  const theme = useContext(context);

  return (
    <button
      type={type}
      data-testid={testId}
      className={getButtonClass({ variant, disabled, className })}
      disabled={disabled}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...getButtonStyle({ variant, theme }),
      }}
      {...rest}
    >
      {label}
    </button>
  );
};

export default Button;
