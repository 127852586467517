export const TOGGLE_RUNTIME_SETTINGS_MODAL = '[Query Creation] toggle runtime settings modal';
export const toggleRuntimeSettingsModal = (isOpen) => ({
  type: TOGGLE_RUNTIME_SETTINGS_MODAL,
  payload: isOpen,
});

export const RESET_QUERY_STATE = '[Query Creation] Reset query state when component unmounts';
export const resetQueryState = () => ({ type: RESET_QUERY_STATE });

// fetch all domains
export const SET_DOMAINS = '[Query Creation] Set domains';
export const setDomains = (domain) => ({ type: SET_DOMAINS, payload: domain });

export const SET_DOMAINS_IS_LOADING = '[Query Creation] Set domains Is Loading';
export const setDomainsIsLoading = (status) => ({ type: SET_DOMAINS_IS_LOADING, payload: status });

export const SET_DOMAINS_HAS_LOADING_ERROR = '[Query Creation] Set domains Has Loading Error';
export const setDomainsHasLoadingError = (status) => ({
  type: SET_DOMAINS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_DOMAINS = '[Query Creation] Fetch domains';
export const FETCH_DOMAINS_SUCCESS = '[Query Creation] Fetch domains Success';
export const FETCH_DOMAINS_ERROR = '[Query Creation] Fetch domains Error';
export const fetchDomains = () => ({ type: FETCH_DOMAINS });

export const FETCH_DOMAINS_THEN_QUERY = '[Query Creation] Fetch domains then query';
export const FETCH_DOMAINS_THEN_QUERY_SUCCESS = '[Query Creation] Fetch domains then query Success';
export const FETCH_DOMAINS_THEN_QUERY_ERROR = '[Query Creation] Fetch domains then query Error';
export const fetchDomainsThenQuery = (queryId) => ({
  type: FETCH_DOMAINS_THEN_QUERY,
  payload: { queryId },
});

// Fetch domains with concept relations
export const SET_DOMAINS_WITH_CONCEPT_RELATIONS =
  '[Query Creation] Set domains with concept relations';
export const setDomainsWithConceptRelations = (domain) => ({
  type: SET_DOMAINS_WITH_CONCEPT_RELATIONS,
  payload: domain,
});

export const SET_DOMAINS_WITH_CONCEPT_RELATIONS_IS_LOADING =
  '[Query Creation] Set domains Is Loading with concept relations';
export const setDomainsWithConceptRelationsIsLoading = (status) => ({
  type: SET_DOMAINS_WITH_CONCEPT_RELATIONS_IS_LOADING,
  payload: status,
});

export const SET_DOMAINS_WITH_CONCEPT_RELATIONS_HAS_LOADING_ERROR =
  '[Query Creation] Set domains with concept relations Has Loading Error';
export const setDomainsWithConceptRelationsHasLoadingError = (status) => ({
  type: SET_DOMAINS_WITH_CONCEPT_RELATIONS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_DOMAINS_WITH_CONCEPT_RELATIONS =
  '[Query Creation] Fetch domains with concept relations';
export const FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_SUCCESS =
  '[Query Creation] Fetch domains with concept relations Success';
export const FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_ERROR =
  '[Query Creation] Fetch domains with concept relations Error';
export const fetchDomainsWithConceptRelations = (domainId, conceptURI) => ({
  type: FETCH_DOMAINS_WITH_CONCEPT_RELATIONS,
  payload: { domainId, conceptURI },
});

// fetch query by id
export const SET_QUERY_BY_ID = '[Query Creation] Set query by id';
export const setQueryById = (queryById) => ({ type: SET_QUERY_BY_ID, payload: queryById });

export const SET_QUERY_BY_ID_IS_LOADING = '[Query Creation] Set query by id Is Loading';
export const setQueryByIdIsLoading = (status) => ({
  type: SET_QUERY_BY_ID_IS_LOADING,
  payload: status,
});

export const SET_QUERY_BY_ID_HAS_LOADING_ERROR =
  '[Query Creation] Set query by id Has Loading Error';
export const setQueryByIdHasLoadingError = (status, error) => ({
  type: SET_QUERY_BY_ID_HAS_LOADING_ERROR,
  payload: { status, error },
});

export const FETCH_QUERY_BY_ID = '[Query Creation] Fetch query by id';
export const FETCH_QUERY_BY_ID_SUCCESS = '[Query Creation] Fetch query by id Success';
export const FETCH_QUERY_BY_ID_ERROR = '[Query Creation] Fetch query by id Error';
export const fetchQueryById = (id) => ({ type: FETCH_QUERY_BY_ID, id });

// open/close model graph
export const TOGGLE_MODEL_GRAPH = '[Query Creation] toggle model graph isOpen';
export const toggleModelGraph = () => ({ type: TOGGLE_MODEL_GRAPH });

export const SET_SELECTED_CONCEPT_FOR_QUERY = '[Query Creation] set selected concept for query';
export const setSelectedConceptForQuery = (selectedConceptId, concept, color) => ({
  type: SET_SELECTED_CONCEPT_FOR_QUERY,
  payload: { selectedConceptId, concept, color },
});

export const CLEAR_SELECTED_CONCEPT = '[Query Creation] clear selected concept';
export const clearSelectedConcept = () => ({ type: CLEAR_SELECTED_CONCEPT });

export const UPDATE_SELECTED_CONCEPT_PROPERTIES =
  '[Query Creation] update selected concept properties after saving query';
export const updateSelectedConceptProperties = (selectedConceptId) => ({
  type: UPDATE_SELECTED_CONCEPT_PROPERTIES,
  payload: { selectedConceptId },
});

// save query
export const INIT_SAVING_QUERY = 'INIT_SAVING_QUERY';
export const initSavingQuery = () => ({
  type: INIT_SAVING_QUERY,
});

export const DONE_SAVING_QUERY = 'DONE_SAVING_QUERY';
export const doneSavingQuery = () => ({
  type: DONE_SAVING_QUERY,
});

export const DONE_SAVING_QUERY_WITH_ERROR = 'DONE_SAVING_QUERY_WITH_ERROR';
export const doneSavingQueryWithError = () => ({
  type: DONE_SAVING_QUERY_WITH_ERROR,
});

export const UPDATE_QUERY = 'UPDATE_QUERY';
export const updateQuery = (query, additionalProps) => ({
  type: UPDATE_QUERY,
  payload: { query, additionalProps },
});

export const SET_SAVED_QUERY_ID = '[Query Creation] Set saved query id';
export const setSavedQueryId = (queryById, keepSelectedConcept) => ({
  type: SET_SAVED_QUERY_ID,
  payload: { queryById, keepSelectedConcept },
});

export const SAVE_QUERY_IS_DONE_SAVING = '[Query Creation] Save query is done saving';
export const saveQueryIsDoneSaving = (status) => ({
  type: SAVE_QUERY_IS_DONE_SAVING,
  payload: status,
});

export const SAVE_QUERY_IS_SAVING = '[Query Creation] Save query is saving';
export const saveQueryIsSaving = (status) => ({ type: SAVE_QUERY_IS_SAVING, payload: status });

export const SAVE_QUERY_HAS_SAVING_ERROR = '[Query Creation] Save query has saving error';
export const saveQueryHasSavingError = (status) => ({
  type: SAVE_QUERY_HAS_SAVING_ERROR,
  payload: status,
});

export const SAVE_QUERY = '[Query Creation] Save query';
export const SAVE_QUERY_SUCCESS = '[Query Creation] Save query Success';
export const SAVE_QUERY_ERROR = '[Query Creation] Save query Error';
export const saveQuery = (updatedQuery, keepSelectedConcept) => ({
  type: SAVE_QUERY,
  data: { ...updatedQuery },
  keepSelectedConcept,
});

// update question w/added sub-query
export const UPDATE_QUERY_WITH_ADDED_SUB_QUERY = 'UPDATE_QUERY_WITH_ADDED_SUB_QUERY';
export const updateQueryWithAddedSubQuery = (query, updatedQueryGraphOptions) => ({
  type: UPDATE_QUERY_WITH_ADDED_SUB_QUERY,
  payload: { query, updatedQueryGraphOptions },
});

export const HIGHLIGHT_SELECTED_CONCEPT_ON_GRAPH = 'HIGHLIGHT_SELECTED_CONCEPT_ON_GRAPH';
export const highlightSelectedConceptOnGraph = (selectedConceptId) => ({
  type: HIGHLIGHT_SELECTED_CONCEPT_ON_GRAPH,
  payload: { selectedConceptId },
});

export const RESET_HIGHLIGHTED_CONCEPT_ON_GRAPH = 'RESET_HIGHLIGHTED_CONCEPT_ON_GRAPH';
export const resetHighlightedConceptOnGraph = () => ({
  type: RESET_HIGHLIGHTED_CONCEPT_ON_GRAPH,
});

export const ADD_CONCEPT = '[Query Creation] Add concept';
export const ADD_CONCEPT_SUCCESS = '[Query Creation] Add concept Success';
export const ADD_CONCEPT_ERROR = '[Query Creation] Add concept Error';
export const addConcept = (updatedQuery, newConceptId, newConceptColor) => ({
  type: ADD_CONCEPT,
  data: updatedQuery,
  newConceptId,
  newConceptColor,
});

// ts params
export const UPDATE_TS_PARAMS = '[Query Creation] update query timeseries params';
export const updateTsParams = (updatedParams) => ({
  type: UPDATE_TS_PARAMS,
  payload: updatedParams,
});

// calcs list
export const SET_CALCS_LIST = '[Query Creation] set list of calcs by query id';
export const setCalcsList = (list) => ({ type: SET_CALCS_LIST, payload: list });

export const SET_CALCS_LIST_IS_LOADING =
  '[Query Creation] set list of calcs by query id is loading';
export const setCalcsListIsLoading = (status) => ({
  type: SET_CALCS_LIST_IS_LOADING,
  payload: status,
});

export const SET_CALCS_LIST_HAS_ERROR =
  '[Query Creation] set list of calcs by query id has loading error';
export const setCalcsListHasError = (status) => ({
  type: SET_CALCS_LIST_HAS_ERROR,
  payload: status,
});

export const GET_CALCS_LIST_SUCCESS = '[Query Creation] set list of calcs by query id success';
export const GET_CALCS_LIST_ERROR = '[Query Creation] set list of calcs by query id error';
export const GET_CALCS_LIST = '[Query Creation] get list of calcs by query id';
export const getCalcsList = (queryId) => ({ type: GET_CALCS_LIST, payload: queryId });

// Query Entity Deletion
export const IS_CONFIRM_DELETE_MODAL_OPEN = 'IS_CONFIRM_DELETE_MODAL_OPEN';
export const setIsConfirmDeleteModalOpen = (isOpen) => ({
  type: IS_CONFIRM_DELETE_MODAL_OPEN,
  isOpen,
});

export const SET_MAPPING_INFO = 'SET_MAPPING_INFO';
export const setMappingInfo = (mappingInfo) => ({ type: SET_MAPPING_INFO, mappingInfo });

export const IS_PROPERTY_ENTITY = 'IS_PROPERTY_ENTITY';
export const setIsPropertyEntity = (isPropertyEntity) => ({
  type: IS_PROPERTY_ENTITY,
  isPropertyEntity,
});

export const HAS_CONFIRMED_ENTITY_REMOVAL = 'HAS_CONFIRMED_ENTITY_REMOVAL';
export const setHasConfirmedEntityRemoval = (hasConfirmedEntityRemoval) => ({
  type: HAS_CONFIRMED_ENTITY_REMOVAL,
  hasConfirmedEntityRemoval,
});

export const IS_CONCEPT_SETTINGS_MODAL_OPEN = 'IS_CONCEPT_SETTINGS_MODAL_OPEN';
export const setIsConceptSettingsModalOpen = (isConceptSettingsModalOpen) => ({
  type: IS_CONCEPT_SETTINGS_MODAL_OPEN,
  isConceptSettingsModalOpen,
});

export const FETCH_MAPPING_INFO = 'FETCH_MAPPING_INFO';
export const FETCH_MAPPING_INFO_SUCCESS = 'FETCH_MAPPING_INFO_SUCCESS';
export const FETCH_MAPPING_INFO_FAILURE = 'FETCH_MAPPING_INFO_FAILURE';
export const fetchMappingInfo = (queryId, solutionId) => ({
  type: FETCH_MAPPING_INFO,
  payload: { queryId, solutionId },
});
