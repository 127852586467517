import { TowerCardItem } from '../../../../../utils/Viz/@types/visualDefV1';
import { CardConfigVersions } from '../../../../../utils/Viz/CardVersions';
import { CARD_STEPS } from '../helpers/Constants';
import { ICardBuilderState } from './cardBuilder.interface';

export const V1SelectedConfig: TowerCardItem = {
  id: -1,
  queryId: -1,
  collections: [],
  createdBy: '',
  modifiedBy: '',
  visualDef: {
    version: undefined,
    data: {
      filters: {},
      hideFilters: {},
      requiresCalculation: false,
      value: '',
      name: '',
      groupBy: '',
      calcFn: '',
      barLineCombo: {
        series: [],
        xAxis: '',
        xAxisType: '',
        groupBy: '',
      },
      dualYAxis: {
        series: {},
        xAxis: '',
        xAxisType: '',
      },
      tooltipPre: '',
      tooltipSuff: '',
      goal: '',
      goalTable: {
        categoriesGroup: '',
        categories: '',
        values: '',
        timeAxis: '',
        goal: '',
        horizontalHeader: '',
        comparisonOperator: '',
      },
    },
    config: {
      limit: 0,
    },
    name: '',
    options: {
      cardHeight: '',
      cardWidth: '',
      question: {
        id: -1,
        description: '',
        author: {
          firstName: '',
          lastName: '',
        },
      },
      showAsText: false,
      collections: [],
    },
  },
};

const CardConfigInitialState = {
  cardId: null,
  cardTitle: '',
  chartType: '',
  cardDimensions: {
    cardHeight: 2,
    cardWidth: 6,
  },
  cardOwner: null,
  cardEditor: null,
  collections: [],
  selectedChartConfig: null,
  dynamicFilters: {},
  axisData: null,
  chartOptions: null,
  isEditMode: false,
  cardVersion: CardConfigVersions.current,
};

export const CardBuilderInitialState: ICardBuilderState = {
  cardData: {
    rawData: [],
    loading: false,
    hasErrors: false,
  },
  cardsList: {
    list: [],
    loading: false,
    hasErrors: false,
  },
  cardModal: {
    step: CARD_STEPS.initial,
    isConfigModalOpen: false,
    isEditMode: false,
    V1: {
      selectedConfig: V1SelectedConfig,
    },
  },
  cardConfig: CardConfigInitialState,
};
