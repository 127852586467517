import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IErrorModalState {
  errorMessage: string;
}

const errorModalInitialState = {
  errorMessage: '',
};

export const updateErrorModalReduxSlice = {
  resetErrorModalMessage: (state: IErrorModalState) => {
    state.errorMessage = '';
  },

  updateErrorMessage: (
    state: IErrorModalState,
    action: PayloadAction<{
      error: { message: string };
    }>
  ) => {
    state.errorMessage = action.payload?.error?.message || 'Something went wrong';
  },
};

const ErrorModalReduxSlice = createSlice({
  name: 'errorModal',
  initialState: errorModalInitialState,
  reducers: updateErrorModalReduxSlice,
});

export const { resetErrorModalMessage, updateErrorMessage } = ErrorModalReduxSlice.actions;

export default ErrorModalReduxSlice.reducer;
