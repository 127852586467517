import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './assets/colors.css';
import './assets/designSystem/3.0/colors/colors.css';
import './assets/designSystem/3.0/colors/accent.css';
import './assets/designSystem/3.0/colors/brand.css';
import './assets/designSystem/3.0/colors/shade.css';
import './assets/designSystem/3.0/colors/tints-default.css';
import './assets/designSystem/3.0/colors/tints-highlight.css';
import './assets/designSystem/3.0/colors/tints-shade.css';
import './assets/designSystem/3.0/colors/transparencies.css';
import './assets/designSystem/3.0/text/text.css';
import './assets/fonts.css';
import './assets/forms.css';
import './assets/headings.css';
import './assets/paragraph.css';
import './assets/nav.css';
import './assets/modal.css';
import './assets/transition.css';
import './assets/cursor.css';
import './assets/sizing.css';
import './assets/overflow.css';
import './assets/grid.css';
import './assets/paddingAndMargins.css';
import './assets/buttons.css';
import './assets/responsiveBreakpoints.css';
import './assets/form-sizing.css';
import './index.css';

import App from './App';
import { unregister } from './registerServiceWorker';
import { store } from './redux/store';
import { initEchartsThemes } from './utils/Viz/Themes/initEchartsThemes';
import ThemeProvider from './components/Theme/ThemeProvider';
import AuthTypeProvider from './Providers/AuthTypeProvider/AuthTypeProvider';

// register echarts themes
initEchartsThemes();

//calling unregister to avoid caching index.html for nginx
unregister();

const element = (
  <Provider store={store}>
    <ThemeProvider>
      <AuthTypeProvider>
        <App />
      </AuthTypeProvider>
    </ThemeProvider>
  </Provider>
);

createRoot(document.getElementById('root')).render(element);
