// fetch props output
export const SET_PROPS = '[Studio: query mapping properties] set properties list';
export const setProps = (queryDef, mappingDef, mappingId) => ({
  type: SET_PROPS,
  payload: { queryDef, mappingDef, mappingId },
});
export const resetProps = () => ({ type: SET_PROPS, payload: {} });

export const SET_PROPS_IS_LOADING = '[Studio: query mapping properties] set fetch props is loading';
export const setPropsIsLoading = (status) => ({ type: SET_PROPS_IS_LOADING, payload: status });

export const SET_PROPS_HAS_LOADING_ERROR =
  '[Studio: query mapping properties] set fetch props has error';
export const setPropsHasLoadingError = (status) => ({
  type: SET_PROPS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_PROPS = '[Studio: query mapping properties] fetch props';
export const FETCH_PROPS_SUCCESS = '[Studio: query mapping properties] fetch props success';
export const FETCH_PROPS_ERROR = '[Studio: query mapping properties] fetch props has error';
export const fetchProps = (queryId, mappingId) => ({
  type: FETCH_PROPS,
  payload: { queryId, mappingId },
});

// create/edit query mapping
export const SET_SELECTED_PROP = '[Studio: query mapping properties] set selected property';
export const setSelectedProp = (
  selectedConcept,
  selectedProp,
  selectedMapping,
  solutionId,
  queryId,
  queryDescription
) => ({
  type: SET_SELECTED_PROP,
  payload: {
    selectedConcept,
    selectedProp,
    selectedMapping,
    solutionId,
    queryId,
    queryDescription,
  },
});

export const CLEAR_MAPPING_FOR_PROP = '[Studio: query mapping properties] Clear mapping for prop';
export const clearMappingForProp = () => ({ type: CLEAR_MAPPING_FOR_PROP });

// create mapping definition
export const SET_MAPPING_DEF_LOCAL = '[Studio: create mapping def] set mapping def locally';
export const setMappingDefLocal = (def) => ({ type: SET_MAPPING_DEF_LOCAL, payload: def });

export const SET_MAPPING_DEF = '[Studio: create mapping def] set mapping def';
export const setMappingDef = (mappingDef, dataSets, datasetId) => ({
  type: SET_MAPPING_DEF,
  payload: { mappingDef, dataSets, datasetId },
});

export const SET_MAPPING_DEF_IS_SAVING = '[Studio: create mapping def] set mapping def is saving';
export const setMappingDefIsSaving = (status) => ({
  type: SET_MAPPING_DEF_IS_SAVING,
  payload: status,
});

export const SET_MAPPING_DEF_HAS_SAVING_ERROR =
  '[Studio: create mapping def] set mapping def has saving error';
export const setMappingDefHasSavingError = (status) => ({
  type: SET_MAPPING_DEF_HAS_SAVING_ERROR,
  payload: status,
});

export const UPDATE_MAPPING_DEF = '[Studio: create mapping def] create/update mapping def';
export const UPDATE_MAPPING_DEF_SUCCESS =
  '[Studio: create mapping def] create/update mapping def success';
export const UPDATE_MAPPING_DEF_ERROR =
  '[Studio: create mapping def] create/update mapping def error';
export const updateMappingDef = (solutionId, queryId, data, callback) => ({
  type: UPDATE_MAPPING_DEF,
  payload: { solutionId, queryId, data, callback },
});

// fetch mapping definition by mapping def id
export const SET_MAPPING_DEF_IS_LOADING =
  '[Studio: fetch mapping def] set fetch mapping def is loading';
export const setMappingDefIsLoading = (status) => ({
  type: SET_MAPPING_DEF_IS_LOADING,
  payload: status,
});

export const SET_MAPPING_DEF_HAS_LOADING_ERROR =
  '[Studio: fetch mapping def] set fetch mapping def has loading error';
export const setMappingDefHasLoadingError = (status, errorCode) => ({
  type: SET_MAPPING_DEF_HAS_LOADING_ERROR,
  payload: { status, errorCode },
});

export const FETCH_MAPPING_DEF = '[Studio: fetch mapping def] fetch mapping def';
export const FETCH_MAPPING_DEF_SUCCESS = '[Studio: fetch mapping def] fetch mapping def success';
export const FETCH_MAPPING_DEF_ERROR = '[Studio: fetch mapping def] fetch mapping def error';
export const fetchMappingDefAndDataSets = (solutionId, queryId, mappingId, dataSetId) => ({
  type: FETCH_MAPPING_DEF,
  payload: { solutionId, queryId, mappingId, dataSetId },
});

// fetch ConceptIdOverlaps
export const SET_CONCEPT_ID_OVERLAPS = '[Studio: concept id overlaps] set concept id overlaps list';
export const setConceptIdOverlaps = (overlapsList) => ({
  type: SET_CONCEPT_ID_OVERLAPS,
  payload: overlapsList,
});

export const SET_CONCEPT_ID_OVERLAPS_IS_LOADING =
  '[Studio: concept id overlaps] set concept id overlaps list is loading';
export const setConceptIdOverlapsIsLoading = (status) => ({
  type: SET_CONCEPT_ID_OVERLAPS_IS_LOADING,
  payload: status,
});

export const SET_CONCEPT_ID_OVERLAPS_HAS_LOADING_ERROR =
  '[Studio: concept id overlaps] set concept id overlaps list has loading error';
export const setConceptIdOverlapsHasLoadingError = (status) => ({
  type: SET_CONCEPT_ID_OVERLAPS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_CONCEPT_ID_OVERLAPS =
  '[Studio: concept id overlaps] fetch concept id overlaps list';
export const FETCH_CONCEPT_ID_OVERLAPS_SUCCESS =
  '[Studio: concept id overlaps] fetch concept id overlaps list success';
export const FETCH_CONCEPT_ID_OVERLAPS_ERROR =
  '[Studio: concept id overlaps] fetch concept id overlaps list error';
export const fetchConceptIdOverlaps = (solutionId, uri, dataSetId) => ({
  type: FETCH_CONCEPT_ID_OVERLAPS,
  payload: { solutionId, uri, dataSetId },
});

// fetch ConceptLookups array
export const SET_CONCEPT_LOOKUPS = '[Studio: concept id lookups] set concept lookups list';
export const setConceptLookups = (conceptLookups) => ({
  type: SET_CONCEPT_LOOKUPS,
  payload: conceptLookups,
});

export const SET_CONCEPT_LOOKUPS_IS_LOADING =
  '[Studio: concept id lookups] set concept lookups list is loading';
export const setConceptLookupsIsLoading = (status) => ({
  type: SET_CONCEPT_LOOKUPS_IS_LOADING,
  payload: status,
});

export const SET_CONCEPT_LOOKUPS_HAS_LOADING_ERROR =
  '[Studio: concept id lookups] set concept lookups list has loading error';
export const setConceptLookupsHasLoadingError = (status) => ({
  type: SET_CONCEPT_LOOKUPS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_CONCEPT_LOOKUPS = '[Studio: concept id lookups] fetch concept lookups list';
export const FETCH_CONCEPT_LOOKUPS_SUCCESS =
  '[Studio: concept id lookups] fetch concept lookups list success';
export const FETCH_CONCEPT_LOOKUPS_ERROR =
  '[Studio: concept id lookups] fetch concept lookups list error';
export const fetchConceptLookups = (solutionId, uri, dataSetId) => ({
  type: FETCH_CONCEPT_LOOKUPS,
  payload: { solutionId, uri, dataSetId },
});

// overlaps for concept_id tab
export const UPDATE_OVERLAP_CHECKED_FOR_CONCEPT_ID = 'UPDATE_OVERLAP_CHECKED_FOR_CONCEPT_ID';
export const updateOverlapCheckedForConceptId = (update) => ({
  type: UPDATE_OVERLAP_CHECKED_FOR_CONCEPT_ID,
  payload: update,
});

export const UPDATE_OVERLAP_SELECTED_DATASET_FOR_CONCEPT_ID =
  'UPDATE_OVERLAP_SELECTED_DATASET_FOR_CONCEPT_ID';
export const updateOverlapSelectedDatasetForConceptId = (update) => ({
  type: UPDATE_OVERLAP_SELECTED_DATASET_FOR_CONCEPT_ID,
  payload: update,
});
// -------------

// overlaps for relations in general tab
export const UPDATE_OVERLAP_CHECKED_FOR_RELATIONS = 'UPDATE_OVERLAP_CHECKED_FOR_RELATIONS';
export const updateOverlapCheckedForRelations = (update) => ({
  type: UPDATE_OVERLAP_CHECKED_FOR_RELATIONS,
  payload: update,
});

export const UPDATE_OVERLAP_SELECTED_DATASET_FOR_RELATIONS =
  'UPDATE_OVERLAP_SELECTED_DATASET_FOR_RELATIONS';
export const updateOverlapSelectedDatasetForRelations = (update) => ({
  type: UPDATE_OVERLAP_SELECTED_DATASET_FOR_RELATIONS,
  payload: update,
});

// fetch property mapping
export const FETCH_PROP_MAPPING = '[Studio: query mapping properties] fetch prop mapping';
export const FETCH_PROP_MAPPING_SUCCESS =
  '[Studio: query mapping properties] fetch prop mapping success';
export const FETCH_PROP_MAPPING_ERROR =
  '[Studio: query mapping properties] fetch prop mapping has error';
export const fetchPropMapping = (queryId, solutionId) => ({
  type: FETCH_PROP_MAPPING,
  payload: { queryId, solutionId },
});

export const SET_PROP_MAPPING = '[Studio: query mapping properties] set property mapping';
export const setPropMapping = (propMapping) => ({ type: SET_PROP_MAPPING, payload: propMapping });

export const SET_PROP_MAPPING_IS_LOADING =
  '[Studio: query mapping properties] set fetch prop mapping is loading';
export const setPropMappingIsLoading = (status) => ({
  type: SET_PROP_MAPPING_IS_LOADING,
  payload: status,
});

export const SET_PROP_MAPPING_HAS_LOADING_ERROR =
  '[Studio: query mapping properties] set fetch prop mapping has error';
export const setPropMappingHasLoadingError = (status) => ({
  type: SET_PROP_MAPPING_HAS_LOADING_ERROR,
  payload: status,
});

// fetch datasets
export const SET_DATASETS = '[Studio: query mapping properties] set dataset list';
export const setDatasets = (datasets) => ({ type: SET_DATASETS, payload: datasets });

export const SET_DATASETS_IS_LOADING =
  '[Studio: query mapping properties] set fetch datasets is loading';
export const setIsLoading = (status) => ({ type: SET_DATASETS_IS_LOADING, payload: status });

export const SET_DATASETS_HAS_LOADING_ERROR =
  '[Studio: query mapping properties] set fetch datasets has error';
export const setHasLoadingError = (status, error) => ({
  type: SET_DATASETS_HAS_LOADING_ERROR,
  payload: { status, error },
});

export const FETCH_DATASETS = '[Studio: query mapping properties] fetch datasets';
export const FETCH_DATASETS_SUCCESS = '[Studio: query mapping properties] fetch datasets success';
export const FETCH_DATASETS_ERROR = '[Studio: query mapping properties] fetch datasets has error';
export const fetchDatasets = (solutionId, queryId) => ({
  type: FETCH_DATASETS,
  payload: { solutionId, queryId },
});

// fetch datasets columns
export const SET_DATASET_COLUMNS = '[Studio: query mapping properties] set dataset columns';
export const setDatasetColumns = (columns, dataSetId) => ({
  type: SET_DATASET_COLUMNS,
  payload: { columns, dataSetId },
});

export const RESET_DATASET_COLUMNS = '[Studio: query mapping properties] reset dataset columns';
export const resetDatasetColumns = () => ({ type: RESET_DATASET_COLUMNS });

export const SET_DATASET_COLUMNS_IS_LOADING =
  '[Studio: query mapping properties] set fetch datasets columns is loading';
export const setDatasetColumnsIsLoading = (status) => ({
  type: SET_DATASET_COLUMNS_IS_LOADING,
  payload: status,
});

export const SET_DATASET_COLUMNS_HAS_LOADING_ERROR =
  '[Studio: query mapping properties] set fetch datasets columns has error';
export const setDatasetColumnsHasLoadingError = (status) => ({
  type: SET_DATASET_COLUMNS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_DATASET_COLUMNS = '[Studio: query mapping properties] fetch datasets columns';
export const FETCH_DATASET_COLUMNS_SUCCESS =
  '[Studio: query mapping properties] fetch datasets columns success';
export const FETCH_DATASET_COLUMNS_ERROR =
  '[Studio: query mapping properties] fetch datasets columns has error';
export const fetchDatasetColumns = (dataSetId) => ({ type: FETCH_DATASET_COLUMNS, dataSetId });

// fetch timeseries datasets columns
export const SET_TIMESERIES_DATASET_COLUMNS =
  '[Studio: query mapping properties] set timeseries dataset columns';
export const setTimeseriesDatasetColumns = (columns, dataSetId) => ({
  type: SET_TIMESERIES_DATASET_COLUMNS,
  payload: { columns, dataSetId },
});

export const RESET_TIMESERIES_DATASET_COLUMNS =
  '[Studio: query mapping properties] reset timeseries dataset columns';
export const resetTimeseriesDatasetColumns = () => ({ type: RESET_TIMESERIES_DATASET_COLUMNS });

export const SET_TIMESERIES_DATASET_COLUMNS_IS_LOADING =
  '[Studio: query mapping properties] set fetch timeseries datasets columns is loading';
export const setTimeseriesDatasetColumnsIsLoading = (status) => ({
  type: SET_TIMESERIES_DATASET_COLUMNS_IS_LOADING,
  payload: status,
});

export const SET_TIMESERIES_DATASET_COLUMNS_HAS_LOADING_ERROR =
  '[Studio: query mapping properties] set fetch timeseries datasets columns has error';
export const setTimeseriesDatasetColumnsHasLoadingError = (status) => ({
  type: SET_TIMESERIES_DATASET_COLUMNS_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_TIMESERIES_DATASET_COLUMNS =
  '[Studio: query mapping properties] fetch timeseries datasets columns';
export const FETCH_TIMESERIES_DATASET_COLUMNS_SUCCESS =
  '[Studio: query mapping properties] fetch timeseries datasets columns success';
export const FETCH_TIMESERIES_DATASET_COLUMNS_ERROR =
  '[Studio: query mapping properties] fetch timeseries datasets columns has error';
export const fetchTimeseriesDatasetColumns = (dataSetId) => ({
  type: FETCH_TIMESERIES_DATASET_COLUMNS,
  dataSetId,
});
