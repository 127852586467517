import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { relationLookupsInitialState } from './constants/relationLookups.initialState';
import { IRelationLookupsState } from './constants/relationLookups.interface';

const updateRelationLookupsReduxSlice = {
  resetState: (state: IRelationLookupsState) => {
    state.datasetColumns = [];
    state.loading = false;
    state.hasErrors = false;
  },

  initDataSetColumnsFetch: (state: IRelationLookupsState) => {
    state.datasetColumns = [];
    state.loading = true;
    state.hasErrors = false;
  },

  dataSetColumnsFetchError: (state: IRelationLookupsState) => {
    state.loading = false;
    state.hasErrors = true;
  },

  setDataSetColumns: (state: IRelationLookupsState, action: PayloadAction<string[]>) => {
    state.datasetColumns = action.payload.map((item) => ({ label: item, value: item }));
    state.loading = false;
  },
};

const relationLookupsSlice = createSlice({
  name: 'relationLookups',
  initialState: relationLookupsInitialState,
  reducers: { ...updateRelationLookupsReduxSlice },
});

export const { resetState, initDataSetColumnsFetch, dataSetColumnsFetchError, setDataSetColumns } =
  relationLookupsSlice.actions;

export default relationLookupsSlice.reducer;
