export const SET_API_DETAILS = '[Api Details] Set Api Details';
export const setApiDetails = (tenants) => ({ type: SET_API_DETAILS, payload: tenants });

export const SET_IS_LOADING = '[Api Details] Set Is Loading';
export const setIsLoading = (status) => ({ type: SET_IS_LOADING, payload: status });

export const SET_HAS_LOADING_ERROR = '[Api Details] Set Has Loading Error';
export const setHasLoadingError = (status) => ({ type: SET_HAS_LOADING_ERROR, payload: status });

export const FETCH_API_DETAILS = '[ApiDetails] Fetch Api Details';
export const FETCH_API_DETAILS_SUCCESS = '[ApiDetails] Fetch Api Details Success';
export const FETCH_API_DETAILS_ERROR = '[ApiDetails] Fetch Api Details Error';
export const fetchApiDetails = () => ({ type: FETCH_API_DETAILS });
