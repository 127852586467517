export const GRAPH_NODE_EDGE_DEFAULTS = {
  label: {
    show: true,
    color: 'rgb(255, 255, 255, 0)',
  },
  lineStyle: {
    curveness: 0.2,
    width: 1,
  },
  emphasis: {
    label: {
      color: '#979797',
      show: true,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 300,
    },
    lineStyle: {
      width: 1,
    },
  },
};
