import React from 'react';
import Roles from './config/roles';
import Policies from './config/policies';
import ResourceStatements from './config/resourceStatements/index';
import { store } from '../redux/store';
import flattenDeep from 'lodash/flattenDeep';
export const ShowIfUserHasPermission = (props) => {
  const { permissionRef, any = false, children, overridePermissions = false } = props;
  try {
    //Pass in permissions as ['resource', 'subject', 'action'] i.e. ['models', 'concepts', 'c'];
    //For multiple permissions pass in arrays [['resource', 'subject', 'action'],['resource', 'subject', 'action']]
    if (overridePermissions) {
      return '';
    }
    return hasPermission(permissionRef, any) ? children : '';
  } catch (err) {
    console.warn(
      'ShowIfUserHasPermission() failed using:',
      props,
      '\n',
      'Pass in permissionRef = ["resource", "subject", "action"] for a single permission \n',
      'or pass permissionRef = [["resource", "subject", "action"]["resource", "subject", "action"]] for multiple permissions.'
    );
    return '';
  }
};

export const ShowIfUserDoesntHavePermission = (props) => {
  const { permissionRef, any = false, children, overridePermissions = false } = props;
  try {
    //Pass in permissions as ['resource', 'subject', 'action'] i.e. ['models', 'concepts', 'c'];
    //For multiple permissions pass in arrays [['resource', 'subject', 'action'],['resource', 'subject', 'action']]
    if (overridePermissions) {
      return children;
    }
    return hasPermission(permissionRef, any) ? '' : children;
  } catch (err) {
    console.warn(
      'ShowIfUserDoesntHavePermission() failed using:',
      props,
      '\n',
      'Pass in permissionRef = ["resource", "subject", "action"] for a single permission \n',
      'or pass permissionRef = [["resource", "subject", "action"]["resource", "subject", "action"]] for multiple permissions.'
    );
    return '';
  }
};

const buildPermissionList = ([...userRoles]) => {
  const assignedPolicies = getAssignedPolicies(userRoles);
  return getPermissionList(assignedPolicies);
};

const getAssignedPolicies = (userRoles) => {
  let assignedPolicies = [];

  if (userRoles.length > 0) {
    userRoles.forEach((role) => assignedPolicies.push(Roles.assignedPolicies(role)));
  } else {
    //If no roles are returned it will default as guest
    assignedPolicies.push(Roles.assignedPolicies(userRoles));
  }
  //flatten array and remove duplicates
  assignedPolicies = new Set(flattenDeep(assignedPolicies));
  return [...assignedPolicies];
};

const getPermissionList = (assignedPolicies) => {
  let permissionList = ResourceStatements;

  //For each policy => get each of the resources => and change the permissions in the permission list
  assignedPolicies.forEach((policies) =>
    Object.keys(Policies.statements(policies)).forEach((resource) => {
      const permissions = Policies.statements(policies)[resource];
      if (permissions === 'all') {
        permissionList = grantAllPermissions(permissionList, resource);
      } else if (permissions === 'readAll') {
        permissionList = grantReadAllPermissions(permissionList, resource);
      } else {
        permissionList = grantCustomPermissions(permissionList, resource, permissions);
      }
    })
  );

  return permissionList;
};

const grantAllPermissions = (permissionList, resource) => {
  return Object.keys(permissionList[resource]).reduce((acc, key) => {
    const actions = Object.keys(permissionList[resource][key]).reduce(
      (actionAcc, actionKey) => ({ ...actionAcc, [actionKey]: true }),
      {}
    );
    return {
      ...acc,
      [resource]: {
        ...acc[resource],
        [key]: actions,
      },
    };
  }, permissionList);
};

const grantReadAllPermissions = (permissionList, resource) => {
  return Object.keys(permissionList[resource]).reduce((acc, key) => {
    return {
      ...acc,
      [resource]: {
        ...acc[resource],
        [key]: {
          ...acc[resource][key],
          r: true,
        },
      },
    };
  }, permissionList);
};

const grantCustomPermissions = (permissionList, resource, permissions = []) => {
  return Object.keys(permissionList[resource]).reduce((acc, key) => {
    const customPermissions = permissions.reduce(
      (permAcc, permissionKey) => ({ ...permAcc, [permissionKey]: true }),
      {}
    );
    const actions = Object.assign(permissionList[resource][key], customPermissions);
    return {
      ...acc,
      [resource]: {
        ...acc[resource],
        [key]: actions,
      },
    };
  }, permissionList);
};

export const hasPermission = (permissionRef = [], any = false) => {
  const permissions = store.getState().roles.permissions;
  if (!Array.isArray(permissionRef[0])) {
    return standardPermissionCheck(
      permissionRef[0],
      permissionRef[1],
      permissionRef[2],
      permissions,
      any
    );
  } else {
    return multiPermissionCheck(permissionRef, permissions, any);
  }
};

const standardPermissionCheck = (resource, subject, action, permissions, any = false) => {
  let noPermissionsRequired = !resource && !action && !subject;
  let userHasPermission;

  //standard [resource, subject, action] format
  if (!Array.isArray(action)) {
    userHasPermission =
      permissions[resource] &&
      permissions[resource][subject] &&
      permissions[resource][subject][action];
  }

  //multi action [resource, subject, [action, action]] format
  else {
    userHasPermission = !any;
    action.forEach((crud) => {
      if (
        !(
          permissions[resource] &&
          permissions[resource][subject] &&
          permissions[resource][subject][crud]
        ) &&
        !any
      ) {
        userHasPermission = false;
      } else if (
        permissions[resource] &&
        permissions[resource][subject] &&
        permissions[resource][subject][crud] &&
        any
      ) {
        userHasPermission = true;
      }
    });
  }

  return noPermissionsRequired || userHasPermission;
};

const multiPermissionCheck = (permissionRef, permissions, any = false) => {
  let userHasPermission = !any;

  //multi permission format [[resource, subject, action], [resource, subject, action]] format
  permissionRef.forEach((permissionSet) => {
    if (
      !standardPermissionCheck(
        permissionSet[0],
        permissionSet[1],
        permissionSet[2],
        permissions,
        any
      ) &&
      !any
    ) {
      userHasPermission = false;
    } else if (
      standardPermissionCheck(
        permissionSet[0],
        permissionSet[1],
        permissionSet[2],
        permissions,
        any
      ) &&
      any
    ) {
      userHasPermission = true;
    }
  });
  return userHasPermission;
};

const Rbac = {
  buildPermissionList,
  hasPermission,
};

export default Rbac;
