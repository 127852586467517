import React, { FC, ReactElement } from 'react';
import { WarningRounded } from '@mui/icons-material';
import { ErrorBoundaryProps } from './constants/errorBoundary.interface';

interface Props extends ErrorBoundaryProps {
  children: ReactElement;
}

const ErrorMessage: FC<Props> = ({
  testId,
  containerProps,
  errorMessageProps,
  children,
}): ReactElement => {
  return (
    <div
      data-testid={testId || 'error-message'}
      className={
        'flex flex-column items-center ' +
        (containerProps?.className ? containerProps.className : ' mt4 f4')
      }
    >
      <WarningRounded style={{ color: '#CC3300', fontSize: 64 }} />
      <div
        className="mt3 fw3"
        style={{
          lineHeight: '31px',
          width: errorMessageProps?.style?.width ? errorMessageProps.style?.width : undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ErrorMessage;
