// create new dataset
export const SET_NEW_DATASET = '[Studio: datasets] set new dataset';
export const setNewDataset = (datasets) => ({ type: SET_NEW_DATASET, payload: datasets });
export const SET_NEW_DATASET_ID = '[Studio: datasets] set new dataset id';
export const setNewDatasetId = (dataSetId) => ({ type: SET_NEW_DATASET_ID, payload: dataSetId });

export const SET_NEW_DATASET_IS_SAVING = '[Studio: datasets] set new dataset is saving';
export const setNewDatasetIsSaving = (status) => ({
  type: SET_NEW_DATASET_IS_SAVING,
  payload: status,
});

export const SET_NEW_DATASET_HAS_SAVING_ERROR =
  '[Studio: datasets] set new dataset has saving error';
export const setNewDatasetHasSavingError = (status) => ({
  type: SET_NEW_DATASET_HAS_SAVING_ERROR,
  payload: status,
});

export const CREATE_NEW_DATASET = '[Studio: datasets] create new dataset';
export const CREATE_NEW_DATASET_SUCCESS = '[Studio: datasets] create new dataset success';
export const CREATE_NEW_DATASET_ERROR = '[Studio: datasets] create new dataset has error';
export const createNewDataset = (data) => ({ type: CREATE_NEW_DATASET, data });
