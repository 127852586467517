import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { groupBy, map } from 'underscore';

import {
  IActiveScenario,
  ITower360ScenarioListRaw,
  ITower360ScenariosInitialState,
} from '../../constants/tower360.interfaces';
import { Tower360ScenariosState } from '../../constants/tower360.constants';
import { getConceptPropName } from '../../../../utils/Viz/getConceptPropName';

// scenarios list
const updateTowerScenariosReduxSlice = {
  setIsLoadingScenarios: (state: ITower360ScenariosInitialState) => {
    state.loading = true;
    state.hasErrors = false;
    state.list = [];
  },
  setHasErrorLoadingScenarios: (state: ITower360ScenariosInitialState) => {
    state.hasErrors = true;
    state.loading = false;
  },
  setScenariosList: (
    state: ITower360ScenariosInitialState,
    action: PayloadAction<ITower360ScenarioListRaw[]>
  ) => {
    state.loading = false;

    // reduce the list to get all of the Concept_Props, vizids & calcnames, per scenario id
    state.list = map(
      groupBy(action.payload, (item) => item?.id || 0),
      (scenariosById) => {
        return scenariosById.reduce(
          (acc: IActiveScenario, scenario) => {
            return {
              ...acc,
              id: scenario.id,
              name: scenario.name,

              // accumulate scenario: calcnames, vizids & props
              calcnames:
                scenario.calcnames !== null ? `${acc.calcnames}${scenario.calcnames},` : '',
              vizids: `${acc.vizids}${scenario.vizids},`,
              props: {
                ...acc.props,
                [getConceptPropName(scenario.concept, scenario.prop)]: true,
              },
            };
          },
          {
            id: 0,
            name: '',
            calcnames: '',
            vizids: '',
            props: {},
          }
        );
      }
    );
  },
};

const tower360ScenariosSlice = createSlice({
  name: 'tower360Scenarios',
  initialState: Tower360ScenariosState,
  reducers: {
    ...updateTowerScenariosReduxSlice,
  },
});

export const { setIsLoadingScenarios, setHasErrorLoadingScenarios, setScenariosList } =
  tower360ScenariosSlice.actions;

export default tower360ScenariosSlice.reducer;
