import { size } from 'underscore';

export const envData = window._env_;
const Features =
  envData && size(envData) > 0
    ? Object.keys(envData).reduce((acc, feature) => {
        if (feature.includes('REACT_APP_')) {
          // skip configuration variables
          return acc;
        }

        let value = envData[feature];
        const featureName = feature.toLowerCase().replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        switch (value) {
          case 'true':
            value = true;
            break;

          case 'false':
            value = false;
            break;

          default:
            break;
        }

        return {
          ...acc,
          [featureName]: value,
        };
      }, {})
    : {};

export default Features;
