export const RELATION_PROPS = {
  isRelation: false,
  selfJoin: false,
  relationInstances: [],
  relationTypeLabel: '',
  relationTypeUri: '',
};

export const PROPERTY_PROPS = {
  windowFunctions: [],
  selfJoin: false,
  aggregates: [],
  filters: [],
  hidden: false,
  optional: false,
  outputCategory: '',
  propTypeLabel: '',
  propTypeUri: '',
  round: null,
  truncateTo: null,
};
