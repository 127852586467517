export enum OptGroupElementTypes {
  AGGR = 'AGGR',
  CALC = 'CALC',
  PROP = 'PROP',
}

export interface IWindowFunctionPartition {
  concept: string | null;
  property: string | null;
  elementType: OptGroupElementTypes | string;
  label: string;
  aggrType: string | null;
  calcId: number | null;
}

export interface IWindowFunctionSorting extends IWindowFunctionPartition {
  order: string;
}

export interface IWindowFunctionItem {
  type: string;
  columnLabel: string;
  partition?: IWindowFunctionPartition[];
  sorting?: IWindowFunctionSorting[];
}

export enum WindowFunctionModalViews {
  LIST = 'list',
  EMPTY = 'empty',
  EDIT = 'edit',
}

export enum WindowFunctionTypes {
  LAG = 'lag',
  LEAD = 'lead',
  CSUM = 'csum',
  DENSERANK = 'dense_rank',
  GLOBALCSUM = 'global_csum',
  PERCENTRANK = 'percent_rank',
  SHOWPROPERTY = 'showProperty',
}

const { SHOWPROPERTY, CSUM, GLOBALCSUM, DENSERANK, PERCENTRANK, LEAD, LAG } = WindowFunctionTypes;

const lag = { value: LAG, label: 'Lag' };
const lead = { value: LEAD, label: 'Lead' };
const denseRank = { value: DENSERANK, label: 'Dense Rank' };
const cumulativeSum = { value: CSUM, label: 'Cumulative Sum' };
const percentRank = { value: PERCENTRANK, label: 'Percent Rank' };
const showProperty = { value: SHOWPROPERTY, label: 'Show Property' };
const globalCumulativeSum = {
  value: GLOBALCSUM,
  label: 'Cumulative Sum Global',
};

export const WindowFunctionsWithLabels = {
  lag,
  lead,
  cumulativeSum,
  denseRank,
  percentRank,
  showProperty,
  globalCumulativeSum,
};
