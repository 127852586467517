import { FC, ReactElement } from 'react';

const Refresh: FC = (): ReactElement => (
  <span data-testid="refresh-error-message">
    Sorry, there was problem loading this page. Please
    <span className="link-blue pointer" onClick={() => window.location.reload()}>
      {' click here '}
    </span>
    to refresh your page. If this is still happening, please contact support at:
    <a className="link-blue no-underline" href="mailto:support@kobai.io">
      support@kobai.io
    </a>
  </span>
);

export default Refresh;
