import { envData } from '@Features/feature-flags';
import { size } from 'underscore';

const envVariables: Record<string, any> =
  envData && size(envData) > 0
    ? Object.keys(envData).reduce((acc, variable) => {
        // skip FEATURE FLAG variables
        if (!variable.includes('REACT_APP_')) {
          return acc;
        }
        let value = envData[variable];
        variable = variable.replace('REACT_APP_', '');
        const varName = variable.toLowerCase().replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        return {
          ...acc,
          [varName]: value,
        };
      }, {})
    : {};

export default envVariables;
