const getMatchingOverlap = (dataSetMap, conceptIdOverlaps, selectedMapping) => {
  return conceptIdOverlaps.filter((id) => {
    const overlapId = `${id.dataSourceId}.${id.tableName}`;
    const datasetID = `${dataSetMap[selectedMapping.dataSetId].dataSourceId}.${
      dataSetMap[selectedMapping.dataSetId].tableName
    }`;

    return overlapId === datasetID;
  });
};

export default getMatchingOverlap;
