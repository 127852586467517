// fetch queries list
export const SET_API_LIST = '[ApiList] Set ApiList';
export const setApiList = (apiList) => ({ type: SET_API_LIST, payload: apiList });

export const SET_API_LIST_IS_LOADING = '[ApiList] Set Is Loading';
export const setApiListIsLoading = (status) => ({ type: SET_API_LIST_IS_LOADING, payload: status });

export const SET_API_LIST_HAS_LOADING_ERROR = '[ApiList] Set Has Loading Error';
export const setApiListHasLoadingError = (status, errorCode) => ({
  type: SET_API_LIST_HAS_LOADING_ERROR,
  payload: { status, errorCode },
});

export const FETCH_API_LIST = '[ApiList] Fetch ApiList';
export const FETCH_API_LIST_SUCCESS = '[ApiList] Fetch ApiList Success';
export const FETCH_API_LIST_ERROR = '[ApiList] Fetch ApiList Error';
export const fetchApiList = () => ({ type: FETCH_API_LIST });

// fetch query api id
export const SET_QUERY_API_ID = '[ApiList] Set query api id';
export const setQueryApiId = (id) => ({ type: SET_QUERY_API_ID, payload: id });

export const SET_QUERY_API_ID_IS_LOADING = '[ApiList] Set query api id Loading';
export const setQueryApiIdIsLoading = (status) => ({
  type: SET_QUERY_API_ID_IS_LOADING,
  payload: status,
});

export const SET_QUERY_API_ID_HAS_LOADING_ERROR = '[ApiList] Set query api id has Loading Error';
export const setQueryApiIdHasLoadingError = (status) => ({
  type: SET_QUERY_API_ID_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_QUERY_API_ID = '[ApiList] Fetch query api id';
export const FETCH_QUERY_API_ID_SUCCESS = '[ApiList] Fetch query api id Success';
export const FETCH_QUERY_API_ID_ERROR = '[ApiList] Fetch query api id Error';
export const fetchQueryApiId = (queryId) => ({ type: FETCH_QUERY_API_ID, payload: queryId });

// API details
export const FETCH_API_DETAILS_SUCCESS = '[PublishApi] fetch api details success';
export const FETCH_API_DETAILS_ERROR = '[PublishApi] fetch api details error';
export const FETCH_API_DETAILS = '[PublishApi] fetch api details';
export const fetchApiDetails = () => ({ type: FETCH_API_DETAILS });

export const SET_API_DETAILS = '[PublishApi] set api details';
export const setApiDetails = (details) => ({ type: SET_API_DETAILS, payload: details });

export const SET_API_DETAILS_LOADING = '[PublishApi] set api details loading';
export const setApiDetailsIsLoading = (status) => ({
  type: SET_API_DETAILS_LOADING,
  payload: status,
});

export const SET_API_DETAILS_LOADING_ERROR = '[PublishApi] set api details has loading error';
export const setApiDetailsHasLoadingError = (status) => ({
  type: SET_API_DETAILS_LOADING_ERROR,
  payload: status,
});

// publish api
export const PUBLISH_API_SUCCESS = '[PublishApi] publish api success';
export const PUBLISH_API_ERROR = '[PublishApi] publish api error';
export const PUBLISH_API = '[PublishApi] publish api';
export const publishApi = (apiId, publish, data) => ({ type: PUBLISH_API, apiId, publish, data });

export const SET_PUBLISHING_API = '[PublishApi] set publishing api';
export const setPublishingApi = (status) => ({ type: SET_PUBLISHING_API, payload: status });

export const SET_PUBLISH_API_SUCCESS = '[PublishApi] set publishing api success';
export const setPublishApiSuccess = (status) => ({
  type: SET_PUBLISH_API_SUCCESS,
  payload: status,
});

export const SET_UNPUBLISH_API_SUCCESS = '[PublishApi] set unpublishing api success';
export const setUnPublishApiSuccess = (status) => ({
  type: SET_UNPUBLISH_API_SUCCESS,
  payload: status,
});

export const SET_PUBLISH_API_ERROR = '[PublishApi] set publishing api error';
export const setPublishApiError = (status) => ({ type: SET_PUBLISH_API_ERROR, payload: status });

// publish queryApi
export const PUBLISH_QUERY_API_SUCCESS = '[PublishApi] publish query api success';
export const PUBLISH_QUERY_API_ERROR = '[PublishApi] publish query api error';
export const PUBLISH_QUERY_API = '[PublishApi] publish query api';
export const publishQueryApi = (queryId) => ({ type: PUBLISH_QUERY_API, queryId });

export const UPDATE_API_KEY_SUCCESS = '[PublishApi] update api key success';
export const UPDATE_API_KEY_ERROR = '[PublishApi] update api key error';
export const UPDATE_API_KEY = '[PublishApi] update api key';
export const updateApiKey = (apiId, isRemove, queryId) => ({
  type: UPDATE_API_KEY,
  apiId,
  isRemove,
  queryId,
});

export const SET_UPDATING_API_KEY = '[PublishApi] set updating api key';
export const setUpdatingApiKey = (status) => ({ type: SET_UPDATING_API_KEY, payload: status });

export const SET_UPDATE_API_KEY_SUCCESS = '[PublishApi] set update api key success';
export const setUpdateApiKeySuccess = (status) => ({
  type: SET_UPDATE_API_KEY_SUCCESS,
  payload: status,
});

export const SET_UPDATE_API_KEY_ERROR = '[PublishApi] set update api key error';
export const setUpdateApiKeyError = (status) => ({
  type: SET_UPDATE_API_KEY_ERROR,
  payload: status,
});

export const RESET_PROGRESS_INDICATORS = '[PublishApi] reset api loading indicators';
export const resetApiProgressIndicators = (status) => ({ type: RESET_PROGRESS_INDICATORS });
