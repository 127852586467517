import { PayloadAction } from '@reduxjs/toolkit';

import { conditionalFormattingInitialState } from '../constants/conditions.constant';
import {
  conditionType,
  IConditionalFormattingCompareType,
  IListColumnsState,
  IConditionalFormattingTypes,
} from '../constants/conditions.interface';
import React from 'react';

export const updateListDataColumnConditionsReduxSlice = {
  resetConditions: (state: IListColumnsState, action: PayloadAction<{ index: number }>) => {
    state.columns[action.payload.index].conditions = conditionalFormattingInitialState;
    state.columns[action.payload.index].activeTab = '';
  },

  updateActiveTab: (
    state: IListColumnsState,
    action: PayloadAction<{ activeTab: string; index: number }>
  ) => {
    state.columns[action.payload.index].activeTab = action.payload.activeTab;
  },

  updateUseCondition: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      useCondition: boolean;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].useCondition =
      action.payload.useCondition;
  },

  updateCompareToType: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      type: IConditionalFormattingCompareType;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.type =
      action.payload.type;
  },

  updateValue: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      value: string;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.value =
      action.payload.value;
  },

  updateCondition: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      condition: conditionType;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.condition =
      action.payload.condition;
  },

  updateTextColor: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      color: string;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.style.color =
      action.payload.color;
  },

  updateBackgroundColor: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      backgroundColor: string;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[
      action.payload.conditionKey
    ].config.style.backgroundColor = action.payload.backgroundColor;
  },

  updateStyles: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      style: React.CSSProperties;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.style =
      Object.assign(
        state.columns[action.payload.index].conditions[action.payload.conditionKey].config.style,
        action.payload.style
      );
  },

  updateNegativeOverride: (
    state: IListColumnsState,
    action: PayloadAction<{
      conditionKey: keyof IConditionalFormattingTypes;
      overrideNegativeFormat: boolean;
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].conditions[
      action.payload.conditionKey
    ].config.overrideNegativeFormat = action.payload.overrideNegativeFormat;
  },

  deleteCondition: (
    state: IListColumnsState,
    action: PayloadAction<{ conditionKey: keyof IConditionalFormattingTypes; index: number }>
  ) => {
    state.columns[action.payload.index].conditions[action.payload.conditionKey].useCondition =
      false;

    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.condition =
      conditionalFormattingInitialState[action.payload.conditionKey].config.condition;

    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.type =
      conditionalFormattingInitialState[action.payload.conditionKey].config.type;

    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.value =
      conditionalFormattingInitialState[action.payload.conditionKey].config.value;

    state.columns[action.payload.index].conditions[
      action.payload.conditionKey
    ].config.overrideNegativeFormat =
      conditionalFormattingInitialState[action.payload.conditionKey].config.overrideNegativeFormat;

    state.columns[action.payload.index].conditions[action.payload.conditionKey].config.style.color =
      conditionalFormattingInitialState[action.payload.conditionKey].config.style.color;

    state.columns[action.payload.index].conditions[
      action.payload.conditionKey
    ].config.style.backgroundColor =
      conditionalFormattingInitialState[action.payload.conditionKey].config.style.backgroundColor;
  },
};
