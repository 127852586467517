import {
  IActiveScenario,
  IActiveSimulationsWithVizids,
  ISimulationListItem,
} from '../../../constants/tower360.interfaces';

/**
 * Iterates the simulation list & takes any items with useNow = true
 * Converts the list to the expected body payload for the chart data fetch API
 * @param list
 * @returns IActiveSimulationsWithVizids
 */
export const getActiveSimulations: (
  list: ISimulationListItem[],
  activeScenario: IActiveScenario
) => IActiveSimulationsWithVizids = (list, activeScenario) => {
  const reducedSimulationList = list.reduce(
    (acc: IActiveSimulationsWithVizids, item) => {
      if (item.useNow) {
        const prop = {
          [item.prop]: {
            type: item.type,
            subType: item.subType,
            value: Number(item.value),
            simulatedTags: [],
            calcnames: item.calcnames || '',
          },
        };
        return {
          ...acc,
          simulations: {
            ...acc.simulations,
            concepts: {
              ...acc.simulations.concepts,
              [item.concept]:
                item.concept in acc.simulations.concepts
                  ? {
                      ...acc.simulations.concepts[item.concept],
                      ...prop,
                    }
                  : prop,
            },
          },
          vizids: `${acc.vizids},${item.vizids}`,
        };
      } else {
        return acc;
      }
    },
    {
      simulations: {
        simulatedTag: activeScenario.id !== null ? activeScenario.id : null,
        concepts: {},
        data: null,
      },
      vizids: activeScenario.id !== null ? activeScenario.vizids : '',
    }
  );

  return reducedSimulationList;
};
