import { FC, ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

const HomeContent: FC<Props> = ({ children }): ReactElement => {
  return <div className="home-aside pa3 relative">{children}</div>;
};

export default HomeContent;
