import { NonIndexRouteObject, Outlet } from 'react-router-dom-v5-compat';

import { TowerRoutes } from '../../../../../RouteConstants';
import { HomeLayoutRoutes } from './layouts.home.routes';
import { TabbedLayoutRoutes } from './layouts.tabbed.routes';
import { TowerChildRoutes } from '../../../../TowerLayout/constants/tower.routes';

export const ProtectedRoutes: NonIndexRouteObject[] = [
  {
    path: '/*',
    async lazy() {
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const RootLayout = (await import('../../../RootLayout')).default;
      const ProtectedRouteLoader = (await import('../../../utils/protectedRoute.loader')).default;

      return {
        errorElement: <ErrorPage />,
        loader: ProtectedRouteLoader,
        element: <RootLayout />,
      };
    },
    children: [
      {
        path: `*`,
        async lazy() {
          const PageNotFound = (await import('../../../../../../Modules/PageNotFound/PageNotFound'))
            .default;
          const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
            .default;

          return {
            element: <PageNotFound />,
            errorElement: <ErrorPage />,
          };
        },
      },

      ...HomeLayoutRoutes,
      ...TabbedLayoutRoutes,

      {
        // Tower
        path: `${TowerRoutes.base}/*`,
        async lazy() {
          const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
            .default;

          return {
            errorElement: <ErrorPage />,
            element: <Outlet />,
          };
        },

        children: TowerChildRoutes,
      },
    ],
  },
];
