export const COPY_QUERY_FILTER = '[Copy Query Filter] Copy a query filter';
export const copyQueryFilter = (filters, propType) => ({
  type: COPY_QUERY_FILTER,
  payload: { filters, propType },
});

export const RESET_COPIED_QUERY_FILTER = '[Copy Query Filter] Reset a copied query filter';
export const resetCopiedQueryFilter = () => ({ type: RESET_COPIED_QUERY_FILTER });

export const COPY_QUERY_MAPPING_FILTER = '[Copy Query Filter] Copy a query mapping filter';
export const copyQueryMappingFilter = (filters, propType) => ({
  type: COPY_QUERY_MAPPING_FILTER,
  payload: { filters, propType },
});

export const RESET_COPIED_QUERY_MAPPING_FILTER =
  '[Copy Query Filter] Reset a copied query mapping filter';
export const resetCopiedQueryMappingFilter = () => ({ type: RESET_COPIED_QUERY_MAPPING_FILTER });

export const COPY_QUERY_AGG_FILTER = '[Copy Query Filter] Copy a query aggregate filter';
export const copyQueryAggFilter = (filters, propType) => ({
  type: COPY_QUERY_AGG_FILTER,
  payload: { filters, propType },
});

export const RESET_COPIED_QUERY_AGG_FILTER =
  '[Copy Query Filter] Reset a copied query aggregate filter';
export const resetCopiedQueryAggFilter = () => ({ type: RESET_COPIED_QUERY_AGG_FILTER });
