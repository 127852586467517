import { PayloadAction } from '@reduxjs/toolkit';
import { ICardBuilderState } from '../cardBuilder.interface';

const fetchCardsListReduxSlice = {
  initiateFetchCardsList: (state: ICardBuilderState) => {
    state.cardsList.list = [];
    state.cardsList.hasErrors = false;
    state.cardsList.loading = true;
  },

  fetchCardsListSuccess: (state: ICardBuilderState, action: PayloadAction<any>) => {
    state.cardsList.list = action.payload;
    state.cardsList.hasErrors = false;
    state.cardsList.loading = false;
  },

  fetchCardsListError: (state: ICardBuilderState, action: PayloadAction<any>) => {
    state.cardsList.list = action.payload;
    state.cardsList.hasErrors = true;
    state.cardsList.loading = false;
  },
};

export default fetchCardsListReduxSlice;
