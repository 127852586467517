import { PayloadAction } from '@reduxjs/toolkit';
import { ICardBuilderState } from '../cardBuilder.interface';

const fetchDataReduxSlice = {
  initiateFetchCardData: (state: ICardBuilderState) => {
    state.cardData.rawData = [];
    state.cardData.hasErrors = false;
    state.cardData.loading = true;
  },

  fetchCardDataSuccess: (state: ICardBuilderState, action: PayloadAction<any>) => {
    state.cardData.rawData = action.payload;
    state.cardData.hasErrors = false;
    state.cardData.loading = false;
  },

  fetchCardDataError: (state: ICardBuilderState, action: PayloadAction<any>) => {
    state.cardData.rawData = action.payload;
    state.cardData.hasErrors = true;
    state.cardData.loading = false;
  },
};

export default fetchDataReduxSlice;
