import {
  ISimulationListItem,
  ITower360ChartDataMap,
  ITower360ChartDataState,
  ITower360Collections,
  ITower360InitialState,
  ITower360NumericPropsInitialState,
  ITower360ScenariosInitialState,
  ITower360SimulationInitialState,
} from './tower360.interfaces';

export const SimulationListItem: ISimulationListItem = {
  prop: '',
  concept: '',
  type: 'value',
  subType: '+',
  value: '',
  useNow: false,
  vizids: '',
  id: '-1',
  isNew: false,
  calcnames: '',
};

export const Tower360SimulationState: ITower360SimulationInitialState = {
  activeTabIndex: 0,
  showConfig: false,
  compareToBaseline: false,
  loading: false,
  hasErrors: false,
  list: {},
  activeSimulations: {
    simulations: {
      concepts: {},
      data: null,
      simulatedTag: null,
    },
    vizids: '',
  },
  activeScenario: {
    id: null,
    name: '',
    vizids: '',
    props: {},
    calcnames: null,
  },
};

export const Tower360NumericPropsState: ITower360NumericPropsInitialState = {
  list: [],
  loading: false,
  hasErrors: false,
};

export const Tower360ScenariosState: ITower360ScenariosInitialState = {
  list: [],
  loading: false,
  hasErrors: false,
};

export const Tower360ChartDataMapInitialState: ITower360ChartDataMap = {};

export const Tower360ChartDataInitialState: ITower360ChartDataState = {
  data: [],
  loading: false,
  hasErrors: false,
  accessDenied: false,
  abortController: null,
};

export const Tower360CollectionsInitialState: ITower360Collections = {
  showDownloadView: false,
  showExpandedView: false,
  selectedViz: {
    id: -1,
    item: null,
  },
};

export const Tower360InitialState: ITower360InitialState = {
  loading: false,
  hasErrors: false,
  responsePayload: null,
  isEditMode: false,
  showFiltersConfig: false,
  navItems: {
    loading: false,
    hasErrors: false,
    responsePayload: null,
    selectedNavItems: [],
    availableNavItems: [],
  },
  navFilters: {
    loading: false,
    hasErrors: false,
    responsePayload: null,
    selectedNavFilters: [],
    availableNavFilters: [],
    appliedNavFilters: [],
  },
};
