import { SET_API_DETAILS, SET_IS_LOADING, SET_HAS_LOADING_ERROR } from './actions.js';

const initialState = {
  apiDetailsList: [],
  accessInfo: {
    apiKey: null,
    accessSecret: null,
  },
  isLoading: false,
  hasLoadingError: false,
};

export function apiDetailsReducer(state = initialState, action) {
  const stateCopy = { ...state };

  switch (action.type) {
    case SET_API_DETAILS:
      stateCopy.accessInfo.apiKey = action.payload.apiKey;
      stateCopy.accessInfo.accessSecret = action.payload.accessSecret;
      stateCopy.apiDetailsList = action.payload.apiList;
      return stateCopy;

    case SET_IS_LOADING:
      stateCopy.isLoading = action.payload;
      return stateCopy;

    case SET_HAS_LOADING_ERROR:
      stateCopy.hasLoadingError = action.payload;
      return stateCopy;

    default:
      return state;
  }
}
