import { getPropertyName } from 'utils/Studio/getPropertyName';

const extractMappingInfo = (data) => {
  const resultMap = {};

  data.forEach((item) => {
    const parentUri = item.uri;
    let hasChildMappings = false;
    item.properties.forEach((prop) => {
      const uri = prop.uri;
      const hasMapping = prop.selfMappings.length > 0;
      const parentName = getPropertyName(parentUri);
      resultMap[uri + parentName] = hasMapping; // this is required since there is a possibility that a question has 2 properties with the same URI.

      if (hasMapping) {
        hasChildMappings = true;
      }
    });
    resultMap[parentUri] = hasChildMappings;
  });

  return resultMap;
};

export default extractMappingInfo;
