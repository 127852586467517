import { Navigate, Outlet, useParams } from 'react-router-dom-v5-compat';

import { HomeRoutes, StudioRoutes } from '../../../../RouteConstants';
import { IRoutes } from '../../../../../components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';

import ErrorBoundry from '../../../../../components/ErrorBoundry/ErrorBoundry';
import ErrorPage from '../../../../../components/ErrorBoundry/ErrorPage';
import Features from '../../../../../Features/feature-flags';

// redirect for old route, in case someone has a domain id bookmarked
const EditDomainRedirect = () => {
  const params = useParams();
  return <Navigate to={`${StudioRoutes.editDomain.base}/${params.domainId}`} />;
};

const EditQuestionRedirect = () => {
  const params = useParams();
  return <Navigate to={`${StudioRoutes.base}/${StudioRoutes.questions.base}/${params.queryId}`} />;
};

const QuestionMappingRedirect = () => {
  const params = useParams();
  return (
    <Navigate
      to={`${StudioRoutes.base}/${StudioRoutes.questions.base}/${StudioRoutes.mapping.base}/${params.solutionId}/${params.queryId}/properties`}
    />
  );
};

export const routes: IRoutes[] = [
  {
    routeItem: {
      path: `${StudioRoutes.model.base}/*`,
      element: <Outlet />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          async lazy() {
            let Domains = (await import('../../../../../Modules/Studio/Domains/Domains')).default;
            const ToggleComponentBasedOnPermission = (
              await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
            ).default;
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={Domains}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.model.permission}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: `${StudioRoutes.editDomain.base}${StudioRoutes.editDomain.basePathVariables}`,
          async lazy() {
            const ToggleComponentBasedOnPermission = (
              await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
            ).default;
            let DomainDetails = (
              await import('../../../../../Modules/Studio/DomainEditor/DomainDetails')
            ).default;
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={DomainDetails}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.model.permission}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: `edit-domain${StudioRoutes.editDomain.basePathVariables}`,
          element: <EditDomainRedirect />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    permissionRef: StudioRoutes.model.permission,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${StudioRoutes.questions.base}/*`,
      async lazy() {
        let Questions = (await import('../../../../../Modules/Studio/QueriesList/Questions'))
          .default;
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={Questions}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={StudioRoutes.questions.permission}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },

      children: [
        {
          path: '',
          async lazy() {
            let DomainsHOC = (
              await import(
                '../../../../../Modules/Studio/QueriesList/components/DomainsList/DomainsHOC'
              )
            ).default;
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const ToggleComponentBasedOnPermission = (
              await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
            ).default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={DomainsHOC}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.questions.permission}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: StudioRoutes.questions.basePathVariables,
          async lazy() {
            const QuestionsList = (
              await import(
                '../../../../../Modules/Studio/QueriesList/components/QuestionsList/QuestionsList'
              )
            ).default;
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const ToggleComponentBasedOnPermission = (
              await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
            ).default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={QuestionsList}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.questions.permission}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
      ],
    },
    permissionRef: StudioRoutes.questions.permission,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${StudioRoutes.questions.base}/${StudioRoutes.createQuestion.base}${StudioRoutes.createQuestion.basePathVariables}/*`,
      async lazy() {
        const EditQuery = (
          await import('../../../../../components/Studio/QueryCreationTwo/EditQuery')
        ).default;
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={EditQuery}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={StudioRoutes.createQuestion.permission}
              any={true}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: StudioRoutes.createQuestion.permission,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `chat/*`,
      async lazy() {
        const AIView = (await import('../../../../../Modules/Studio/AI/AI')).default;

        return {
          element: <AIView />,
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: StudioRoutes.createQuestion.permission,
    featureFlag: (Features as any).aiChatPoc,
  },
  {
    routeItem: {
      path: `${StudioRoutes.questions.base}/create-question${StudioRoutes.createQuestion.basePathVariables}/*`,
      element: <EditQuestionRedirect />,
      errorElement: <ErrorPage />,
    },
    permissionRef: StudioRoutes.createQuestion.permission,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `map-question${StudioRoutes.mapping.basePathVariables}/*`,
      element: <QuestionMappingRedirect />,
      errorElement: <ErrorPage />,
    },
    permissionRef: StudioRoutes.mapping.permission,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${StudioRoutes.questions.base}/${StudioRoutes.mapping.base}${StudioRoutes.mapping.basePathVariables}/*`,
      async lazy() {
        let MapQuery = (await import('../../../../../components/Studio/QueryMapping/MapQuery'))
          .default;
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={MapQuery}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={StudioRoutes.mapping.permission}
              any={true}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },

      children: [
        {
          path: `${StudioRoutes.mapping.properties}/*`,
          async lazy() {
            let MappingProperties = (
              await import('../../../../../components/Studio/QueryMapping/MappingProperties')
            ).default;
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const ToggleComponentBasedOnPermission = (
              await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
            ).default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={() => (
                    <ErrorBoundry>
                      <MappingProperties standardGutter={30} />
                    </ErrorBoundry>
                  )}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.mapping.permission}
                  any={true}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: `${StudioRoutes.mapping.dataSources}/*`,
          async lazy() {
            let DataSources = (
              await import('../../../../../components/Studio/QueryMapping/DataSources/DataSources')
            ).default;
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const ToggleComponentBasedOnPermission = (
              await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
            ).default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={() => (
                    <ErrorBoundry>
                      <DataSources />
                    </ErrorBoundry>
                  )}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.mapping.permission}
                  any={true}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
      ],
    },
    permissionRef: StudioRoutes.mapping.permission,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${StudioRoutes.api.base}`,
      async lazy() {
        let ApiDetails = (await import('../../../../../Modules/Studio/ApiDetails/ApiDetails'))
          .default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={() => (
                <ErrorBoundry>
                  <ApiDetails />
                </ErrorBoundry>
              )}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={StudioRoutes.api.permission}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: StudioRoutes.api.permission,
    featureFlag: true,
  },

  {
    routeItem: {
      path: `${StudioRoutes.auditLog.base}`,
      async lazy() {
        let AuditLog = (await import('../../../../../Modules/Studio/AuditLog/AuditLog')).default;
        const ToggleComponentBasedOnPermission = (
          await import('../../../../../RBAC/ToggleComponentBasedOnPermission')
        ).default;

        return {
          element: (
            <ToggleComponentBasedOnPermission
              IsAllowed={() => (
                <ErrorBoundry>
                  <AuditLog />
                </ErrorBoundry>
              )}
              IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
              permissionRef={StudioRoutes.auditLog.permission}
            />
          ),
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: StudioRoutes.auditLog.permission,
    featureFlag: true,
  },
];
