import { PayloadAction } from '@reduxjs/toolkit';
import { ICardBuilderState } from '../cardBuilder.interface';
import { CardBuilderInitialState } from '../cardBuilder-initialState';
import { TowerCardItem } from '../../../../../../utils/Viz/@types/visualDefV1';

const cardModalReduxSlice = {
  setCardModalStep: (state: ICardBuilderState, action: PayloadAction<number>) => {
    state.cardModal.step = action.payload;
  },

  toggleConfigModal: (state: ICardBuilderState) => {
    state.cardModal.isConfigModalOpen = !state.cardModal.isConfigModalOpen;
  },

  setIsEditMode: (state: ICardBuilderState, action: PayloadAction<boolean>) => {
    state.cardModal.isEditMode = action.payload;
  },

  resetCardModalState: (state: ICardBuilderState) => {
    state.cardModal = CardBuilderInitialState.cardModal;
  },

  setV1SelectedConfig: (state: ICardBuilderState, action: PayloadAction<TowerCardItem>) => {
    state.cardModal.V1.selectedConfig = action.payload;
  },
};

export default cardModalReduxSlice;
