import { FC, ReactElement, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom-v5-compat';

import { RootRoutes } from './constants/routes/root.routes';
import LoadingIndicator from '../../../components/LoadingIndicatorMaterial/LoadingIndicator';

const Root: FC = (): ReactElement | null => {
  const [router, setRouter] = useState<any | null>(null);

  useEffect(() => {
    // ensure createBrowserRouter isn't called until the <App /> renders,
    // and this component renders w/in <App />
    const router = createBrowserRouter(RootRoutes);
    setRouter(router);
  }, []);

  if (router !== null) {
    return (
      <RouterProvider router={router} fallbackElement={<LoadingIndicator isLoading={true} />} />
    );
  } else {
    return null;
  }
};

export default Root;
