export const getColorSchemes = (type) => {
  switch (type) {
    case 'lineChart':
      return lineChart;

    case 'models':
      lineChart[0] = '#2B3947';
      return lineChart;

    default:
      break;
  }
};

const lineChart = [
  '#080616',
  '#481567',
  '#81176D',
  '#C92D59',
  '#ED504A',
  '#FA8657',
  '#FBC17D',
  '#FDE725',
  '#B8DE29',
  '#73D055',
  '#3CBB75',
  '#20A387',
  '#238A8D',
  '#2D708E',
  '#39568C',
  '#61606A',
  '#9B7FAC',
  '#AE6BA2',
  '#D25377',
  '#F06F6A',
  '#5C1529',
  '#453781',
  '#FEF49B',
  '#DEF09D',
  '#BFE9B1',
  '#A6E0C0',
  '#71C4B2',
  '#73B4B6',
  '#9FBECB',
  '#973330',
  '#723D28',
  '#737451',
  '#988EAB',
  '#C595BC',
  '#E69FB3',
  '#F6AFAC',
  '#D0BE1F',
  '#97B622',
  '#5FAB46',
  '#27774B',
  '#1D485B',
  '#20193B',
  '#8193B5',
  '#887FAE',
];
