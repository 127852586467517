import React from 'react';
import NavigateBefore from '@mui/icons-material/NavigateBefore';

export default function BackButton({ buttonProps = {} }) {
  return (
    <button
      type="button"
      className="bg-transparent h-inherit picklewood-blue br b--nobel flex items-center fs-14 lh-16 pa-24-right bl-0 bt-0 bb-0 pointer"
      {...buttonProps}
    >
      <NavigateBefore style={{ height: 36, width: 36 }} />
      <span>Back</span>
    </button>
  );
}
