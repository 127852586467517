import { IQuestionProperty } from './queryCreation.interface';

export const SUB_QUERY = 'subQuery';
export const SELF_JOIN_RELATION_NAME = '##selfJoin##';
export const SELF_JOIN_LABEL = 'SelfJoin';

export const NEW_PROPERTY: IQuestionProperty = {
  windowFunctions: [],
  aggregates: [],
  label: '',
  propTypeUri: '',
  uri: '',
  filters: [],
  hidden: false,
  optional: false,
  outputCategory: null,
  propTypeLabel: null,
  round: null,
  subQueryId: null,
  truncateTo: null,
};
