import Features from '../../../../../Features/feature-flags';
import { ILinks } from '../../../../../components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';
import { StudioRoutes } from '../../../../RouteConstants';

export const links: ILinks[] = [
  {
    tabItem: {
      to: `/${StudioRoutes.base}/${StudioRoutes.model.base}`,
      label: 'Ontology',
      variant: 'primary',
      testId: 'domains',
    },
    permissionRef: ['models', 'model', 'r'],
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${StudioRoutes.base}/${StudioRoutes.questions.base}`,
      label: 'Questions',
      variant: 'primary',
      testId: 'questions',
    },
    permissionRef: ['queries', 'query', 'r'],
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${StudioRoutes.base}/${StudioRoutes.api.base}`,
      label: 'APIs',
      variant: 'primary',
      testId: 'api',
    },
    permissionRef: ['apis', 'api', 'r'],
    featureFlag: true,
  },
  {
    tabItem: {
      to: `/${StudioRoutes.base}/chat`,
      label: 'Chat (Alpha)',
      variant: 'primary',
      testId: 'chat',
    },
    permissionRef: ['queries', 'query', 'r'],
    featureFlag: (Features as any).aiChatPoc,
  },
];
