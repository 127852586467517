import { CSSProperties } from 'react';

import { ITheme } from '@components/Theme/constants/Theme.interface';
import { IButtonVariant } from '../constants/Button.interface';

type Args = {
  variant: IButtonVariant;
  theme: ITheme;
};

const getButtonStyle = ({ variant, theme }: Args): CSSProperties => {
  return {
    backgroundColor:
      variant === 'primary' && theme?.useCustomTheme ? theme.colors.background : undefined,
    color: variant === 'primary' && theme?.useCustomTheme ? theme.colors.foreground : undefined,
  };
};

export default getButtonStyle;
