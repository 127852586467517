import {
  AUTHORIZE,
  AUTHORIZE_SUCCESS,
  AUTHORIZE_ERROR,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  SSO_OAUTH_LOG_IN,
  SSO_OAUTH_LOG_IN_SUCCESS,
  SSO_OAUTH_LOG_IN_ERROR,
  SUPER_USER_LOG_IN,
  SUPER_USER_LOG_IN_SUCCESS,
  SUPER_USER_LOG_IN_ERROR,
  FETCH_AUTH_SETUP_DETAILS,
  FETCH_AUTH_SETUP_DETAILS_SUCCESS,
  FETCH_AUTH_SETUP_DETAILS_ERROR,
  FETCH_AUTH_TYPE,
  FETCH_AUTH_TYPE_SUCCESS,
  FETCH_AUTH_TYPE_ERROR,
  SET_DEFAULT_TENANT,
  SET_DEFAULT_TENANT_SUCCESS,
  SET_DEFAULT_TENANT_ERROR,
  LOG_OUT_SSO,
  LOG_OUT_SSO_SUCCESS,
  LOG_OUT_SSO_ERROR,
  setIsAuthorized,
  logOut,
  appIsFinishedLoading,
  appIsLoading,
  loginFailure,
  setAuthSetupDetails,
  setAuthSetupIsLoading,
  setAuthSetupHasError,
  setAuthType,
  setDefaultTenant,
  setAuthTypeIsLoading,
  setAuthTypeHasError,
} from './actions';

import { resetCurrentUser, setCurrentUser, setRememberMe } from '../Users/actions';
import { apiRequest, apiLoginRequest, apiRequestNoAuth } from '../Api/actions';
import url from '../../api-config';
import { setupAxiosConfig } from '../../Api/utils';
import { resetRoles } from '../Roles/actions';

export const authActions =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case AUTHORIZE:
        dispatch(
          apiRequest(
            'GET',
            url.userProfile,
            {},
            AUTHORIZE_SUCCESS,
            AUTHORIZE_ERROR,
            {},
            {},
            action?.payload?.location
          )
        );
        break;

      case LOG_IN:
        dispatch(appIsLoading());
        dispatch(
          apiLoginRequest(
            'POST',
            url.login,
            action.payload.credentials,
            LOG_IN_SUCCESS,
            LOG_IN_ERROR,
            action.payload.rememberMe,
            {
              userName: action.payload.credentials.userName,
              tenantId: action.payload.credentials.tenantId,
              isDefault: action.payload.credentials.defaultTenant,
              rememberMe: action.payload.rememberMe,
            }
          )
        );
        break;

      case SUPER_USER_LOG_IN:
        dispatch(appIsLoading());
        dispatch(
          apiLoginRequest(
            'POST',
            url.loginSuperUser,
            action.payload.credentials,
            SUPER_USER_LOG_IN_SUCCESS,
            SUPER_USER_LOG_IN_ERROR,
            action.payload.rememberMe,
            {
              rememberMe: action.payload.rememberMe,
            }
          )
        );
        break;

      case SSO_OAUTH_LOG_IN:
        dispatch(appIsLoading());
        dispatch(
          apiLoginRequest(
            'POST',
            url.ssoOauthLogin,
            action.payload.credentials,
            SSO_OAUTH_LOG_IN_SUCCESS,
            SSO_OAUTH_LOG_IN_ERROR,
            action.payload.rememberMe,
            {
              userName: action.payload.credentials.userName,
              tenantId: action.payload.credentials.tenantId,
              isDefault: action.payload.credentials.defaultTenant,
              rememberMe: action.payload.rememberMe,
            }
          )
        );
        break;

      case FETCH_AUTH_SETUP_DETAILS:
        dispatch(setAuthSetupIsLoading(true));
        dispatch(setAuthSetupHasError(false));
        dispatch(
          apiRequest(
            'GET',
            url.authSetup,
            {},
            FETCH_AUTH_SETUP_DETAILS_SUCCESS,
            FETCH_AUTH_SETUP_DETAILS_ERROR
          )
        );
        break;

      case FETCH_AUTH_TYPE:
        dispatch(setAuthTypeIsLoading(true));
        dispatch(setAuthTypeHasError(false));
        dispatch(
          apiRequestNoAuth('GET', url.authType, {}, FETCH_AUTH_TYPE_SUCCESS, FETCH_AUTH_TYPE_ERROR)
        );
        break;

      case SET_DEFAULT_TENANT:
        dispatch(
          apiRequest(
            'POST',
            `${url.userTenants}/default`,
            action.payload,
            SET_DEFAULT_TENANT_SUCCESS,
            SET_DEFAULT_TENANT_ERROR
          )
        );
        break;

      case LOG_OUT_SSO:
        dispatch(
          apiRequest('GET', `${url.eoAuthLogout}`, {}, LOG_OUT_SSO_SUCCESS, LOG_OUT_SSO_ERROR)
        );
        break;

      default:
        break;
    }

    next(action);
  };

export const processAuthActions =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case AUTHORIZE_ERROR:
        dispatch(logOut());
        dispatch(appIsFinishedLoading());

        // clear currentUser state
        dispatch(resetCurrentUser({}));

        // clear roles
        dispatch(resetRoles());
        break;

      case LOG_IN_ERROR:
      case SUPER_USER_LOG_IN_ERROR:
      case SSO_OAUTH_LOG_IN_ERROR:
        dispatch(loginFailure());
        dispatch(logOut());

        // clear current user state
        dispatch(resetCurrentUser({}));
        // clear roles
        dispatch(resetRoles());

        dispatch(appIsFinishedLoading());
        break;

      case LOG_IN_SUCCESS:
      case SSO_OAUTH_LOG_IN_SUCCESS:
        dispatch(setIsAuthorized());
        dispatch(setRememberMe(action.payload?.rememberMe || false));

        try {
          if (action.payload.isDefault && action.payload.tenantId.length > 0) {
            dispatch(setDefaultTenant(action.payload.userName, action.payload.tenantId));
          }
        } catch (e) {
          console.warn('Error setting default tenant', e);
          console.warn(action.payload);
        }
        break;

      case SUPER_USER_LOG_IN_SUCCESS:
        dispatch(setIsAuthorized());
        dispatch(setRememberMe(action.payload?.rememberMe || false));
        break;

      case SET_DEFAULT_TENANT_SUCCESS:
        // do nada
        break;

      case SET_DEFAULT_TENANT_ERROR:
        console.warn('Error happened setting default tenant');
        break;

      case AUTHORIZE_SUCCESS:
        dispatch(setIsAuthorized());
        dispatch(
          setCurrentUser(
            action.payload.userDetails,
            action.payload.currentTenantId,
            action.payload.tenantRoles
          )
        );

        // Call setupAxiosConfig to add the Authorization header at a top level axios config
        setupAxiosConfig();
        break;

      case FETCH_AUTH_SETUP_DETAILS_ERROR:
        dispatch(setAuthSetupIsLoading(false));
        dispatch(setAuthSetupHasError(true));
        break;

      case FETCH_AUTH_SETUP_DETAILS_SUCCESS:
        dispatch(setAuthSetupIsLoading(false));
        dispatch(setAuthSetupDetails(action.payload));
        break;

      case FETCH_AUTH_TYPE_SUCCESS:
        dispatch(setAuthType(action.payload));
        dispatch(setAuthTypeIsLoading(false));
        break;

      case FETCH_AUTH_TYPE_ERROR:
        dispatch(setAuthType({ authType: 'ERROR' }));
        dispatch(setAuthTypeHasError(true));
        dispatch(setAuthTypeIsLoading(false));
        break;

      case LOG_OUT_SSO_SUCCESS:
        dispatch(logOut());
        // clear current user state
        dispatch(resetCurrentUser({}));
        // clear roles
        dispatch(resetRoles());
        break;

      case LOG_OUT_SSO_ERROR:
        console.warn('ERROR LOGGING OUT');
        break;

      default:
        break;
    }
  };

export const authModel = [processAuthActions, authActions];
