import {
  SET_ROLES,
  SET_IS_LOADING,
  SET_HAS_LOADING_ERROR,
  SET_USER_ROLES,
  SET_ROLES_TEST,
  RESET_ROLES,
} from './actions.js';
import cloneDeep from 'lodash/cloneDeep';

import Rbac from '../../RBAC/rbac';

const initialState = {
  rolesList: [],
  roleMapByName: {},
  superAdminName: 'SUPER_ADMIN',
  adminRoleName: 'TENANT_ADMIN',
  studioEditorRoleName: 'STUDIO_EXPERT',
  datamapExpertRoleName: 'DATA_MAP_EXPERT',
  tenantUserRoleName: 'TENANT_USER',

  permissions: {},

  // TODO: these 3 can go too
  isLoading: false,
  hasLoadingError: false,
  doneLoading: false,
};

export const rolesReducer = (state = initialState, action) => {
  const stateCopy = cloneDeep(state);

  switch (action.type) {
    case RESET_ROLES:
      return {
        ...state,
        rolesList: [],
      };

    case SET_ROLES:
      const roleMapByName = action.payload.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: {
            ...curr,
          },
        }),
        {}
      );

      if (
        !roleMapByName[state.adminRoleName] ||
        !roleMapByName[state.studioEditorRoleName] ||
        !roleMapByName[state.datamapExpertRoleName] ||
        !roleMapByName[state.tenantUserRoleName] ||
        !roleMapByName[state.superAdminName]
      ) {
        console.error('ONE OF ROLE NAMES CHANGED, USERS TAB WILL BREAK', roleMapByName);
      }

      return {
        ...state,
        rolesList: action.payload,
        roleMapByName,
        doneLoading: true,
      };

    case SET_USER_ROLES:
      const userRoles = convertRoleNames(action.payload, state.roleMapByName);
      stateCopy.permissions = Rbac.buildPermissionList(userRoles);
      return stateCopy;

    case SET_IS_LOADING:
      stateCopy.isLoading = action.payload;
      return stateCopy;

    case SET_HAS_LOADING_ERROR:
      stateCopy.hasLoadingError = action.payload;
      return stateCopy;

    case SET_ROLES_TEST:
      return {
        ...state,
        permissions: Rbac.buildPermissionList(action.payload),
      };

    default:
      return state;
  }
};

// ********* NOTE: the id of the role that gets added to rolelist has a -1 because the 1st roleId starts with 1 & not 0
const convertRoleNames = (roles = [], roleMapByName) =>
  roles.reduce((acc, item) => [...acc, Number(roleMapByName[item.name].id) - 1], []);
