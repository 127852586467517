import { NonIndexRouteObject } from 'react-router-dom-v5-compat';

import { HomeRoutes } from '../../../../../RouteConstants';

// eOauth SSO routes
export const OAuthLoginPublicRoutes: NonIndexRouteObject[] = [
  {
    path: HomeRoutes.oAuthcb,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const TenantSelectLogin = (
        await import('../../../../../../Modules/Authentication/Login/SSO/TenantSelectLogin')
      ).default;
      const { SSOLoginAction } = await import(
        '../../../../../../Modules/Authentication/Login/SSO/utils/ssoLogin.action'
      );
      const { SSOLoginLoader } = await import(
        '../../../../../../Modules/Authentication/Login/SSO/utils/ssoLogin.loader'
      );

      return {
        element: <HomeLayoutLoginRoutes content={<TenantSelectLogin />} />,
        errorElement: <ErrorPage />,
        action: SSOLoginAction,
        loader: SSOLoginLoader,
      };
    },
  },
  {
    path: HomeRoutes.eoAuthLogin,
    async lazy() {
      const LoginRedirect = (await import('../../../../../../Modules/LoginRedirect/LoginRedirect'))
        .default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;

      return {
        element: <LoginRedirect />,
        errorElement: <ErrorPage />,
      };
    },
  },
  {
    path: HomeRoutes.eoAuthLogout,
    async lazy() {
      const LoginRedirect = (await import('../../../../../../Modules/LoginRedirect/LoginRedirect'))
        .default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;

      return {
        element: <LoginRedirect requestType="logout" />,
        errorElement: <ErrorPage />,
      };
    },
  },
  {
    path: HomeRoutes.ssoLogout,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const LogoutMessageSSO = (
        await import('../../../../../../Modules/Authentication/Logout/SSO/LogoutMessageSSO')
      ).default;

      return {
        element: <HomeLayoutLoginRoutes content={<LogoutMessageSSO />} />,
        errorElement: <ErrorPage />,
      };
    },
  },
];
