import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { authReducer } from './Auth/reducers';
import { tenantsReducer } from './Tenants/reducers';
import { apiDetailsReducer } from './ApiDetails/reducers';
import { usersReducer } from './Users/reducers';
import { rolesReducer } from './Roles/reducers';
import { queriesReducer } from './Studio/Queries/reducers';
import { queryCreationReducer } from './Studio/QueryCreation/reducers';
import { studioApiReducer } from './Studio/Api/reducers';
import { studioDataSourcesReducer } from './Studio/DataSources/reducers';
import { studioPropertiesReducer } from './Studio/Properties/reducers';
import { studioDatasetsReducer } from './Studio/Datasets/reducers';
import { copyQueryFiltersReducer } from './Studio/CopyQueryFilter/reducers';
import tower360ReduxSlice from '../Apps/Tower360/redux/tower360-redux-slice';
import tower360SimulationsSlice from '../Apps/Tower360/redux/simulations/simulations-redux-slice';
import tower360NumericPropsSlice from '../Apps/Tower360/redux/numericProps/numericProps-redux-slice';
import tower360CollectionsSlice from '../Apps/Tower360/redux/collections/collections-redux-slice';
import tower360ChartDataMapSlice from '../Apps/Tower360/redux/chartData/chartData-redux-slice';
import tower360ScenariosSlice from '../Apps/Tower360/redux/scenarios/scenarios-redux-slice';
import cardBuilderReduxSlice from '../Modules/Studio/Questions/Cards/redux/cardBuilder-redux-slice';
import collectionsReduxSlice from '../components/Studio/QueryCreationTwo/ActionButtons/components/MoreMenu/Collections/redux/collections-redux-slice';
import goalTableColorsReduxSlice from '../components/Studio/QueryCreationTwo/ActionButtons/Visualization/ChartForms/GoalTable/redux/goalTable-redux-slice';
import listDataColumnsReduxSlice from '../components/Studio/QueryCreationTwo/ActionButtons/Visualization/ChartForms/List/redux/list-data-columns-redux-slice';
import errorModalReduxSlice from './ErrorModal/error-modal-redux-slice';
import relationLookupsSlice from '@components/Studio/QueryMapping/Properties/Mapping/Lookups/redux/relationLookups/relationLookups.slice';
import subQuestionsModalSlice from '../Modules/Studio/Questions/EditQuestion/SubQuestions/AddSubQuestionModal/redux/subQuestionsModal.slice';
import subQuestionsSlice from 'Modules/Studio/Questions/EditQuestion/SubQuestions/redux/subQuestions.slice';
import propertiesListModalsSlice from 'Modules/Studio/Questions/EditQuestion/PropertiesList/redux/propertiesListModals.slice';
import relationsModalSlice from '@components/Studio/QueryCreationTwo/Props/modals/RelationsModal/redux/relations.slice';

export const reducers = combineReducers({
  tenants: tenantsReducer,
  userInfo: authReducer,
  users: usersReducer,
  router: routerReducer,
  roles: rolesReducer,
  queries: queriesReducer,
  queryCreation: queryCreationReducer,
  studioApi: studioApiReducer,
  queryProperties: studioPropertiesReducer,
  queryCopiedFilters: copyQueryFiltersReducer,
  studioDatasets: studioDatasetsReducer,
  studioDataSources: studioDataSourcesReducer,
  apiDetails: apiDetailsReducer,
  tower360: tower360ReduxSlice,
  tower360Simulations: tower360SimulationsSlice,
  tower360NumericProps: tower360NumericPropsSlice,
  tower360Collections: tower360CollectionsSlice,
  tower360ChartDataMap: tower360ChartDataMapSlice,
  tower360Scenarios: tower360ScenariosSlice,
  cardBuilder: cardBuilderReduxSlice,
  collections: collectionsReduxSlice,
  goalTableStyles: goalTableColorsReduxSlice,
  listDataColumns: listDataColumnsReduxSlice,
  errorModal: errorModalReduxSlice,
  relationLookups: relationLookupsSlice,
  subQuestionsModal: subQuestionsModalSlice,
  subQuestions: subQuestionsSlice,
  propertiesListModals: propertiesListModalsSlice,
  relationsModal: relationsModalSlice,
});
