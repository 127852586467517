// fetch domain list
export const SET_READ_DOMAINS_LIST = 'SET_READ_DOMAINS_LIST';
export const SET_READ_DOMAINS_LIST_PENDING = 'SET_READ_DOMAINS_LIST_PENDING';
export const SET_READ_DOMAINS_LIST_FAILED = 'SET_READ_DOMAINS_LIST_FAILED';
export const setReadDomainsList = (data) => ({
  type: SET_READ_DOMAINS_LIST,
  payload: data,
});
export const setReadDomainsListPending = (status) => ({
  type: SET_READ_DOMAINS_LIST_PENDING,
  payload: status,
});
export const setReadDomainsListFailed = ({ status, error }) => ({
  type: SET_READ_DOMAINS_LIST_FAILED,
  payload: { status, error },
});

export const READ_DOMAINS_LIST = 'READ_DOMAINS_LIST';
export const READ_DOMAINS_LIST_SUCCESS = 'READ_DOMAINS_LIST_SUCCESS';
export const READ_DOMAINS_LIST_FAILED = 'READ_DOMAINS_LIST_FAILED';
export const fetchDomainsList = () => ({
  type: READ_DOMAINS_LIST,
});

// fetch queries list
export const SET_QUERIES_LIST = '[QueriesList] Set QueriesList';
export const setQueriesList = (queriesList, currentUser) => ({
  type: SET_QUERIES_LIST,
  payload: { queriesList, currentUser },
});

export const SET_QUERIES_LIST_IS_LOADING = '[QueriesList] Set QueriesList Is Loading';
export const setQueriesListIsLoading = (status) => ({
  type: SET_QUERIES_LIST_IS_LOADING,
  payload: status,
});

export const SET_QUERIES_LIST_HAS_LOADING_ERROR = '[QueriesList] Set QueriesList Has Loading Error';
export const setQueriesListHasLoadingError = (status) => ({
  type: SET_QUERIES_LIST_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_QUERIES_LIST = '[QueriesList] Fetch QueriesList';
export const FETCH_QUERIES_LIST_SUCCESS = '[QueriesList] Fetch QueriesList Success';
export const FETCH_QUERIES_LIST_ERROR = '[QueriesList] Fetch QueriesList Error';
export const fetchQueriesList = (domainId, currentUser) => ({
  type: FETCH_QUERIES_LIST,
  payload: { domainId, currentUser },
});

export const FILTER_QUERIES_LIST = '[QueriesList] filter QueriesList';
export const filterQuestionsList = (searchStr, ownerStr, selectMode, currentUser) => ({
  type: FILTER_QUERIES_LIST,
  payload: { searchStr, ownerStr, selectMode, currentUser },
});

export const UPDATE_QUERIES_SEARCH_STR = '[QueriesList] update search string for QueriesList';
export const updateQuestionsSearchStr = (searchStr) => ({
  type: UPDATE_QUERIES_SEARCH_STR,
  payload: searchStr,
});

export const UPDATE_OWNER_STR = '[QueriesList] update owner string for QueriesList';
export const updateOwnerStr = (ownerStr) => ({ type: UPDATE_OWNER_STR, payload: ownerStr });

export const RESET_QUERIES_LIST_STATE = '[QueriesList] reset state';
export const resetQuestionsListState = () => ({ type: RESET_QUERIES_LIST_STATE });

export const UPDATE_SELECT_MODE = '[QueriesList] update select mode';
export const updateSelectMode = (selectMode) => ({ type: UPDATE_SELECT_MODE, payload: selectMode });

export const UPDATE_SELECTED_QUESTIONS = '[QueriesList] update selected questions';
export const updateSelectedQs = (selected) => ({
  type: UPDATE_SELECTED_QUESTIONS,
  payload: selected,
});
