import { matchSorter } from 'match-sorter';

import {
  SET_TENANTS,
  SET_IS_LOADING,
  SET_HAS_LOADING_ERROR,
  SET_TENANT_ADMINS,
  SET_TENANT_ADMIN_IS_LOADING,
  SET_TENANT_ADMIN_HAS_LOADING_ERROR,
  SEARCH_ADMIN_LIST,
  FILTER_ADMIN_LIST_BY_TENANT,
  RESET_LISTS,
} from './actions.js';

const adminListSortBy = 'firstName';

const sortListByKey = (list, key) => {
  let sortedList = list;
  try {
    sortedList = list.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
  } catch (e) {
    console.warn('error sorting', e);
  }
  return sortedList;
};

const searchAdminList = (list, value) => {
  const newFilteredList = matchSorter(list, value, {
    keys: ['firstName', 'lastName', 'userName', 'email', 'phone'],
  });
  return newFilteredList;
};

const filterListByTentant = (listToSearch, filterTenantBy) => {
  return filterTenantBy.length > 0
    ? listToSearch.filter((item) =>
        item.tenants.some((tenant) => (tenant.id === filterTenantBy ? item : false))
      )
    : listToSearch;
};

const initialState = {
  tenantsList: [],
  isLoading: false,
  hasLoadingError: false,

  adminList: [],
  filteredAdminList: [],
  isAdminsLoading: true,
  adminsHasLoadingError: false,

  filterTenantBy: '',
  searchAdminBy: '',
};

export function tenantsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TENANTS:
      return {
        ...state,
        tenantsList: sortListByKey(action.payload, 'name'),
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_HAS_LOADING_ERROR:
      return {
        ...state,
        hasLoadingError: action.payload,
      };

    case SET_TENANT_ADMINS:
      let adminList = action.payload;
      let filteredAdminList = adminList;

      // keep filtering if it's set
      if (state.searchAdminBy.length > 0) {
        filteredAdminList = searchAdminList(filteredAdminList, state.searchAdminBy);
      }
      if (state.filterTenantBy.length > 0) {
        filteredAdminList = filterListByTentant(filteredAdminList, state.filterTenantBy);
      }

      filteredAdminList = sortListByKey(filteredAdminList, adminListSortBy);

      return {
        ...state,
        adminList,
        filteredAdminList,
      };

    case SEARCH_ADMIN_LIST:
      const searchAdminBy = action.payload.value;
      let newFilteredList = [];

      // first find admins w/search term
      newFilteredList = searchAdminList(state.adminList, searchAdminBy);

      // if filtered tenant set too, filter list by tenant
      if (state.filterTenantBy.length > 0) {
        newFilteredList = filterListByTentant(newFilteredList, state.filterTenantBy);
      }

      return {
        ...state,
        filteredAdminList: sortListByKey(newFilteredList, adminListSortBy),
        searchAdminBy,
      };

    case FILTER_ADMIN_LIST_BY_TENANT:
      const filterTenantBy = action.payload.value;

      let newFilteredListByTenant = filterListByTentant(state.adminList, filterTenantBy);

      // if search term is set, keep filtering on that term
      if (state.searchAdminBy.length > 0) {
        newFilteredListByTenant = searchAdminList(newFilteredListByTenant, state.searchAdminBy);
      }

      return {
        ...state,
        filteredAdminList: sortListByKey(newFilteredListByTenant, adminListSortBy),
        filterTenantBy,
      };

    case SET_TENANT_ADMIN_IS_LOADING:
      return {
        ...state,
        isAdminsLoading: action.payload,
      };

    case SET_TENANT_ADMIN_HAS_LOADING_ERROR:
      return {
        ...state,
        adminsHasLoadingError: action.payload,
      };

    case RESET_LISTS:
      return initialState;

    default:
      return state;
  }
}
