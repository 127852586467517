import config from 'card-builder-constants';

const { list, text, timeline, sankey, barLineCombo } = config.chartTypes.values;

export const CARD_STEPS = {
  initial: 0,
  selectChartType: 1,
  selectRecipe: 2,
  selectData: 3,
  configureChart: 4,
};

export const V1_CHARTS = [list, text, timeline, sankey, barLineCombo];
