import {
  COPY_QUERY_FILTER,
  RESET_COPIED_QUERY_FILTER,
  COPY_QUERY_MAPPING_FILTER,
  RESET_COPIED_QUERY_MAPPING_FILTER,
  COPY_QUERY_AGG_FILTER,
  RESET_COPIED_QUERY_AGG_FILTER,
} from './actions';

const initialState = {
  copiedFilters: [],
  propType: null,

  copiedMappingFilters: [],
  mappingPropType: null,

  copiedAggFilters: [],
  aggPropType: null,
};

export function copyQueryFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case COPY_QUERY_FILTER:
      return {
        ...state,
        copiedFilters: action.payload.filters,
        propType: action.payload.propType,
      };

    case COPY_QUERY_MAPPING_FILTER:
      return {
        ...state,
        copiedMappingFilters: action.payload.filters,
        mappingPropType: action.payload.propType,
      };

    case RESET_COPIED_QUERY_FILTER:
      return {
        ...state,
        copiedFilters: [],
        propType: null,
      };

    case RESET_COPIED_QUERY_MAPPING_FILTER:
      return {
        ...state,
        copiedMappingFilters: [],
        mappingPropType: null,
      };

    case COPY_QUERY_AGG_FILTER:
      return {
        ...state,
        copiedAggFilters: action.payload.filters,
        aggPropType: action.payload.propType,
      };

    case RESET_COPIED_QUERY_AGG_FILTER:
      return {
        ...state,
        copiedAggFilters: [],
        aggPropType: null,
      };

    default:
      return state;
  }
}
