/**
 * handleResponseError - helper function to throw and error for network requests
 * @param {Object} response
 * @returns Error
 */
export default function handleResponseError(response) {
  throw {
    status: response.status,
    message: `Request to ${response.url} failed.`,
  };
}
