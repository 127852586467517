import { accounts } from './accounts';
import { admins } from './admins';
import { apis } from './apis';
import { datasets } from './datasets';
import { datasources } from './datasources';
import { ingestions } from './ingestions';
import { mappings } from './mappings';
import { models } from './models';
import { profiles } from './profiles';
import { queries } from './queries';
import { security } from './security';
import { tenants } from './tenants';
import { users } from './users';
import { tower360 } from './tower360';
import { questionOwner } from './questionOwner';

const ResourceStatements = {
  accounts,
  admins,
  apis,
  datasets,
  datasources,
  ingestions,
  mappings,
  models,
  profiles,
  queries,
  security,
  tenants,
  users,
  tower360,
  questionOwner,
};

export default ResourceStatements;
