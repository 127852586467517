import { Navigate, RouteObject } from 'react-router-dom-v5-compat';

import { BlossomRoutes, HomeRoutes, StudioRoutes } from '../../../../../RouteConstants';

import { links as podManagementTabLinks } from '../../../../TabbedLayout/constants/links/podManagement.links';
import { links as studioTabLinks } from '../../../../TabbedLayout/constants/links/studio.links';
import { links as tenantAdminTabLinks } from '../../../../TabbedLayout/constants/links/tenantAdmin.links';

import { getRoutes } from '../../../../../../components/3.0/Layouts/TabbedLayout/utils/getRoutes';
import { routes as routesForStudio } from '../../../../TabbedLayout/constants/routes/studio.routes';
import { routes as routesForPodMgmt } from '../../../../TabbedLayout/constants/routes/podManagement.routes';
import { routes as routesForTA } from '../../../../TabbedLayout/constants/routes/tenantAdmin.routes';

// getRoutes will filter out any routes that shouldn't exist, when a feature flag is set to true
// OR, any other boolean logic is set in the routes constants for the various routes
const filteredRoutes = {
  studio: getRoutes(routesForStudio),
  podManagement: getRoutes(routesForPodMgmt),
  tenantAdmin: getRoutes(routesForTA),
};

export const TabbedLayoutRoutes: RouteObject[] = [
  {
    // user settings
    path: `${BlossomRoutes.userSettings.base}/*`,
    async lazy() {
      let UserSettings = (
        await import('../../../../../../Modules/Account/UserSettings/UserSettings')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;

      return { element: <UserSettings />, errorElement: <ErrorPage /> };
    },
  },
  {
    // pod management (admin-login)
    path: `${BlossomRoutes.podManagement.base}/*`,
    async lazy() {
      let TabbedLayout = (
        await import('../../../../../../components/3.0/Layouts/TabbedLayout/TabbedLayout')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;

      return {
        element: <TabbedLayout links={podManagementTabLinks} />,
        errorElement: <ErrorPage />,
      };
    },
    children: filteredRoutes.podManagement,
  },
  {
    // tenant admin
    path: `${BlossomRoutes.tenantAdmin.base}/*`,
    async lazy() {
      let TabbedLayout = (
        await import('../../../../../../components/3.0/Layouts/TabbedLayout/TabbedLayout')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;
      const ToggleComponentBasedOnPermission = (
        await import('../../../../../../RBAC/ToggleComponentBasedOnPermission')
      ).default;

      return {
        element: (
          <ToggleComponentBasedOnPermission
            IsAllowed={() => <TabbedLayout links={tenantAdminTabLinks} />}
            IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
            permissionRef={BlossomRoutes.tenantAdmin.permissions}
          />
        ),
        errorElement: <ErrorPage />,
      };
    },
    children: filteredRoutes.tenantAdmin,
  },
  {
    // studio
    path: `${StudioRoutes.base}/*`,
    async lazy() {
      let TabbedLayout = (
        await import('../../../../../../components/3.0/Layouts/TabbedLayout/TabbedLayout')
      ).default;
      let ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage')).default;

      return {
        element: <TabbedLayout links={studioTabLinks} />,
        errorElement: <ErrorPage />,
      };
    },
    children: filteredRoutes.studio,
  },
];
