export const AUTHORIZE = '[Auth] Authorize';
export const AUTHORIZE_SUCCESS = '[Auth] Authorize Success';
export const AUTHORIZE_ERROR = '[Auth] Authorize Error';
export const authorize = (token) => ({ type: AUTHORIZE, payload: token });

export const SET_DEFAULT_TENANT = '[Auth] Setup default tenant';
export const SET_DEFAULT_TENANT_SUCCESS = '[Auth] Setup default tenant Success';
export const SET_DEFAULT_TENANT_ERROR = '[Auth] Setup default tenant Error';
export const setDefaultTenant = (userName, tenantId) => ({
  type: SET_DEFAULT_TENANT,
  payload: { userName, tenantId },
});

export const LOG_IN = '[Auth] Log In';
export const LOG_IN_SUCCESS = '[Auth] Log In Success';
export const LOG_IN_ERROR = '[Auth] Log In Error';
export const login = (credentials, rememberMe) => ({
  type: LOG_IN,
  payload: { credentials, rememberMe },
});

export const SUPER_USER_LOG_IN = '[Auth] Super User Log In';
export const SUPER_USER_LOG_IN_SUCCESS = '[Auth] Super User Log In Success';
export const SUPER_USER_LOG_IN_ERROR = '[Auth] Super User Log In Error';
export const superUserLogin = (credentials, rememberMe) => ({
  type: SUPER_USER_LOG_IN,
  payload: { credentials, rememberMe },
});

export const SSO_OAUTH_LOG_IN = '[Auth] SSO oAuth Log In';
export const SSO_OAUTH_LOG_IN_SUCCESS = '[Auth] SSO oAuth Log In Success';
export const SSO_OAUTH_LOG_IN_ERROR = '[Auth] SSO oAuth Log In Error';
export const ssoOauthLogin = (credentials, rememberMe) => ({
  type: SSO_OAUTH_LOG_IN,
  payload: { credentials, rememberMe },
});

export const LOG_OUT = '[Auth] Log Out';
export const logOut = () => ({ type: LOG_OUT });

export const LOG_OUT_SSO_SUCCESS = '[Auth] Log Out SSO success';
export const LOG_OUT_SSO_ERROR = '[Auth] Log Out SSO error';
export const LOG_OUT_SSO = '[Auth] Log Out SSO';
export const logOutSSO = () => ({ type: LOG_OUT_SSO });

export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED';
export const setIsAuthorized = () => ({ type: SET_IS_AUTHORIZED });

export const APP_IS_LOADED = '[Auth] App is Loaded';
export const appIsFinishedLoading = () => ({ type: APP_IS_LOADED });

export const APP_IS_LOADING = '[Auth] App is Loading';
export const appIsLoading = () => ({ type: APP_IS_LOADING });

export const LOGIN_FAILURE = '[Auth] Login Failure';
export const loginFailure = () => ({ type: LOGIN_FAILURE });

export const FETCH_AUTH_SETUP_DETAILS = '[Auth] Fetch Auth Setup Details';
export const FETCH_AUTH_SETUP_DETAILS_SUCCESS = '[Auth] Fetch Auth Setup Details Success';
export const FETCH_AUTH_SETUP_DETAILS_ERROR = '[Auth] Fetch Auth Setup Details Error';
export const fetchAuthSetupDetails = () => ({ type: FETCH_AUTH_SETUP_DETAILS });

export const SET_AUTH_SETUP_DETAILS = '[Auth] Set Auth Setup Details';
export const setAuthSetupDetails = (details) => ({
  type: SET_AUTH_SETUP_DETAILS,
  payload: details,
});

export const SET_AUTH_SETUP_IS_LOADING = '[Auth] Auth Setup Is Loading';
export const setAuthSetupIsLoading = (isLoading) => ({
  type: SET_AUTH_SETUP_IS_LOADING,
  payload: isLoading,
});

export const SET_AUTH_SETUP_HAS_ERROR = '[Auth] Auth Setup Has Error';
export const setAuthSetupHasError = (didFail) => ({
  type: SET_AUTH_SETUP_HAS_ERROR,
  payload: didFail,
});

export const SET_AUTH_TYPE = '[Auth] Set auth type';
export const setAuthType = (type) => ({ type: SET_AUTH_TYPE, payload: type });

export const SET_AUTH_TYPE_IS_LOADING = 'SET_AUTH_TYPE_IS_LOADING';
export const setAuthTypeIsLoading = (status) => ({
  type: SET_AUTH_TYPE_IS_LOADING,
  payload: status,
});

export const SET_AUTH_TYPE_HAS_ERROR = 'SET_AUTH_TYPE_HAS_ERROR';
export const setAuthTypeHasError = (status) => ({
  type: SET_AUTH_TYPE_HAS_ERROR,
  payload: status,
});

export const FETCH_AUTH_TYPE_ERROR = '[Auth] Fetch auth type error';
export const FETCH_AUTH_TYPE_SUCCESS = '[Auth] Fetch auth type success';
export const FETCH_AUTH_TYPE = '[Auth] Fetch auth type';
export const fetchAuthType = () => ({ type: FETCH_AUTH_TYPE });
