import handleResponseError from './handleResponseError';
import { handleInvalidToken } from '../../utils/Auth';

/**
 * handleResponseBlob - helper function to resolve a blob response
 * @param {Object} response
 * @returns Promise
 */
export default function handleResponseBlob(response) {
  if (response.ok) {
    return response.blob();
  } else {
    handleInvalidToken(response);
    handleResponseError(response);
  }
}
