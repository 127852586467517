import envVariables from './react_env';

const tokenRefreshOverrideInMinutes: string | undefined = envVariables?.tokenRefreshOverrideMinutes;

// get from env variables or default to 9 minutes
const tokenRefreshOverrideInMilliseconds = tokenRefreshOverrideInMinutes
  ? parseInt(tokenRefreshOverrideInMinutes) * 60 * 1000
  : 540000;

export { tokenRefreshOverrideInMilliseconds };
