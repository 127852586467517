import React, { Fragment, ReactElement } from 'react';

type Props = {
  actionButtons: ReactElement[];
};

const ActionButtonsContainer: React.FC<Props> = ({ actionButtons }): ReactElement => {
  return (
    <div className="flex bg-shade-dark-25" data-testid="action-buttons-container">
      {actionButtons.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </div>
  );
};

export default ActionButtonsContainer;
