import { IVizByCollectionIdState } from './vizByCollectionId.interface';

export const vizByCollectionIdInitialState: IVizByCollectionIdState = {
  loading: true,
  hasErrors: false,
  sortableArray: [],
  isReodered: false,
  updatingOrder: false,
  updatingOrderError: false,
};
