import { store } from '../../redux/store';
import { HomeRoutes } from '../../Routing/RouteConstants';
import authTypes from './Constants';

/**
 * getLoginURL - returns the correct login URL depending on the authType and isLogout
 * isLogout gets passed as true from the <Logout /> component
 * @param {boolean} isLogout
 * @returns String
 */
export default function getLoginURL(isLogout: boolean = false) {
  const state = store.getState();

  if (state.users.isSuperAdmin) {
    return `/${HomeRoutes.adminLogin}`;
  } else {
    switch (state.userInfo.authType) {
      case authTypes.db:
        return `/${HomeRoutes.login}`;

      case authTypes.eoauth:
        if (isLogout) {
          return `/${HomeRoutes.eoAuthLogout}`;
        } else {
          return `/${HomeRoutes.eoAuthLogin}`;
        }

      default:
        console.warn('returning default in getLoginUrl');
        return `/${HomeRoutes.login}`;
    }
  }
}
