export const initialState = {
  usersList: [],
  isLoading: true,
  hasLoadingError: false,

  userById: {},
  userByIdIsLoading: false,
  userByIdHasLoadingError: false,

  currentUser: {},
  rememberMe: false,
  isSuperAdmin: false,

  currentUserIsUpdating: false,
  currentUserHasUpdatingError: false,

  roleCount: [],
  roleCountMap: {},
  roleCountIsLoading: true,
  roleCountHasLoadingError: false,
};
