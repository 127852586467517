import axios from 'axios';

export default function removeAuthToken() {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');

  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('refreshToken');

  // reset Authorization in axios to null
  axios.defaults.headers.common['Authorization'] = null;
}
