import { UNSELECTED_CONCEPT_SYMBOL_SIZE } from '@redux/Studio/QueryCreation/constants/graphNode.constant';

/**
 * Resets the graph node color back to the original & the size back to the original
 * This is called when the user deselects a concept from the question graph
 * either by clicking in the container empty space OR by clicking the chevron back icon
 * from the concept sub-menu
 * @param series ECharts graph series
 * @returns ECharts graph series
 */
const resetSelectedConcept = (series: any[]) => {
  return series.map((item) => ({
    ...item,
    data: item.data.map((curr: any) => {
      const newColor = curr.itemStyle.originalColor;

      return {
        ...curr,
        itemStyle: {
          ...curr.itemStyle,
          color: newColor,
        },
        symbolSize: UNSELECTED_CONCEPT_SYMBOL_SIZE,
        emphasis: {
          itemStyle: curr.emphasis.itemStyle
            ? {
                ...curr.emphasis.itemStyle,
                color: newColor,
              }
            : {
                color: newColor,
              },
        },
      };
    }),
  }));
};

export default resetSelectedConcept;
