import * as echarts from 'echarts';

import config from 'card-builder-constants';
const { themes } = config;

export const initEchartsThemes = () => {
  Object.keys(themes.echarts).forEach((item) =>
    echarts.registerTheme(item, themes.echarts[item].theme)
  );
};
