/**
 * getPropertyListWithDC processes and extracts domain properties with relationships.
 * It returns data needed by the Grouped Select dropdown, taking into consideration the viaLabel relationship for every Domain-Concept-property match.
 * @param {DomainWithRelations} domainWithRelations
 * @returns {[]} Grouped Property list.
 */
const getPropertyListWithDC = (domainWithRelations: any) => {
  const result = domainWithRelations?.reduce((result: any, item: any) => {
    const {
      name: domainName,
      uri: domainUri,
      id: domainId,
      color: domainColor,
      concepts = [],
    } = item;

    const retrievedConcepts = concepts?.reduce((conceptResult: any, conceptItem: any) => {
      const { label: conceptName, uri: conceptUri, properties = [] } = conceptItem;

      let outputProperties = [];
      for (const viaLabel of conceptItem.viaLabel) {
        const retrievedProperties = properties?.reduce((propertyResult: any, propertyItem: any) => {
          const { label, propTypeUri, uri, dataClassTags } = propertyItem;

          // get viaLabel URI
          const relation = conceptItem.relations.find(
            (relation: any) => relation.label === viaLabel
          );
          const viaLabelURI = relation?.uri || '';
          const viaLabelRelationTypeURI = relation?.relationTypeUri || '';

          return propertyResult.concat({
            dataClassTags,
            domainColor,
            label,
            value: `${domainName} - ${conceptName} - ${viaLabel} - ${label}`,
            propTypeUri,
            uri,
            domainId,
            domainName,
            domainUri,
            conceptName,
            conceptUri,
            viaLabel,
            viaLabelURI,
            viaLabelRelationTypeURI,
          });
        }, []);
        outputProperties.push(retrievedProperties);
      }

      // prepare concept result
      const preparedConcept = [];
      for (let propertyViaLabelSet of outputProperties) {
        preparedConcept.push({
          label: conceptName,
          domainName,
          domainColor,
          viaLabel: propertyViaLabelSet[0]?.viaLabel,
          options: propertyViaLabelSet,
        });
      }
      return conceptResult.concat(preparedConcept);
    }, []);

    return result.concat(retrievedConcepts);
  }, []);

  return result || [];
};

export default getPropertyListWithDC;
