// ------ users ------
export const SET_USERS = '[Users] Set Users';
export const setUsers = (assets) => ({ type: SET_USERS, payload: assets });

export const SET_IS_LOADING = '[Users] Set Is Loading';
export const setIsLoading = (status) => ({ type: SET_IS_LOADING, payload: status });

export const SET_HAS_LOADING_ERROR = '[Users] Set Has Loading Error';
export const setHasLoadingError = (status) => ({ type: SET_HAS_LOADING_ERROR, payload: status });

export const FETCH_USERS = '[Users] Fetch Users';
export const FETCH_USERS_SUCCESS = '[Users] Fetch Users Success';
export const FETCH_USERS_ERROR = '[Users] Fetch Users Error';
export const fetchUsers = (searchTerm) => ({ type: FETCH_USERS, payload: searchTerm });

// ------ filter users by role id ------
export const FILTER_USERS_BY_ROLE_ID = '[Users] Filter Users by role id';
export const filterUsersByRoleId = (id) => ({ type: FILTER_USERS_BY_ROLE_ID, payload: id });

// ------ users role count------
export const SET_ROLE_COUNT = '[Users] Set Role Count';
export const setRoleCount = (assets) => ({ type: SET_ROLE_COUNT, payload: assets });

export const SET_ROLE_COUNT_IS_LOADING = '[Users] Set Role Count Is Loading';
export const setRoleCountIsLoading = (status) => ({
  type: SET_ROLE_COUNT_IS_LOADING,
  payload: status,
});

export const SET_ROLE_COUNT_HAS_LOADING_ERROR = '[Users] Set Role Count Has Loading Error';
export const setRoleCountHasLoadingError = (status) => ({
  type: SET_ROLE_COUNT_HAS_LOADING_ERROR,
  payload: status,
});

export const FETCH_ROLE_COUNT = '[Users] Fetch Role Count';
export const FETCH_ROLE_COUNT_SUCCESS = '[Users] Fetch Role Count Success';
export const FETCH_ROLE_COUNT_ERROR = '[Users] Fetch Role Count Error';
export const fetchRoleCount = () => ({ type: FETCH_ROLE_COUNT });

// ------ currentUserInfo ------
export const SET_CURRENT_USER_UPDATE = '[Users] Set Current User Info update';
export const setCurrentUserUpdate = (user) => ({
  type: SET_CURRENT_USER_UPDATE,
  payload: { user },
});

export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const resetCurrentUser = () => ({ type: RESET_CURRENT_USER });

export const SET_CURRENT_USER = '[Users] Set Current User Info';
export const setCurrentUser = (user, tenantId, tenantRoles) => ({
  type: SET_CURRENT_USER,
  payload: { user, tenantId, tenantRoles },
});

export const FETCH_CURRENT_USER = '[Users] Fetch Current User';
export const FETCH_CURRENT_USER_SUCCESS = '[Users] Fetch Current User Success';
export const FETCH_CURRENT_USER_ERROR = '[Users] Fetch Current User Error';
export const fetchCurrentUser = () => ({ type: FETCH_CURRENT_USER });

export const SET_REMEMBER_ME = '[Users] Set current user rememberMe option';
export const setRememberMe = (rememberMe) => ({ type: SET_REMEMBER_ME, payload: rememberMe });

export const SET_CURRENT_USER_IS_UPDATING = '[Users] Set Current User Is Updating';
export const setCurrentUserIsUpdating = (status) => ({
  type: SET_CURRENT_USER_IS_UPDATING,
  payload: status,
});

export const SET_CURRENT_USER_HAS_ERROR_UPDATING =
  '[Users] Set Current User Update Has Loading Error';
export const setCurrentUserHasErrorUpdating = (status) => ({
  type: SET_CURRENT_USER_HAS_ERROR_UPDATING,
  payload: status,
});

export const UPDATE_CURRENT_USER = '[Users] Update Current User';
export const UPDATE_CURRENT_USER_SUCCESS = '[Users] Update Current User Success';
export const UPDATE_CURRENT_USER_ERROR = '[Users] Update Current User Error';
export const updateCurrentUser = (payload) => ({ type: UPDATE_CURRENT_USER, payload: payload });
