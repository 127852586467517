export const RESET_ROLES = 'RESET_ROLES';
export const resetRoles = () => ({ type: RESET_ROLES });

export const SET_ROLES = '[Roles] Set Roles';
export const setRoles = (roles) => ({ type: SET_ROLES, payload: roles });

export const SET_USER_ROLES = '[Roles] Set User Roles';
export const setUserRoles = (roles) => ({ type: SET_USER_ROLES, payload: roles });

export const SET_IS_LOADING = '[Roles] Set Is Loading';
export const setIsLoading = (status) => ({ type: SET_IS_LOADING, payload: status });

export const SET_HAS_LOADING_ERROR = '[Roles] Set Has Loading Error';
export const setHasLoadingError = (status) => ({ type: SET_HAS_LOADING_ERROR, payload: status });

export const FETCH_ROLES = '[Roles] Fetch Roles';
export const FETCH_ROLES_SUCCESS = '[Roles] Fetch Roles Success';
export const FETCH_ROLES_ERROR = '[Roles] Fetch Roles Error';
export const fetchRoles = (tenantRoles) => ({ type: FETCH_ROLES, payload: tenantRoles });

// used to set the users roles for RBAC
export const SET_ROLES_TEST = '[Permissions] Set roles for a user';
export const setRolesTest = (roles) => ({ type: SET_ROLES_TEST, payload: roles });
