export const app_json_content_type = 'application/json';

// search localStorage OR sessionStorage (in case user unchecks rememberMe option)
export const getAuthToken = () => localStorage.getItem('token') || sessionStorage.getItem('token');
export const getRefreshToken = () =>
  localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');

export const getHeaders = (headerOpts = {}, contentType = app_json_content_type) => ({
  headers: {
    'Content-Type': contentType,
    Authorization: getAuthToken(),
    ...headerOpts,
  },
});

export const getFileHeaders = () => ({
  headers: {
    'Content-Type': 'application/zip',
    Authorization: getAuthToken(),
  },
});
