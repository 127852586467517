import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  INumericItem,
  ITower360NumericPropsInitialState,
} from '../../constants/tower360.interfaces';
import { Tower360NumericPropsState } from '../../constants/tower360.constants';

// numeric props
const updateTowerNumericPropsReduxSlice = {
  setIsLoadingNumericProps: (state: ITower360NumericPropsInitialState) => {
    state.loading = true;
    state.hasErrors = false;
    state.list = [];
  },
  setHasErrorLoadingNumericProps: (state: ITower360NumericPropsInitialState) => {
    state.hasErrors = true;
    state.loading = false;
  },
  setNumericProps: (
    state: ITower360NumericPropsInitialState,
    action: PayloadAction<INumericItem[]>
  ) => {
    state.list = action.payload;
    state.loading = false;
  },
};

const tower360NumericPropsSlice = createSlice({
  name: 'tower360NumericProps',
  initialState: Tower360NumericPropsState,
  reducers: {
    ...updateTowerNumericPropsReduxSlice,
  },
});

export const { setIsLoadingNumericProps, setHasErrorLoadingNumericProps, setNumericProps } =
  tower360NumericPropsSlice.actions;

export default tower360NumericPropsSlice.reducer;
