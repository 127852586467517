import { token_header_name, invalid_token_text } from './Constants';
import handleDispatchToLogout from './handleDispatchToLogout';

/**
 * handleTokenExpiry - handles logic when token has expired
 * @param {Object} response
 * @param {boolean} isAxios
 * @returns nothing
 */
export default function handleInvalidToken(response, isAxios = false) {
  const expiry_token_header = isAxios
    ? response.response.headers[token_header_name.toLowerCase()]
    : response.headers.get(token_header_name);

  const status = isAxios ? response.response.status : response.status;

  if (status === 401 && expiry_token_header?.indexOf(invalid_token_text) > -1) {
    handleDispatchToLogout();
  } else {
    return;
  }
}
