import {
  SET_NEW_DATASET,
  SET_NEW_DATASET_IS_SAVING,
  SET_NEW_DATASET_HAS_SAVING_ERROR,
  SET_NEW_DATASET_ID,
} from './actions.js';

const initialState = {
  dataSetId: null,
  dataSet: {},
  dataSetIsSaving: false,
  dataSetHasSavingError: false,
};

export function studioDatasetsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_DATASET:
      return {
        ...state,
        dataSet: action.payload,
      };

    case SET_NEW_DATASET_IS_SAVING:
      return {
        ...state,
        dataSetIsSaving: action.payload,
      };

    case SET_NEW_DATASET_HAS_SAVING_ERROR:
      return {
        ...state,
        dataSetHasSavingError: action.payload,
      };

    case SET_NEW_DATASET_ID:
      return {
        ...state,
        dataSetId: action.payload,
      };

    default:
      return state;
  }
}
