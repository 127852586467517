import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IGoalTableColumnInfo,
  IGoalTableStyleColors,
  IGoalTableStyles,
} from '../../../../../../../../Apps/components/visualizations/GoalTable/constants/interfaces/goalTable.interface';
import { IGoalTableStylesState } from '../constants/goalTable.interfaces';
import { goalTableStylesInitialState } from '../constants/goalTable.initialState';

type PayloadType = {
  colorKey: keyof IGoalTableStyleColors;
  color: string;
};

const updateGoalTableColorsReduxSlice = {
  resetState: (state: IGoalTableStylesState) => {
    state.colors = goalTableStylesInitialState.colors;
    state.showDividers = goalTableStylesInitialState.showDividers;
    state.gutters = goalTableStylesInitialState.gutters;
    state.timeAxisFormat = goalTableStylesInitialState.timeAxisFormat;
    state.columnInfo = goalTableStylesInitialState.columnInfo;
  },

  initState: (state: IGoalTableStylesState, action: PayloadAction<IGoalTableStyles>) => {
    state.colors = action.payload.colors;
    state.showDividers = action.payload.showDividers;
    state.gutters = action.payload.gutters;
    state.timeAxisFormat = action.payload.timeAxisFormat;
    state.columnInfo = action.payload.columnInfo;
  },

  updateColor: (state: IGoalTableStylesState, action: PayloadAction<PayloadType>) => {
    state.colors = {
      ...state.colors,
      [action.payload.colorKey]: action.payload.color,
    };
  },

  updateGutters: (state: IGoalTableStylesState, action: PayloadAction<{ gutters: number }>) => {
    state.gutters = action.payload.gutters;
  },

  updateShowDividers: (
    state: IGoalTableStylesState,
    action: PayloadAction<{ showDividers: boolean }>
  ) => {
    state.showDividers = action.payload.showDividers;
  },

  updateTimeAxisFormat: (
    state: IGoalTableStylesState,
    action: PayloadAction<{ timeAxisFormat: string }>
  ) => {
    state.timeAxisFormat = action.payload.timeAxisFormat;
  },

  updateColumnInfo: (
    state: IGoalTableStylesState,
    action: PayloadAction<{ columnInfo: IGoalTableColumnInfo[] }>
  ) => {
    state.columnInfo = action.payload.columnInfo;
  },
};

const goalTableColorsReduxSlice = createSlice({
  name: 'goalTableStyles',
  initialState: goalTableStylesInitialState,
  reducers: {
    ...updateGoalTableColorsReduxSlice,
  },
});

export const {
  updateColor,
  updateGutters,
  updateShowDividers,
  resetState,
  initState,
  updateTimeAxisFormat,
  updateColumnInfo,
} = goalTableColorsReduxSlice.actions;

export default goalTableColorsReduxSlice.reducer;
