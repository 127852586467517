import { API_REQUEST, API_REQUEST_MULTI, API_LOGIN_REQUEST, API_REQUEST_NO_AUTH } from './actions';

import { app_json_content_type } from '../../Api/utils/getAuthHeaders';
import Api from '../../Api/Api';
import { fetchIntercept } from '../../Api/utils';
import { initAuthToken } from '../../utils/Auth/initAuthToken';

const _getBody = (method, payload) => {
  let body;

  if (method === 'POST' || method === 'PUT') {
    body = payload ? JSON.stringify(payload) : '{}';
  }

  return body;
};

const _getOpts = (method, payload, requestOpts = {}) => {
  let opts = {
    method,
    headers: {
      'Content-Type': requestOpts?.headers?.['Content-Type'] || app_json_content_type,
    },
    ...requestOpts,
  };

  if (method === 'POST' || method === 'PUT') {
    opts.body = payload ? JSON.stringify(payload) : '{}';
  }

  return opts;
};

// this middleware care only for API calls
export const api =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    let options = {};

    switch (action.type) {
      case API_REQUEST_MULTI:
        Promise.all(action.requests)
          .then((responses) => {
            dispatch({
              type: action.meta.onSuccess,
              payload: responses,
              props: action.meta.props,
            });
          })
          .catch((err) => {
            dispatch({
              type: action.meta.onError,
              payload: err,
              props: action.meta.props,
            });
          });
        break;

      case API_REQUEST:
        options = _getOpts(action.meta.method, action.payload, action?.meta?.requestOpts || {});
        fetchIntercept(action.meta.url, options)
          .then((data) =>
            dispatch({
              type: action.meta.onSuccess,
              payload: data,
              props: action.meta.props,
            })
          )
          .catch((error) => {
            dispatch({
              type: action.meta.onError,
              payload: {
                error,
                intendedLocation: action.intendedLocation,
              },
              props: action.meta.props,
            });
          });
        break;

      case API_REQUEST_NO_AUTH:
        const method = action.meta.method;

        options = {
          method,
          ...(action?.meta?.requestOpts || {}),
          body: _getBody(method, action.payload),
          headers: {
            'Content-Type': app_json_content_type,
          },
        };

        Api.getNoAuth(action.meta.url, options)
          .then((data) =>
            dispatch({
              type: action.meta.onSuccess,
              payload: data,
              props: action.meta.props,
            })
          )
          .catch((error) => {
            dispatch({
              type: action.meta.onError,
              payload: error,
              props: action.meta.props,
            });
          });
        break;

      case API_LOGIN_REQUEST:
        options = {
          method: action.meta.method,
          headers: {
            'Content-Type': app_json_content_type,
          },
          body: JSON.stringify(action.payload),
        };

        fetch(action.meta.url, options)
          .then((response) =>
            initAuthToken(response, action.meta.rememberMe, action.payload?.refreshToken)
          )
          .then(() =>
            dispatch({
              type: action.meta.onSuccess,
              payload: action.meta.additionalParams,
            })
          )
          .catch((error) =>
            dispatch({
              type: action.meta.onError,
              payload: error,
            })
          );
        break;

      default:
        break;
    }

    return next(action);
  };
