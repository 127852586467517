import { Navigate } from 'react-router-dom-v5-compat';
import { BlossomRoutes } from '../../../../RouteConstants';
import Features from '../../../../../Features/feature-flags';
import { IRoutes } from '../../../../../components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';

export const routes: IRoutes[] = [
  {
    routeItem: {
      path: `${BlossomRoutes.tenantAdmin.user.base}`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const Users = (await import('../../../../../Modules/UserManagement/Users/Users')).default;

        return {
          element: <Users />,
          errorElement: <ErrorPage />,
        };
      },
    },
    permissionRef: BlossomRoutes.tenantAdmin.user.permissions,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${BlossomRoutes.tenantAdmin.dataSecurity.base}/*`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const DataSecurity = (
          await import('../../../../../Modules/UserManagement/DataSecurity/DataSecurity')
        ).default;

        return {
          element: <DataSecurity />,
          errorElement: <ErrorPage />,
        };
      },

      children: [
        {
          path: '',
          element: <Navigate to={`security-clearance`} />,
        },
        {
          path: 'security-clearance',
          async lazy() {
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const SecurityClearance = (
              await import(
                '../../../../../Modules/UserManagement/DataSecurity/SecurityClearance/SecurityClearance'
              )
            ).default;

            return {
              element: <SecurityClearance />,
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: 'client-access',
          async lazy() {
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const ClientAccess = (
              await import(
                '../../../../../Modules/UserManagement/DataSecurity/ClientAccess/ClientAccess'
              )
            ).default;

            return {
              element: <ClientAccess />,
              errorElement: <ErrorPage />,
            };
          },
        },
      ],
    },
    permissionRef: BlossomRoutes.tenantAdmin.dataSecurity.permissions,
    featureFlag: (Features as any).clientAccess,
  },

  {
    routeItem: {
      path: `${BlossomRoutes.tenantAdmin.recovery.base}/*`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const Recovery = (await import('../../../../../Modules/UserManagement/Recovery/Recovery'))
          .default;

        return {
          element: <Recovery />,
          errorElement: <ErrorPage />,
        };
      },

      children: [
        {
          path: '',
          element: <Navigate to={'snapshot'} />,
        },
        {
          path: 'snapshot',
          async lazy() {
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const Snapshot = (
              await import('../../../../../Modules/UserManagement/Recovery/Snapshot/Snapshot')
            ).default;

            return {
              element: <Snapshot />,
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: 'import',
          async lazy() {
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const Import = (
              await import('../../../../../Modules/UserManagement/Recovery/Import/Import')
            ).default;

            return {
              element: <Import />,
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: 'clean-ingestion',
          async lazy() {
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const CleanIngestion = (
              await import(
                '../../../../../Modules/UserManagement/Recovery/CleanIngestion/CleanIngestion'
              )
            ).default;

            return {
              element: <CleanIngestion />,
              errorElement: <ErrorPage />,
            };
          },
        },
      ],
    },
    permissionRef: BlossomRoutes.tenantAdmin.recovery.permissions,
    featureFlag: true,
  },
  {
    routeItem: {
      path: `${BlossomRoutes.tenantAdmin.security.base}/*`,
      async lazy() {
        const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
          .default;
        const Security = (await import('../../../../../Modules/UserManagement/Security/Security'))
          .default;

        return {
          element: <Security />,
          errorElement: <ErrorPage />,
        };
      },

      children: [
        {
          path: '',
          element: <Navigate to={'ssl-certs'} />,
        },

        {
          path: 'ssl-certs',
          async lazy() {
            const ErrorPage = (await import('../../../../../components/ErrorBoundry/ErrorPage'))
              .default;
            const SSLCerts = (
              await import(
                '../../../../../Modules/UserManagement/Security/components/SSLCerts/SSLCerts'
              )
            ).default;

            return {
              element: <SSLCerts />,
              errorElement: <ErrorPage />,
            };
          },
        },
      ],
    },
    permissionRef: BlossomRoutes.tenantAdmin.security.permissions,
    featureFlag: true,
  },
];
