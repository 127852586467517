import {
  SELECTED_CONCEPT_SYMBOL_SIZE,
  UNSELECTED_CONCEPT_COLOR,
  GRAPH_NODE_DEFAULTS,
  graphNodeTooltipHTML,
  SUB_QUESTION_CATEGORY_NAME,
} from '@redux/Studio/QueryCreation/constants/graphNode.constant';
import { subQuestionSymbol } from '@redux/Studio/QueryCreation/constants/subQuestionSymbol.constant';
import { getColorSchemes } from 'utils/ColorSchemes';

const colors = getColorSchemes('models') || [];

/**
 * Iterates the existing graph series data & resets all nodes to their original size
 * and the UNSELECTED_CONCEPT_COLOR
 * Adds the newly added concept as a series w/a larger symbol size and a unique color
 * @param graphOptions Echarts series
 * @param questionDescription
 * @param questionInstanceId
 * @returns Echarts series
 */
export const AddSubQuestionToGraphSeries = (
  graphOptions: any,
  questionDescription: string,
  questionInstanceId: string
) => {
  const addedSubQueryColor = colors[(graphOptions.series[0].data.length + 1) % colors.length];

  return {
    addedSubQueryColor,
    graphOptions: {
      ...graphOptions,
      series: [
        {
          ...graphOptions.series[0],
          data: [
            ...graphOptions.series[0].data.map((item: any) => ({
              ...item,
              symbolSize: GRAPH_NODE_DEFAULTS.symbolSize,
              itemStyle: {
                ...item.itemStyle,
                color: UNSELECTED_CONCEPT_COLOR,
              },
            })),
            {
              ...GRAPH_NODE_DEFAULTS,
              label: {
                ...GRAPH_NODE_DEFAULTS.label,
                width: 150,
                overflow: 'break',
              },
              symbol: subQuestionSymbol,
              symbolSize: SELECTED_CONCEPT_SYMBOL_SIZE,
              itemStyle: {
                color: addedSubQueryColor,
                originalColor: addedSubQueryColor,
              },
              name: questionDescription,
              category: SUB_QUESTION_CATEGORY_NAME,
              id: questionInstanceId,
              isSubQuestion: true,
              tooltip: {
                ...GRAPH_NODE_DEFAULTS.tooltip,
                formatter: (params: any) => {
                  if (params.dataType === 'node') {
                    return graphNodeTooltipHTML(
                      params.color,
                      SUB_QUESTION_CATEGORY_NAME,
                      true,
                      questionDescription
                    );
                  } else {
                    return `<span class="mw5 graph-tooltip">${questionDescription}</span>`;
                  }
                },
              },
            },
          ],
        },
      ],
    },
  };
};
