import { PayloadAction } from '@reduxjs/toolkit';

import { IListColumnsState } from '../constants/conditions.interface';

export const updateListDataColumnsFormattingReduxSlice = {
  toggleApplyColumnStyle: (
    state: IListColumnsState,
    action: PayloadAction<{ index: number; enabled: boolean }>
  ) => {
    state.columns[action.payload.index].columnStyle.enabled = action.payload.enabled;
  },

  updateColumnColor: (
    state: IListColumnsState,
    action: PayloadAction<{ color: string; index: number }>
  ) => {
    state.columns[action.payload.index].columnStyle.style.color = action.payload.color;
  },

  updateColumnAlignment: (
    state: IListColumnsState,
    action: PayloadAction<{
      textAlign: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
      index: number;
    }>
  ) => {
    state.columns[action.payload.index].columnStyle.style.textAlign = action.payload.textAlign;

    // update at negative override level
    state.columns[action.payload.index].columnNegativeOverride.style.textAlign =
      action.payload.textAlign;

    // Update for conditions
    state.columns[action.payload.index].conditions.fixed.config.style.textAlign =
      action.payload.textAlign;
    state.columns[action.payload.index].conditions.lower.config.style.textAlign =
      action.payload.textAlign;
    state.columns[action.payload.index].conditions.upper.config.style.textAlign =
      action.payload.textAlign;
  },

  updateColumnStyle: (
    state: IListColumnsState,
    action: PayloadAction<{ style: React.CSSProperties; index: number }>
  ) => {
    // update at column level
    state.columns[action.payload.index].columnStyle.style = Object.assign(
      state.columns[action.payload.index].columnStyle.style,
      action.payload.style
    );

    // update at negative override level
    state.columns[action.payload.index].columnNegativeOverride.style = Object.assign(
      state.columns[action.payload.index].columnNegativeOverride.style,
      action.payload.style
    );

    // Update for conditions
    state.columns[action.payload.index].conditions.fixed.config.style = Object.assign(
      state.columns[action.payload.index].conditions.fixed.config.style,
      action.payload.style
    );
    state.columns[action.payload.index].conditions.lower.config.style = Object.assign(
      state.columns[action.payload.index].conditions.lower.config.style,
      action.payload.style
    );
    state.columns[action.payload.index].conditions.upper.config.style = Object.assign(
      state.columns[action.payload.index].conditions.upper.config.style,
      action.payload.style
    );
  },

  updateColumnBackgroundColor: (
    state: IListColumnsState,
    action: PayloadAction<{ backgroundColor: string; index: number }>
  ) => {
    state.columns[action.payload.index].columnStyle.style.backgroundColor =
      action.payload.backgroundColor;
  },

  toggleNegativeFormatting: (
    state: IListColumnsState,
    action: PayloadAction<{ index: number; enabled: boolean }>
  ) => {
    state.columns[action.payload.index].columnNegativeOverride.enabled = action.payload.enabled;
  },

  updateNegativeFormattingStyle: (
    state: IListColumnsState,
    action: PayloadAction<{ index: number; style: React.CSSProperties }>
  ) => {
    state.columns[action.payload.index].columnNegativeOverride.style = Object.assign(
      state.columns[action.payload.index].columnNegativeOverride.style,
      action.payload.style
    );
  },

  updateNegativeFormattingColor: (
    state: IListColumnsState,
    action: PayloadAction<{ color: string; index: number }>
  ) => {
    state.columns[action.payload.index].columnNegativeOverride.style.color = action.payload.color;
  },

  updateNegativeFormattingBackgroundColor: (
    state: IListColumnsState,
    action: PayloadAction<{ backgroundColor: string; index: number }>
  ) => {
    state.columns[action.payload.index].columnNegativeOverride.style.backgroundColor =
      action.payload.backgroundColor;
  },

  updateUseAccountingStyle: (
    state: IListColumnsState,
    action: PayloadAction<{ useAccountingStyle: boolean; index: number }>
  ) => {
    state.columns[action.payload.index].columnNegativeOverride.useAccountingStyle =
      action.payload.useAccountingStyle;
  },
};
