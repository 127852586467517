import { IGoalTableStyles } from '../../../../../../../../Apps/components/visualizations/GoalTable/constants/interfaces/goalTable.interface';

const defaultTextColor = '#2B3947';

export const goalTableStylesInitialState: IGoalTableStyles = {
  colors: {
    dividerColor: '#FFF',
    catGroupBg: '#EDEFEF',
    catGroupText: defaultTextColor,
    catBg: '#DDDDDD',
    catText: defaultTextColor,
    valueBg: '#F1F3F3',
    valueText: defaultTextColor,
    goalBg: '#5FD1FD',
    goalText: defaultTextColor,
  },
  gutters: 1,
  showDividers: true,
  timeAxisFormat: 'MM/yyyy',
  columnInfo: [],
};
