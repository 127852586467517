import { NonIndexRouteObject } from 'react-router-dom-v5-compat';

import { HomeRoutes } from '../../../../../RouteConstants';
import { DBLoginPublicRoutes } from './dbLogin.routes';
import { OAuthLoginPublicRoutes } from './oAuth.routes';
import { SuperAdminLoginPublicRoutes } from './adminLogin.routes';

/**
 * These are all of the public routes.
 * It mostly consists of login routes for the various auth types
 */
export const PublicRoutes: NonIndexRouteObject[] = [
  // DB Login routes
  ...DBLoginPublicRoutes,

  // external oAuth (SSO) routes
  ...OAuthLoginPublicRoutes,

  // super admin (pod manager login)
  ...SuperAdminLoginPublicRoutes,

  // other public routes
  {
    path: `/${HomeRoutes.terms}`,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const TermsAndConditions = (
        await import('../../../../../../Modules/Home/TermsAndConditions/TermsAndConditions')
      ).default;

      return {
        element: <HomeLayoutLoginRoutes content={<TermsAndConditions />} />,
        errorElement: <ErrorPage />,
      };
    },
  },
  {
    path: `${HomeRoutes.notAuthorized}`,
    async lazy() {
      const NotAuthorized = (await import('../../../../../../Modules/NotAuthorized/NotAuthorized'))
        .default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;

      return {
        element: <NotAuthorized />,
        errorElement: <ErrorPage />,
      };
    },
  },
  {
    path: `${HomeRoutes.notSetup}`,
    async lazy() {
      const NotSetup = (await import('../../../../../../Modules/NotSetup/NotSetup')).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;

      return {
        element: <NotSetup />,
        errorElement: <ErrorPage />,
      };
    },
  },
];
