import axios from 'axios';
import { handleInvalidToken } from '../../utils/Auth';
import checkTokenExpiry from './checkTokenExpiry';
import { getAuthToken } from './getAuthHeaders';

export default function setupAxiosConfig() {
  // set auth globally
  axios.defaults.headers.common['Authorization'] = getAuthToken();

  // check for token expiry
  axios.interceptors.request.use(async (req) => {
    await checkTokenExpiry();
    return req;
  });

  // handle response error globally
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      handleInvalidToken(error, true);
      return Promise.reject(error);
    }
  );
}
