import { jwtDecode } from 'jwt-decode';
import { differenceInMinutes, fromUnixTime } from 'date-fns';

import url from '../../api-config';

import { app_json_content_type, getAuthToken, getRefreshToken } from './getAuthHeaders';
import { handleDispatchToLogout, removeAuthToken } from '../../utils/Auth';
import { store } from '../../redux/store';
import { SSO_OAUTH_LOG_IN_ERROR } from '../../redux/Auth/actions';
import { authTypes } from '../../utils';
import { initAuthToken } from '../../utils/Auth/initAuthToken';
import parseResponse from './parseResponse';
import handleResponseText from './handleResponseText';

let isRefreshing = false;
let refreshTokenPromise = null;

export const requestRefreshTokenSSO = async (usersOldToken = {}) => {
  if (isRefreshing) {
    return refreshTokenPromise;
  }

  isRefreshing = true;
  refreshTokenPromise = (async () => {
    try {
      // fetch refresh token & reset to new token
      const newTokensResponse = await fetch(url.refreshToken, {
        method: 'POST',
        headers: {
          'Content-Type': app_json_content_type,
        },
        body: JSON.stringify({
          refreshToken: getRefreshToken(),
        }),
      });
      const newTokensTextProcessed = await handleResponseText(newTokensResponse);
      const newTokens = parseResponse(newTokensTextProcessed);

      const state = store.getState();

      const loginResponse = await fetch(url.ssoOauthLogin, {
        method: 'POST',
        headers: {
          'Content-Type': app_json_content_type,
        },
        body: JSON.stringify({
          oauthToken: newTokens?.oauthToken,
          refreshToken: newTokens?.refreshToken,
          userName: usersOldToken?.sub, // This is the username
          tenantId: usersOldToken?.tenantUUID,
          defaultTenant: false,
        }),
      });

      initAuthToken(loginResponse, state.users?.rememberMe, newTokens?.refreshToken);
    } catch (err) {
      console.warn('There was an error getting the refresh tokens:', err);
      // dispatch login error, which will log the user out
      store.dispatch({
        type: SSO_OAUTH_LOG_IN_ERROR,
      });
      removeAuthToken();
    } finally {
      isRefreshing = false;
      refreshTokenPromise = null;
    }
  })();

  return refreshTokenPromise;
};

/**
 * This function checks if a expiryTargetTime is within 5 mins of the set expiration and refreshes the token
 * It only runs if authentication type is set to SSO (eoauth).
 * @param {unixDateTime} expiryTargetTime
 * @returns boolean
 */
const isWithinRefreshRange = ({ refreshTarget = 5, expiryTargetTime }) => {
  const expiryTime = fromUnixTime(expiryTargetTime);
  const minutesDifference = differenceInMinutes(expiryTime, Date.now());
  return Math.abs(minutesDifference) <= refreshTarget;
};

export const isTokenExpired = () => {
  const token = getAuthToken();
  if (token) {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } else {
    return true;
  }
};

export const getExpiryDateInMinutes = () => {
  const token = getAuthToken();
  if (!token) {
    return null;
  }
  const decoded = jwtDecode(token);
  return { expTime: decoded?.exp ?? 0, decodedToken: decoded };
};

const logout = () => {
  removeAuthToken();
  handleDispatchToLogout();
};

const checkTokenExpiry = async () => {
  const expDateObj = getExpiryDateInMinutes();
  if (!expDateObj) {
    logout();
    return;
  }
  const { expTime, decodedToken } = expDateObj;
  if (expTime && isWithinRefreshRange({ refreshTarget: 5, expiryTargetTime: expTime })) {
    const state = store.getState();
    // if SSO authType, request refresh tokens, else redirect to login
    if (state.userInfo.authType === authTypes.eoauth) {
      await requestRefreshTokenSSO(decodedToken);
    } else {
      logout();
    }
  }
};

export default checkTokenExpiry;
