import React, { useContext } from 'react';

import { context } from '../../../../components/Theme/Context';

function ThemedGradient() {
  const theme = useContext(context);

  return (
    <div
      className="absolute z-0 left-0 top-0"
      style={
        theme.useCustomTheme
          ? {
              backgroundColor: theme.colors.background,
              width: '100%',
              height: '100%',
            }
          : {
              background: `radial-gradient(circle, ${theme.colors.background} 0%, var(--black-pearl) 50%)`,
              width: '200%',
              height: '200%',
            }
      }
    ></div>
  );
}

export default ThemedGradient;
