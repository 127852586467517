import React, { useState, useEffect } from 'react';

import { context } from './Context';
import logo from '../../assets/images/logo-light.png';
import navbarLogo from '../../assets/images/logo-dark.svg';
import Features from '../../Features/feature-flags';

import Api from '../../Api/Api';
import url from '../../api-config';
import LoadingIndicator from '../LoadingIndicatorMaterial/LoadingIndicator';

const kobaiDefault = {
  useCustomTheme: false,
  companyName: 'kobai',
  logos: {
    company: logo,
    navbar: navbarLogo,
  },
  colors: {
    background: '#D42667',
    foreground: '#FFF',
  },
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(kobaiDefault);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // prevent making the request to branding if turned off
    if (Features.podBranding) {
      Api.getNoAuth(`${url.brand}/config`)
        .then((res) => {
          const theme = res.useCustomTheme
            ? {
                ...res,
                logos: {
                  company:
                    res.logos.company !== null && res.logos.company.length === 0
                      ? kobaiDefault.logos.company
                      : res.logos.company,
                  navbar:
                    res.logos.navbar !== null && res.logos.navbar.length === 0
                      ? kobaiDefault.logos.navbar
                      : res.logos.navbar,
                  // prop used to show default logo when navbar logo isnt set
                  useDefaultNavbarLogo: res.logos.navbar !== null && res.logos.navbar.length === 0,
                },
              }
            : kobaiDefault;

          setTheme(theme);
          setIsLoading(false);
        })
        .catch((err) => {
          console.warn('error getting theme, therefore setting to kobai default', err);
          setTheme(kobaiDefault);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const { Provider } = context;

  return !isLoading ? (
    <Provider value={theme}>{children}</Provider>
  ) : (
    <LoadingIndicator isLoading />
  );
};

export default ThemeProvider;
