import { timeseriesPropType } from '../../../../utils/Studio/SelectOptions.js';
import { WindowFunctionTypes } from '../Props/modals/WindowFunctionsModal/constants/interfaces/windowFunctions.interface';

export const defaultCollectionName = 'default';

export const inputClassName = 'ba b--silver bg-white w-100 br2';

export const hasTimeseriesProp = (queryMap) => {
  return Object.keys(queryMap).some((concept) => {
    return queryMap[concept].properties.some(
      (prop) => getPropType(prop.propTypeUri) === timeseriesPropType[0]
    );
  });
};

export const getPropType = (propUri) => {
  try {
    return propUri.substring(propUri.lastIndexOf('#') + 1, propUri.length);
  } catch (e) {
    if (propUri) {
      console.log('error in getPropType for: ', propUri);
    }
    return '';
  }
};

/**
 * getOptionsByConcept() returns the options for the select, grouped by concept
 * @param {Object} conceptDefinition
 * @returns Array
 */
const getOptionsByConcept = (conceptDefinition) => {
  const options = conceptDefinition.reduce((acc, prop) => {
    const value = `${prop.conceptId}####${prop.uri}`;

    // map aggregate value & labels
    let aggregates = prop.aggregates
      ? prop.aggregates.map((agg) => {
          const label = agg.columnLabel.length > 0 ? agg.columnLabel : `${prop.label}_${agg.type}`;

          return {
            value: `${value}####${agg.type}`,
            label,
            conceptAndPropName: `${prop.conceptName}[${label}]`,
          };
        })
      : [];

    const windowFunctions = prop.windowFunctions
      ? prop.windowFunctions.reduce((acc, wf) => {
          // prevent showProperty from being an option, as it's not not returned as a data column in question data
          if (wf.type !== WindowFunctionTypes.SHOWPROPERTY) {
            const label = `${prop.label}_${wf.type}`;

            return [
              ...acc,
              {
                value: `${value}####${wf.type}`,
                label,
                conceptAndPropName: `${prop.conceptName}[${label}]`,
              },
            ];
          }
          return acc;
        }, [])
      : [];

    // If prop has aggregate or calc, don't include prop as option
    let propOption =
      prop.aggregates && prop.aggregates.length > 0
        ? []
        : [
            {
              value,
              label: prop.label,
              conceptAndPropName: `${prop.conceptName}[${prop.label}]`,
            },
          ];

    const results = [...aggregates, ...(prop.isRelation ? [] : propOption), ...windowFunctions];
    return [...acc, ...results];
  }, []);

  return options;
};
/**
 * getValuesAndLabels()
 * @param {Object} propMapByConcept
 * @param {String} defaultValue
 * @returns Array
 */
const getValuesAndLabels = (propMapByConcept, defaultValue) => {
  const optGroupOpts = Object.keys(propMapByConcept)
    .sort()
    .map((concept) => {
      return {
        label: concept,
        color: propMapByConcept[concept]?.[0]?.color || '#ccc',
        options: getOptionsByConcept(propMapByConcept[concept], defaultValue),
      };
    });

  return optGroupOpts;
};

export const CalcLabel = 'Calculation';
export const CalcsLabel = 'Calculations';

export const formulaArgDivider = '::';

/**
 * getCalcOptions() - returns the value/labels for calcs
 * The label value is also being sent by the backend, so Calculations vs Calculation makes a big difference
 * we need to be able to return both versions if required. If isRuntimeSettings is set to true Calculations
 * will be returned.
 *
 * @param {Array} calcsList
 * @returns Array
 */
export const getCalcOptions = (calcsList, isRuntimeSettings = true) => {
  const labelToUse = isRuntimeSettings ? CalcsLabel : CalcLabel;
  return [
    {
      label: labelToUse,
      options: calcsList.map((calc) => ({
        value: calc.id,
        label: calc.outputKeyName,
        conceptAndPropName: `${labelToUse}[${calc.outputKeyName}]`,
      })),
    },
  ];
};

/**
 * getOptionsForRuntimeSettings() - returns the options used in the selects
 * for runtimeSettings & calcs
 * @param {Object} existingQueryProperties
 * @param {Array} calcsList
 * @returns Array
 */
export const getOptionsForRuntimeSettings = (
  existingQueryProperties,
  calcsList,
  isRuntimeSettings = true
) => {
  const propMapByConcept = existingQueryProperties.reduce(
    (acc, prop) => ({
      ...acc,
      [prop.conceptName]: acc[prop.conceptName] ? [...acc[prop.conceptName], prop] : [prop],
    }),
    {}
  );

  const optGroupOpts = getValuesAndLabels(propMapByConcept);

  const optGroupCalcs = calcsList.length > 0 ? getCalcOptions(calcsList, isRuntimeSettings) : [];

  return [...optGroupOpts, ...optGroupCalcs];
};

export const ElementTypes = {
  isProp: 'PROP',
  isCalc: 'CALC',
  isAggr: 'AGGR',
  isVal: 'VAL',
};

export const GetSelectDefaultValue = (type, elementProps) => {
  switch (type) {
    case ElementTypes.isProp:
      return elementProps.concept !== null && elementProps.property !== null
        ? `${elementProps.concept}####${elementProps.property}`
        : '';

    case ElementTypes.isAggr:
      return `${elementProps.concept}####${elementProps.property}####${elementProps.aggrType}`;

    case ElementTypes.isCalc:
      return elementProps.calcId;

    default:
      return '';
  }
};

export const GetElementType = (selectValue) => {
  switch (selectValue.length) {
    case 1:
      return ElementTypes.isCalc;

    case 2:
      return ElementTypes.isProp;

    case 3:
      return ElementTypes.isAggr;

    default:
      return '';
  }
};

export const GetElementPropSettings = (elementType, selectValue) => ({
  concept:
    elementType === ElementTypes.isAggr || elementType === ElementTypes.isProp
      ? selectValue[0]
      : null,
  property:
    elementType === ElementTypes.isAggr || elementType === ElementTypes.isProp
      ? selectValue[1]
      : null,
  calcId: elementType === ElementTypes.isCalc ? Number(selectValue[0]) : null,
  aggrType: elementType === ElementTypes.isAggr ? selectValue[2] : null,
});
