import axios from 'axios';
import {
  UPDATE_MAPPING_DEF,
  UPDATE_MAPPING_DEF_SUCCESS,
  UPDATE_MAPPING_DEF_ERROR,
  setMappingDef,
  setMappingDefIsSaving,
  setMappingDefHasSavingError,
  FETCH_MAPPING_DEF,
  FETCH_MAPPING_DEF_SUCCESS,
  FETCH_MAPPING_DEF_ERROR,
  setMappingDefIsLoading,
  setMappingDefHasLoadingError,
  FETCH_CONCEPT_ID_OVERLAPS,
  FETCH_CONCEPT_ID_OVERLAPS_SUCCESS,
  FETCH_CONCEPT_ID_OVERLAPS_ERROR,
  setConceptIdOverlaps,
  setConceptIdOverlapsIsLoading,
  setConceptIdOverlapsHasLoadingError,
  FETCH_PROP_MAPPING,
  FETCH_PROP_MAPPING_SUCCESS,
  FETCH_PROP_MAPPING_ERROR,
  fetchPropMapping,
  setPropMapping,
  setPropMappingIsLoading,
  setPropMappingHasLoadingError,
  clearMappingForProp,
  FETCH_DATASETS,
  FETCH_DATASETS_SUCCESS,
  FETCH_DATASETS_ERROR,
  setDatasets,
  setIsLoading,
  setHasLoadingError,
  FETCH_DATASET_COLUMNS,
  FETCH_DATASET_COLUMNS_SUCCESS,
  FETCH_DATASET_COLUMNS_ERROR,
  setDatasetColumns,
  setDatasetColumnsIsLoading,
  setDatasetColumnsHasLoadingError,
  FETCH_TIMESERIES_DATASET_COLUMNS,
  FETCH_TIMESERIES_DATASET_COLUMNS_SUCCESS,
  FETCH_TIMESERIES_DATASET_COLUMNS_ERROR,
  setTimeseriesDatasetColumns,
  setTimeseriesDatasetColumnsIsLoading,
  setTimeseriesDatasetColumnsHasLoadingError,
  FETCH_CONCEPT_LOOKUPS,
  FETCH_CONCEPT_LOOKUPS_SUCCESS,
  FETCH_CONCEPT_LOOKUPS_ERROR,
  setConceptLookups,
  fetchConceptLookups,
  setConceptLookupsIsLoading,
  setConceptLookupsHasLoadingError,
} from './actions';

import url from '../../../api-config';
import { apiRequest, apiRequestMulti } from '../../Api/actions';

let requestUrl;

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case UPDATE_MAPPING_DEF:
        dispatch(setMappingDefIsSaving(true));
        dispatch(setMappingDefHasSavingError(false));
        const method = action.payload.data.id ? 'PUT' : 'POST';
        dispatch(
          apiRequest(
            method,
            `${url.mapping}/query/${action.payload.queryId}/solution/${action.payload.solutionId}/defs`,
            action.payload.data,
            UPDATE_MAPPING_DEF_SUCCESS,
            UPDATE_MAPPING_DEF_ERROR,
            {
              queryId: action.payload.queryId,
              solutionId: action.payload.solutionId,
              callback: action.payload.callback,
            }
          )
        );
        break;

      case FETCH_MAPPING_DEF:
        dispatch(setMappingDefIsLoading(true));
        dispatch(setMappingDefHasLoadingError(false, -1));

        const requests = [
          axios.get(
            `${url.mapping}/query/${action.payload.queryId}/solution/${action.payload.solutionId}/defs/${action.payload.mappingId}`
          ),
          axios.get(
            `${url.dataSet}/query/${action.payload.queryId}/solution/${action.payload.solutionId}`
          ),
        ];

        dispatch(
          apiRequestMulti(requests, FETCH_MAPPING_DEF_SUCCESS, FETCH_MAPPING_DEF_ERROR, {
            dataSetId: action.payload.dataSetId,
          })
        );
        break;

      case FETCH_CONCEPT_ID_OVERLAPS:
        dispatch(setConceptIdOverlapsIsLoading(true));
        dispatch(setConceptIdOverlapsHasLoadingError(false));
        dispatch(setConceptIdOverlaps([]));
        dispatch(
          apiRequest(
            'POST',
            `${url.mapping}/overlaps/${action.payload.solutionId}`,
            { uri: action.payload.uri },
            FETCH_CONCEPT_ID_OVERLAPS_SUCCESS,
            FETCH_CONCEPT_ID_OVERLAPS_ERROR,
            action.payload
          )
        );
        break;

      case FETCH_CONCEPT_LOOKUPS:
        dispatch(setConceptLookupsIsLoading(true));
        dispatch(setConceptLookupsHasLoadingError(false));
        dispatch(
          apiRequest(
            'POST',
            `${url.mapping}/conceptLookups/${action.payload.solutionId}/${action.payload.dataSetId}`,
            { uri: action.payload.uri },
            FETCH_CONCEPT_LOOKUPS_SUCCESS,
            FETCH_CONCEPT_LOOKUPS_ERROR
          )
        );
        break;

      case FETCH_PROP_MAPPING:
        dispatch(setPropMapping([]));
        dispatch(clearMappingForProp());
        dispatch(setPropMappingHasLoadingError(false));
        dispatch(setPropMappingIsLoading(true));

        dispatch(
          apiRequest(
            'GET',
            `${url.mapping}/query/${action.payload.queryId}/solution/${action.payload.solutionId}/defs`,
            {},
            FETCH_PROP_MAPPING_SUCCESS,
            FETCH_PROP_MAPPING_ERROR
          )
        );
        break;

      case FETCH_DATASETS:
        dispatch(setHasLoadingError(false, -1));
        dispatch(setIsLoading(true));
        requestUrl = `${url.dataSet}/query/${action.payload.queryId}/solution/${action.payload.solutionId}`;
        dispatch(apiRequest('GET', requestUrl, {}, FETCH_DATASETS_SUCCESS, FETCH_DATASETS_ERROR));
        break;

      case FETCH_DATASET_COLUMNS:
        dispatch(setDatasetColumnsIsLoading(true));
        dispatch(setDatasetColumnsHasLoadingError(false));
        requestUrl = `${url.dataSet}/${action.dataSetId}/columns`;
        dispatch(
          apiRequest(
            'GET',
            requestUrl,
            {},
            FETCH_DATASET_COLUMNS_SUCCESS,
            FETCH_DATASET_COLUMNS_ERROR,
            { dataSetId: action.dataSetId }
          )
        );
        break;

      case FETCH_TIMESERIES_DATASET_COLUMNS:
        dispatch(setTimeseriesDatasetColumnsIsLoading(true));
        dispatch(setTimeseriesDatasetColumnsHasLoadingError(false));
        requestUrl = `${url.dataSet}/${action.dataSetId}/columns`;
        dispatch(
          apiRequest(
            'GET',
            requestUrl,
            {},
            FETCH_TIMESERIES_DATASET_COLUMNS_SUCCESS,
            FETCH_TIMESERIES_DATASET_COLUMNS_ERROR,
            { dataSetId: action.dataSetId }
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case UPDATE_MAPPING_DEF_SUCCESS:
        dispatch(setMappingDefIsSaving(false));
        dispatch(fetchPropMapping(action.props.queryId, action.props.solutionId));
        if (action.props.callback) {
          // this will trigger refetching datasets
          action.props.callback();
        }
        break;

      case UPDATE_MAPPING_DEF_ERROR:
        dispatch(setMappingDefIsSaving(false));
        dispatch(setMappingDefHasSavingError(true));
        break;

      case FETCH_MAPPING_DEF_SUCCESS:
        dispatch(
          setMappingDef(action.payload[0].data, action.payload[1].data, action.props.dataSetId)
        );
        dispatch(setMappingDefIsLoading(false));
        break;

      case FETCH_MAPPING_DEF_ERROR:
        dispatch(setMappingDefIsLoading(false));
        dispatch(setMappingDefHasLoadingError(true, action.payload.status));
        break;

      case FETCH_CONCEPT_ID_OVERLAPS_SUCCESS:
        dispatch(
          fetchConceptLookups(action.props.solutionId, action.props.uri, action.props.dataSetId)
        );

        dispatch(setConceptIdOverlapsIsLoading(false));
        dispatch(setConceptIdOverlaps(action.payload));
        break;

      case FETCH_CONCEPT_ID_OVERLAPS_ERROR:
        dispatch(setConceptIdOverlapsIsLoading(false));
        dispatch(setConceptIdOverlapsHasLoadingError(true));
        break;

      case FETCH_CONCEPT_LOOKUPS_SUCCESS:
        dispatch(setConceptLookups(action.payload));
        dispatch(setConceptLookupsIsLoading(false));
        break;

      case FETCH_CONCEPT_LOOKUPS_ERROR:
        dispatch(setConceptLookupsHasLoadingError(true));
        dispatch(setConceptLookupsIsLoading(false));
        break;

      case FETCH_PROP_MAPPING_SUCCESS:
        dispatch(setPropMapping(action.payload));
        dispatch(setPropMappingIsLoading(false));
        break;

      case FETCH_PROP_MAPPING_ERROR:
        dispatch(setPropMappingIsLoading(false));
        dispatch(setPropMappingHasLoadingError(true));
        break;

      case FETCH_DATASETS_SUCCESS:
        dispatch(setIsLoading(false));
        dispatch(setDatasets(action.payload));
        break;

      case FETCH_DATASETS_ERROR:
        dispatch(setIsLoading(false));
        dispatch(setHasLoadingError(true, action.payload));
        break;

      case FETCH_DATASET_COLUMNS_SUCCESS:
        dispatch(setDatasetColumnsIsLoading(false));
        dispatch(setDatasetColumns(action.payload, action.props.dataSetId));
        break;

      case FETCH_DATASET_COLUMNS_ERROR:
        dispatch(setDatasetColumnsIsLoading(false));
        dispatch(setDatasetColumnsHasLoadingError(true));
        break;

      case FETCH_TIMESERIES_DATASET_COLUMNS_SUCCESS:
        dispatch(setTimeseriesDatasetColumnsIsLoading(false));
        dispatch(setTimeseriesDatasetColumns(action.payload, action.props.dataSetId));
        break;

      case FETCH_TIMESERIES_DATASET_COLUMNS_ERROR:
        dispatch(setTimeseriesDatasetColumnsIsLoading(false));
        dispatch(setTimeseriesDatasetColumnsHasLoadingError(true));
        break;

      default:
        break;
    }
  };

export const propertiesModel = [fetchData, processCollections];
