import { FC, ReactElement } from 'react';
import { ErrorBoundaryProps } from './constants/errorBoundary.interface';
import ErrorMessage from './ErrorMessage';
import Refresh from './Refresh';

const ErrorMessageRefresh: FC<ErrorBoundaryProps> = (props): ReactElement => {
  return (
    <ErrorMessage {...props}>
      <Refresh />
    </ErrorMessage>
  );
};

export default ErrorMessageRefresh;
