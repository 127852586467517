import { RouteObject } from 'react-router-dom-v5-compat';

import { IRoutes } from './constants/routes.interface';

/**
 * Iterates the routes and filters out any routes that
 * does not have the feature flag = true
 * @param routes
 * @returns IRoutes[]
 */
export const getRoutes = (routes: IRoutes[]): RouteObject[] => {
  return routes.reduce((acc: RouteObject[], item) => {
    if (item.featureFlag) {
      return [...acc, item.routeItem];
    } else {
      return acc;
    }
  }, []);
};
