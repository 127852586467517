import React, { ReactElement } from 'react';
import Button from '../../Buttons/Button/Button';
import StudioModal, {
  StudioModalProps,
} from '../../../../Modules/Studio/components/StudioModal/StudioModal';
import MenuBar from '../../MenuBar/MenuBar';
import ActionButtonsContainer from '../../../../Modules/Studio/components/StudioModal/ActionButtonsContainer';

const errorModalText = {
  ok: 'OK',
  sorryWeEncounteredAnError: 'Sorry, we encountered an error.',
  somethingWentWrong: 'Something Went Wrong',
};

type Props = {
  errorText?: string;
  onClose?: () => void;
  modalProps?: StudioModalProps;
};

const modalPropDefaults = {
  modalName: errorModalText.somethingWentWrong,
  modalWidth: '720px',
  modalHeight: '350px',
  showWarning: false,
  hideHelpBtn: true,
  showBackBtn: false,
  modalContainerProps: { 'data-testid': 'error-modal' },
};

const ErrorModal: React.FC<Props> = ({ errorText, onClose, modalProps }): ReactElement => (
  <StudioModal showError {...{ ...modalPropDefaults, ...modalProps }} closeModal={onClose}>
    <MenuBar mode="shade" variant="default" label="" actionButtons={[]} />
    <section className="flex-auto flex-column pre" style={{ padding: '40px 80px' }}>
      <div
        role="dialog"
        aria-labelledby="errorModalTitle"
        aria-describedby="errorModalDescription"
        className="flex flex-column items-center"
      >
        <h2 id="errorModalTitle" className="pa-24-bottom fs-18 ma0">
          {errorModalText.sorryWeEncounteredAnError}
        </h2>
        <div id="errorModalDescription" className="fs-16 ws-normal tc lh-28">
          {errorText}
        </div>
      </div>
    </section>

    <ActionButtonsContainer className="justify-end">
      <Button
        variant="primary"
        testId="text-modal-continue-button"
        label={errorModalText.ok}
        onClick={onClose}
      />
    </ActionButtonsContainer>
  </StudioModal>
);

export default ErrorModal;
