import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISubQuestionsState } from './constants/subQuestions.interface';
import { subQuestionInitialState } from './constants/subQuestions.initialState';
import { getSelectedSubQueryOutputsAndRelations } from './helpers';
import {
  INodesMap,
  IQuestionById,
  IQuestionDefinition,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';

const updateSubQuestionsReduxSlice = {
  resetSubQuestionsState: (state: ISubQuestionsState) => {
    state.selectedSubQuestion.color = subQuestionInitialState.selectedSubQuestion.color;
    state.selectedSubQuestion.id = subQuestionInitialState.selectedSubQuestion.id;
    state.selectedSubQuestion.uri = subQuestionInitialState.selectedSubQuestion.uri;
    state.selectedSubQuestion.outputs = subQuestionInitialState.selectedSubQuestion.outputs;
    state.showSubQuestionSettings = subQuestionInitialState.showSubQuestionSettings;
  },

  openSubQuestionSettings: (state: ISubQuestionsState) => {
    state.showSubQuestionSettings = true;
  },

  closeSubQuestionSettings: (state: ISubQuestionsState) => {
    state.showSubQuestionSettings = false;
  },

  setSelectedSubQuestion: (
    state: ISubQuestionsState,
    action: PayloadAction<{
      id: null | number;
      uri: string;
      color: string;
      subQuestionDefinition: IQuestionDefinition;
      referenceDefinition: IQuestionDefinition;
      nodesMap: INodesMap;
      query: IQuestionById;
    }>
  ) => {
    const { id, uri, color, subQuestionDefinition, referenceDefinition, nodesMap, query } =
      action.payload;

    const outputs = getSelectedSubQueryOutputsAndRelations(
      subQuestionDefinition,
      referenceDefinition,
      nodesMap,
      uri,
      query
    );

    state.selectedSubQuestion = {
      id,
      uri,
      color,
      outputs,
    };
  },

  clearSelectedSubQuestion: (state: ISubQuestionsState) => {
    state.selectedSubQuestion = subQuestionInitialState.selectedSubQuestion;
  },

  updateQuestionOutputs: (
    state: ISubQuestionsState,
    action: PayloadAction<{
      subQuestionDefinition: IQuestionDefinition;
      referenceDefinition: IQuestionDefinition;
      nodesMap: INodesMap;
      subQuestionUri: string;
      query: IQuestionById;
    }>
  ) => {
    const { subQuestionDefinition, referenceDefinition, nodesMap, subQuestionUri, query } =
      action.payload;

    const outputs = getSelectedSubQueryOutputsAndRelations(
      subQuestionDefinition,
      referenceDefinition,
      nodesMap,
      subQuestionUri,
      query
    );

    state.selectedSubQuestion.outputs = outputs;
  },
};

const subQuestionsSlice = createSlice({
  name: 'subQuestions',
  initialState: subQuestionInitialState,
  reducers: {
    ...updateSubQuestionsReduxSlice,
  },
});

export const {
  resetSubQuestionsState,
  setSelectedSubQuestion,
  clearSelectedSubQuestion,
  updateQuestionOutputs,
  openSubQuestionSettings,
  closeSubQuestionSettings,
} = subQuestionsSlice.actions;

export default subQuestionsSlice.reducer;
