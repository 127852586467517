import Features from '../../Features/feature-flags';

const barChart = 'bar';
const coxcombChart = 'coxcomb';
const lineChart = 'line';
const pieChart = 'pie';
const textChart = 'text';
const timelineChart = 'timeline';
const treemapChart = 'treemap';
const listChart = 'list';
const scatterPlotChart = 'scatter';
const sankey = 'sankey';
const barLineCombo = 'barLineCombo';
const dualYAxis = 'dualYAxis';
const goalTable = 'goalTable';

const chartTypes = [
  { value: barChart, label: 'Bar' },

  { value: barLineCombo, label: 'Bar/Line Combo' },
  { value: coxcombChart, label: 'Coxcomb' },
  { value: dualYAxis, label: 'Dual-Y Axis' },
  { value: lineChart, label: 'Line' },
  { value: listChart, label: 'List' },
  // {value: 'gauge', label: 'Gauge'},

  // Only add goalTable if feature is true
  ...(Features.useGoalTable ? [{ value: goalTable, label: 'Goal Table' }] : []),

  { value: pieChart, label: 'Pie' },
  { value: sankey, label: 'Sankey' },
  { value: scatterPlotChart, label: 'Scatter plot' },
  { value: textChart, label: 'Text' },
  { value: timelineChart, label: 'Timeline' },
  { value: treemapChart, label: 'Treemap' },
];

const cardDimensions = {
  minCardWidth: 2,
  minCardHeight: 1,
  maxCardWidth: 12,
  maxCardHeight: 3,
};

const charConstants = {
  chartNames: {
    barChart,
    coxcombChart,
    lineChart,
    listChart,
    pieChart,
    textChart,
    timelineChart,
    treemapChart,
    scatterPlotChart,
    sankey,
    barLineCombo,
    dualYAxis,
    goalTable,
  },

  chartTypes,
  cardDimensions,
};

export default charConstants;
