import {
  FETCH_DATA_SOURCES,
  FETCH_DATA_SOURCES_SUCCESS,
  FETCH_DATA_SOURCES_ERROR,
  FETCH_DATA_SOURCES_STANDALONE,
  FETCH_DATA_SOURCES_STANDALONE_SUCCESS,
  FETCH_DATA_SOURCES_STANDALONE_ERROR,
  FETCH_DATA_SOURCE_TABLES,
  FETCH_DATA_SOURCE_TABLES_SUCCESS,
  FETCH_DATA_SOURCE_TABLES_ERROR,
  FETCH_COLUMNS,
  FETCH_COLUMNS_SUCCESS,
  FETCH_COLUMNS_ERROR,
  FETCH_DATA_SOURCE_TYPES,
  FETCH_DATA_SOURCE_TYPES_SUCCESS,
  FETCH_DATA_SOURCE_TYPES_ERROR,
  setDataSources,
  setDataSourceTypes,
  setDataSourceTables,
  setIsLoading,
  setTypesIsLoading,
  setTablesIsLoading,
  setErrorLoadingDataSources,
  setErrorLoadingDataSourceTypes,
  setErrorLoadingDataSourceTables,
  setColumns,
  setColumnsIsLoading,
  setErrorLoadingColumns,
  setDataSourcesStandAlone,
  setErrorLoadingDataSourcesStandAlone,
  setIsLoadingDataSourceStandAlone,
} from './actions';

import url from '../../../api-config';
import { apiRequest } from '../../Api/actions';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_DATA_SOURCES:
        dispatch(setIsLoading(true));
        dispatch(setErrorLoadingDataSources(false, -1));
        dispatch(
          apiRequest(
            'GET',
            `${url.dataSource}/query/${action.payload}`,
            {},
            FETCH_DATA_SOURCES_SUCCESS,
            FETCH_DATA_SOURCES_ERROR
          )
        );
        break;
      case FETCH_DATA_SOURCES_STANDALONE:
        dispatch(setIsLoadingDataSourceStandAlone(true));
        dispatch(setErrorLoadingDataSourcesStandAlone(false, { status: -1 }));
        dispatch(
          apiRequest(
            'GET',
            `${url.dataSource}`,
            {},
            FETCH_DATA_SOURCES_STANDALONE_SUCCESS,
            FETCH_DATA_SOURCES_STANDALONE_ERROR
          )
        );
        break;

      case FETCH_DATA_SOURCE_TABLES:
        if (action.payload.showLoad) {
          dispatch(setTablesIsLoading(true));
        }
        dispatch(setErrorLoadingDataSourceTables(false));
        dispatch(
          apiRequest(
            'GET',
            `${url.dataSet}/query/${action.payload.queryId}/solution/${action.payload.solutionId}/dataSource/${action.payload.sourceId}/tables?pageLimit=${action.payload.pageLimit}&pageNumber=${action.payload.pageNumber}&searchPattern=${action.payload.term}`,
            {},
            FETCH_DATA_SOURCE_TABLES_SUCCESS,
            FETCH_DATA_SOURCE_TABLES_ERROR,
            {},
            {
              signal: AbortSignal.timeout(60000),
            }
          )
        );
        break;

      case FETCH_COLUMNS:
        dispatch(setColumnsIsLoading(true));
        dispatch(setErrorLoadingColumns(false, -1));
        dispatch(
          apiRequest(
            'GET',
            `${url.dataSet}/query/${action.payload.queryId}/solution/${action.payload.solutionId}/dataSource/${action.payload.sourceId}/tableColumns?tableName=${action.payload.tableName}&pageLimit=${action.payload.pageLimit}&pageNumber=${action.payload.pageNumber}&searchPattern=${action.payload.term}`,
            {},
            FETCH_COLUMNS_SUCCESS,
            FETCH_COLUMNS_ERROR
          )
        );
        break;

      case FETCH_DATA_SOURCE_TYPES:
        dispatch(setTypesIsLoading(true));
        dispatch(setErrorLoadingDataSourceTypes(false));
        dispatch(
          apiRequest(
            'GET',
            url.dataSourceType,
            {},
            FETCH_DATA_SOURCE_TYPES_SUCCESS,
            FETCH_DATA_SOURCE_TYPES_ERROR
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_DATA_SOURCES_SUCCESS:
        dispatch(setDataSources(action.payload));
        dispatch(setIsLoading(false));
        break;

      case FETCH_DATA_SOURCES_ERROR:
        dispatch(setIsLoading(false));
        dispatch(setErrorLoadingDataSources(true, action.payload));
        break;

      case FETCH_DATA_SOURCES_STANDALONE_SUCCESS:
        dispatch(setDataSourcesStandAlone(action.payload));
        dispatch(setIsLoadingDataSourceStandAlone(false));
        break;

      case FETCH_DATA_SOURCES_STANDALONE_ERROR:
        dispatch(setIsLoadingDataSourceStandAlone(false));
        dispatch(setErrorLoadingDataSourcesStandAlone(true, action.payload));
        break;

      case FETCH_DATA_SOURCE_TYPES_SUCCESS:
        dispatch(setDataSourceTypes(action.payload));
        dispatch(setTypesIsLoading(false));
        break;

      case FETCH_DATA_SOURCE_TYPES_ERROR:
        dispatch(setTypesIsLoading(false));
        dispatch(setErrorLoadingDataSourceTypes(true));
        break;

      case FETCH_DATA_SOURCE_TABLES_SUCCESS:
        dispatch(setDataSourceTables(action.payload));
        dispatch(setTablesIsLoading(false));
        break;

      case FETCH_DATA_SOURCE_TABLES_ERROR:
        dispatch(setTablesIsLoading(false));
        dispatch(setErrorLoadingDataSourceTables(true));
        break;

      case FETCH_COLUMNS_SUCCESS:
        dispatch(setColumns(action.payload));
        dispatch(setColumnsIsLoading(false));
        break;

      case FETCH_COLUMNS_ERROR:
        dispatch(setColumns([]));
        dispatch(setColumnsIsLoading(false));
        dispatch(setErrorLoadingColumns(true, action.payload.error.status));
        break;

      default:
        break;
    }
  };

export const dataSourcesModel = [fetchData, processCollections];
