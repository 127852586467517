import { NonIndexRouteObject } from 'react-router-dom-v5-compat';

import { HomeRoutes } from '../../../../../RouteConstants';

export const DBLoginPublicRoutes: NonIndexRouteObject[] = [
  {
    id: 'db-login',
    path: HomeRoutes.login,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const Login = (await import('../../../../../../Modules/Authentication/Login/Login')).default;
      const { LoginAction } = await import(
        '../../../../../../Modules/Authentication/Login/utils/login.action'
      );
      return {
        element: <HomeLayoutLoginRoutes content={<Login isAdminLogin={false} />} />,
        errorElement: <ErrorPage />,
        action: LoginAction,
      };
    },
  },
  {
    path: HomeRoutes.confirmSignUp,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const ConfirmSignUp = (
        await import('../../../../../../Modules/Authentication/ConfirmSignup/ConfirmSignup')
      ).default;
      const { ConfirmSignUpAction } = await import(
        '../../../../../../Modules/Authentication/ConfirmSignup/utils/confirmSignup.action'
      );

      return {
        element: <HomeLayoutLoginRoutes content={<ConfirmSignUp />} />,
        errorElement: <ErrorPage />,
        action: ConfirmSignUpAction,
      };
    },
  },
  {
    path: HomeRoutes.forgotPassword,
    async lazy() {
      const HomeLayoutLoginRoutes = (
        await import('../../../../../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
      ).default;
      const ErrorPage = (await import('../../../../../../components/ErrorBoundry/ErrorPage'))
        .default;
      const ForgotPassword = (
        await import('../../../../../../Modules/Authentication/ForgotPassword/ForgotPassword')
      ).default;
      const { ForgotPasswordAction } = await import(
        '../../../../../../Modules/Authentication/ForgotPassword/utils/forgotPassword.action'
      );

      return {
        element: <HomeLayoutLoginRoutes content={<ForgotPassword />} />,
        errorElement: <ErrorPage />,
        action: ForgotPasswordAction,
      };
    },
  },
];
