import { getColorSchemes } from '../../../../utils/ColorSchemes';

const colors = getColorSchemes('models');

/**
 * Assigns a color to the question definition, for each concept
 * Also keeps track of the concept instances
 * @param {IQuestionDefinition} definition
 * @param {IQuestionDefinition} base
 * @returns
 */
export default function getQueryMaps(definition, base) {
  try {
    return Reflect.ownKeys(definition).reduce((acc, curr, index) => {
      return {
        ...acc,
        queryMap: {
          ...acc.queryMap,
          [curr]: {
            ...definition[curr],
            color: colors[index % colors.length],
          },
        },
        conceptInstanceIdMap: {
          ...acc.conceptInstanceIdMap,
          [definition[curr].uri]:
            definition[curr].uri in acc.conceptInstanceIdMap
              ? acc.conceptInstanceIdMap[definition[curr].uri] + 1
              : 1,
        },
      };
    }, base);
  } catch (e) {
    console.warn('error building queryMaps', e);
    return {
      ...base,
      hasError: true,
    };
  }
}
