import config from 'card-builder-constants';

import { PayloadAction } from '@reduxjs/toolkit';
import { ICardBuilderState } from '../cardBuilder.interface';
import { CardBuilderInitialState } from '../cardBuilder-initialState';
import { CardConfigVersions } from '../../../../../../utils/Viz/CardVersions';

const { configs } = config;

const cardConfigReduxSlice = {
  updateCardTitle: (state: ICardBuilderState, action: PayloadAction<string>) => {
    state.cardConfig.cardTitle = action.payload;
  },

  updateChartType: (state: ICardBuilderState, action: PayloadAction<string>) => {
    state.cardConfig.chartType = action.payload;
  },

  updateCardVersion: (state: ICardBuilderState, action: PayloadAction<number>) => {
    state.cardConfig.cardVersion = action.payload;
  },

  initCardTitleAndTypeAndOwner: (state: ICardBuilderState, action: PayloadAction<any>) => {
    state.cardConfig.chartType = action.payload.chartType;
    state.cardConfig.cardTitle = action.payload.cardTitle;
    state.cardConfig.cardOwner = action.payload.cardOwner;
  },

  initEditCardV1: (state: ICardBuilderState, action: PayloadAction<any>) => {
    const { selectedConfig, currentUser } = action.payload;

    state.cardConfig.chartType = selectedConfig?.visualDef?.config?.type;
    state.cardConfig.cardTitle = selectedConfig.visualDef.name;
    state.cardConfig.cardOwner = selectedConfig?.visualDef?.options?.question?.author;
    state.cardConfig.cardDimensions = {
      cardHeight: selectedConfig?.visualDef?.options?.cardHeight,
      cardWidth: selectedConfig?.visualDef?.options?.cardWidth,
    };
    state.cardConfig.cardVersion = CardConfigVersions.v1_5;
    state.cardConfig.cardEditor = {
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
    };
  },

  initEditCard: (state: ICardBuilderState, action: PayloadAction<any>) => {
    const { selectedConfig } = action.payload;

    state.cardConfig.cardVersion = CardConfigVersions.current;
    state.cardConfig.cardId = selectedConfig.id;
    state.cardConfig.cardTitle = selectedConfig.visualDef.config.card.cardTitle;
    state.cardConfig.chartType = selectedConfig.visualDef.chartConfigDetails.chartType;
    state.cardConfig.cardDimensions = selectedConfig.visualDef.config.card.cardDimensions;
    state.cardConfig.cardOwner = selectedConfig.visualDef.config.card.cardOwner;
    state.cardConfig.cardEditor = selectedConfig.visualDef.config.card.cardEditor;
    state.cardConfig.collections = selectedConfig.visualDef.config.card.collections;
    state.cardConfig.dynamicFilters = selectedConfig.visualDef.config.card.dynamicFilters;
    state.cardConfig.axisData = selectedConfig.visualDef.config.chart.dataColumns;
    state.cardConfig.selectedChartConfig = configs[
      selectedConfig.visualDef.chartConfigDetails.library
    ][selectedConfig.visualDef.chartConfigDetails.chartType].find(
      (item: any) => item.name === selectedConfig.visualDef.chartConfigDetails.recipeName
    );
    state.cardConfig.chartOptions = selectedConfig.visualDef.config.chart.chartOptions;
  },

  updateSelectedChartConfig: (state: ICardBuilderState, action: PayloadAction<object>) => {
    state.cardConfig.selectedChartConfig = action.payload;
  },

  updateCollections: (state: ICardBuilderState, action: PayloadAction<any[]>) => {
    state.cardConfig.collections = action.payload;
  },

  updateCardHeight: (state: ICardBuilderState, action: PayloadAction<number>) => {
    state.cardConfig.cardDimensions.cardHeight = action.payload;
  },

  updateCardWidth: (state: ICardBuilderState, action: PayloadAction<number>) => {
    state.cardConfig.cardDimensions.cardWidth = action.payload;
  },

  updateDynamicFilters: (
    state: ICardBuilderState,
    action: PayloadAction<{
      name: string;
      value: string;
    }>
  ) => {
    state.cardConfig.dynamicFilters = {
      ...state.cardConfig.dynamicFilters,
      [action.payload.name]: action.payload.value,
    };
  },

  removeDynamicFilters: (
    state: ICardBuilderState,
    action: PayloadAction<{
      name: string;
    }>
  ) => {
    let updatedFilters: any = Object.assign({}, state.cardConfig.dynamicFilters);

    // remove from dynamicFilters update
    delete updatedFilters[action.payload.name];

    state.cardConfig.dynamicFilters = updatedFilters;
  },

  updateAxisData: (state: ICardBuilderState, action: PayloadAction<object>) => {
    state.cardConfig.axisData = action.payload;
  },

  resetCardConfigState: (state: ICardBuilderState) => {
    state.cardConfig = CardBuilderInitialState.cardConfig;
  },
};

export default cardConfigReduxSlice;
