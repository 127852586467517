import React, { Suspense, useCallback } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useIdleTimer } from 'react-idle-timer';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

//  MUI theme config
import { StyledEngineProvider } from '@mui/material/styles';
import { FlagsProvider } from './Features/FeatureFlags';
import Features from './Features/Features';
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry';
import LoadingIndicator from './components/LoadingIndicatorMaterial/LoadingIndicator';
import Root from './Routing/Layouts/RootLayout/Root';
import ErrorModal from './components/3.0/Modals/ErrorModal/ErrorModal';
import { useSelector } from 'react-redux';
import { resetErrorModalMessage } from './redux/ErrorModal/error-modal-redux-slice';
import { useAppDispatch } from './redux/store';
import { checkTokenExpiry } from 'Api/utils';
import { getAuthToken } from 'Api/utils/getAuthHeaders';
import { tokenRefreshOverrideInMilliseconds } from './constants/refresh.constants';
import { isTokenExpired } from 'Api/utils/checkTokenExpiry';

// Create a client
const queryClient = new QueryClient();

function App() {
  const dispatch = useAppDispatch();
  const errorMessage = useSelector((state) => state.errorModal.errorMessage);
  const handleErrorModalClose = useCallback(() => dispatch(resetErrorModalMessage()), [dispatch]);
  const token = getAuthToken();

  const handleAction = () => {
    if (!token || isTokenExpired(token)) return;
    checkTokenExpiry();
  };

  useIdleTimer({
    onAction: handleAction,
    onIdle: handleAction,
    crossTab: true,
    timeout: tokenRefreshOverrideInMilliseconds / 3, // 1/3 of the time before the token expires if use is idle, it will check if the token needs to be refreshed
    throttle: tokenRefreshOverrideInMilliseconds / 2, // 1/2 of the time before the token expires if use is active, it will check if the token needs to be refreshed
  });

  return (
    <main test-id="kobai-blossom-app" data-testid="kobai-blossom-app" className="kobai bg-white">
      <QueryClientProvider client={queryClient}>
        <FlagsProvider value={Features}>
          <StyledEngineProvider injectFirst>
            <Suspense fallback={<LoadingIndicator isLoading />}>
              <ErrorBoundry>
                <Root />
                {errorMessage && (
                  <ErrorModal errorText={errorMessage} onClose={handleErrorModalClose} />
                )}
              </ErrorBoundry>
            </Suspense>
          </StyledEngineProvider>
        </FlagsProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </main>
  );
}

export default App;
