export const UNSELECTED_CONCEPT_COLOR = '#B3B3B3';
export const UNSELECTED_CONCEPT_SYMBOL_SIZE = 30;
export const SELECTED_CONCEPT_SYMBOL_SIZE = 40;
export const SUB_QUESTION_CATEGORY_NAME = 'SubQuestion';

export const GRAPH_NODE_TOOLTIP_DEFAULTS = {
  backgroundColor: 'rgb(255, 255, 255, 1)',
  borderColor: 'rgb(255, 255, 255, 1)',
  padding: 10,
  textStyle: {
    color: '#1E1E1E',
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  extraCssText: 'box-shadow: rgba(24, 32, 39, 0.15) 0px 4px 6px 0px',
  confine: true,
};

export const GRAPH_NODE_DEFAULTS = {
  value: 14,
  symbolSize: UNSELECTED_CONCEPT_SYMBOL_SIZE,
  originalSymbolSize: UNSELECTED_CONCEPT_SYMBOL_SIZE,
  label: {
    show: true,
    color: '',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 300,
  },
  emphasis: {
    symbolSize: 45,
  },
  tooltip: GRAPH_NODE_TOOLTIP_DEFAULTS,
};

export const graphNodeTooltipHTML = (
  domainColor: string,
  category: string,
  isSubQuestion: boolean,
  name: string
) => `
<span class="mw5 graph-tooltip">
  <span class="fs-10 lh-14 fw3 db">
    ${isSubQuestion ? 'Sub-Question' : ''}
  </span>
  <span class="flex flex-row db ${!isSubQuestion ? 'fw3' : ''}"> 
    <span class="ml1 br-100 ba bw1 flex items-center justify-center mr1 dib ${
      isSubQuestion ? 'b--dashed' : ''
    }" style="margin-top: 4px; height:10px; width: 10px; border-color: ${domainColor}">
      <span class="br-100" style="height:6px; width:6px; background-color: ${domainColor}"></span>
    </span>
    ${category}
  </span>     
  ${
    !isSubQuestion
      ? `<span class="dib pt1">
      ${name}
    </span>`
      : ''
  }
</span>  
`;
