import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Animated } from '../Animated';

class LoadingIndicator extends Component {
  render() {
    const { isLoading, heightClass, customStyle, size = 40 } = this.props;

    return (
      <Animated animationInDuration={800} animationOutDuration={500} isVisible={isLoading}>
        <div
          test-id="loading-indicator-material"
          className={
            'flex justify-center items-center selected-row-blue ' +
            (isLoading ? heightClass || 'vh-75' : 'dn h-0 w-0')
          }
          style={customStyle}
        >
          <CircularProgress color="inherit" className={'selected-row-blue'} size={size} />
        </div>
      </Animated>
    );
  }
}

export default LoadingIndicator;
