import { logOut } from '../../redux/Auth/actions';
import { resetRoles } from '../../redux/Roles/actions';
import { resetCurrentUser } from '../../redux/Users/actions';
import { store } from '../../redux/store';
import getLoginURL from './getLoginURL';

/**
 * Clears all authorization redux state
 */
export const resetAuthorizedUserState = () => {
  const state = store.getState();

  // reset state authorized state, if it's set
  if (
    state.userInfo.isAuth &&
    Object.keys(state.users.currentUser).length > 0 &&
    state.roles.rolesList.length > 0
  ) {
    store.dispatch(logOut());
    store.dispatch(resetCurrentUser());
    store.dispatch(resetRoles([]));
  }
};

/**
 * getRedirectToUrl - appends the "redirectTo" query param to the loginUrl,
 * if coming from anywhere other than /
 * @param {string} loginUrl
 * @returns string
 */
export const getRedirectToUrl = () => {
  const from = window.location.pathname + window.location.search;
  const redirectTo = from !== '/' ? `?redirectTo=${encodeURIComponent(from)}` : '';
  const loginUrl = getLoginURL(false);

  return `${loginUrl}${redirectTo}`;
};

/**
 * resetStateAndGetRedirectUrl - calls helper functions to reset
 * redux state & return the users login url, based on their auth type & login type
 * @returns string
 */
export const resetUserStateAndGetRedirectUrl = () => {
  // Step 1: get redirect url, before clearing redux state
  const redirectTo = getRedirectToUrl();

  // Step 2: clear redux user state
  resetAuthorizedUserState();

  // Step 3: return redirect url
  return redirectTo;
};

/**
 * handleDispatchToLogout - calls resetUserStateAndGetRedirectUrl() &
 * redirects the user to the correct login location
 * This function & "redirectToLogin()" in ProtectedRouteLoader are the only 2 places
 * in the entire app that will redirect the user to the login view
 *
 * Note: Unlike "redirectToLogin()" in ProtectedRouteLoader, this function needs to call
 * window.location.replace since we can't use the `useNavigate` or `redirect` hooks
 * when not inside a component or react-router loader/action function
 */
export default function handleDispatchToLogout() {
  const redirectTo = resetUserStateAndGetRedirectUrl();

  // redirect user to login url
  window.location.replace(redirectTo);
}
