import {
  SELECTED_CONCEPT_SYMBOL_SIZE,
  UNSELECTED_CONCEPT_COLOR,
  UNSELECTED_CONCEPT_SYMBOL_SIZE,
} from '@redux/Studio/QueryCreation/constants/graphNode.constant';

/**
 * Updates the graph node color to show a selected state
 * - Updates the color of all of the other nodes to the UNSELECTED_CONCEPT_COLOR
 * - Updates the size of the selected node
 * @param series ECharts graph series
 * @returns ECharts graph series
 */
export default function updateSelectedConcept(selectedConceptId, series) {
  if (selectedConceptId === null) {
    return series;
  } else {
    return series.map((serie) => ({
      ...serie,
      data: serie.data.reduce((acc, curr) => {
        // update the itemStyle to gallery for concepts that are NOT the selected concept
        // update symbol size to 40 (SELECTED_CONCEPT_SYMBOL_SIZE) for the selected concept
        const newColor = curr.itemStyle.originalColor;
        const isSelected = curr.id === selectedConceptId;

        return [
          ...acc,
          {
            ...curr,
            itemStyle: {
              ...curr.itemStyle,
              color: isSelected ? newColor : UNSELECTED_CONCEPT_COLOR,
            },
            symbolSize: isSelected ? SELECTED_CONCEPT_SYMBOL_SIZE : UNSELECTED_CONCEPT_SYMBOL_SIZE,
            emphasis: {
              itemStyle: curr.emphasis.itemStyle
                ? {
                    ...curr.emphasis.itemStyle,
                    color: newColor,
                  }
                : {
                    color: newColor,
                  },
            },
          },
        ];
      }, []),
    }));
  }
}
