import produce from 'immer';
import { EChartsOption } from 'echarts-for-react';
import { IQuestionDefinition, ISubQueries } from '../constants/queryCreation.interface';
import { getDomainName, getEdges } from './QueryGraphUtils';

type Args = {
  definition: IQuestionDefinition;
  graphOptions: EChartsOption;
  isSubQuestion: boolean;
  subQueries: ISubQueries;
};

const parseDefinitionForRelations = ({
  definition,
  isSubQuestion,
  subQueries,
}: Pick<Args, 'definition' | 'isSubQuestion' | 'subQueries'>) => {
  return Object.keys(definition).reduce((acc: any[], conceptURI) => {
    const domainName = isSubQuestion
      ? subQueries?.[definition[conceptURI]?.subQueryId || 0]?.description
      : getDomainName(conceptURI);

    const concept = isSubQuestion
      ? {
          ...definition[conceptURI],
          label: domainName,
        }
      : definition[conceptURI];

    return [...acc, ...getEdges(conceptURI, concept, isSubQuestion)];
  }, []);
};

const redrawGraphLinks = ({
  definition,
  graphOptions,
  isSubQuestion,
  subQueries,
}: Args): EChartsOption => {
  const nextState = produce(graphOptions, (draftState: EChartsOption) => {
    draftState.series[0].links = parseDefinitionForRelations({
      definition,
      isSubQuestion,
      subQueries,
    });
  });

  return nextState;
};

export default redrawGraphLinks;
