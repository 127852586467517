import React, { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Animated } from '../../../../components/Animated';
import { context } from '../../../../components/Theme/Context';

function ThemedSpinner({ isVisible }) {
  const theme = useContext(context);

  return (
    <Animated
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={isVisible}
      animateOnMount={false}
      className={'flex flex-column items-center z-1'}
    >
      <span className="absolute z-1 bottom-2 pb6 mb4">
        <CircularProgress
          color={'inherit'}
          style={{
            color: theme.colors.background,
          }}
          size={40}
        />
      </span>
    </Animated>
  );
}

export default ThemedSpinner;
