import {
  IConditionalFormattingState,
  IConditionalFormattingTypes,
  IListColNegativeOverride,
  IListColStyle,
  IListColumnData,
  IListColumnsState,
} from './conditions.interface';

export const property = 'property';
export const value = 'value';

export const conditionalFormattingInitialState: IConditionalFormattingTypes = {
  upper: {
    useCondition: false,
    label: 'Upper Limit',
    conditionFormType: 'upper',
    config: {
      type: property,
      condition: '>',
      value: '',
      overrideNegativeFormat: false,
      style: {
        color: '#2B3947',
        backgroundColor: '#FFFFFF',
      },
    },
  },
  lower: {
    useCondition: false,
    label: 'Lower Limit',
    conditionFormType: 'lower',
    config: {
      type: property,
      condition: '<',
      value: '',
      overrideNegativeFormat: false,
      style: {
        color: '#2B3947',
        backgroundColor: '#FFFFFF',
      },
    },
  },
  fixed: {
    useCondition: false,
    label: 'Fixed Condition',
    conditionFormType: 'fixed',
    config: {
      type: property,
      condition: '=',
      value: '',
      overrideNegativeFormat: false,
      style: {
        color: '#2B3947',
        backgroundColor: '#FFFFFF',
      },
    },
  },
};

export const listDataColumnConditionsInitialState: IConditionalFormattingState = {
  conditions: conditionalFormattingInitialState,
  activeTab: '',
};

export const columnStyleInitialState: IListColStyle = {
  enabled: false,
  style: {
    color: '#2B3947',
    backgroundColor: '#FFF',
    textAlign: 'left',
  },
};

export const columnNegativeOverrideInitialState: IListColNegativeOverride = {
  enabled: false,
  style: {
    color: '#FF0000',
    backgroundColor: '#FFF',
  },
  useAccountingStyle: false,
};

export const listDataColumnsInitialState: IListColumnsState = {
  columns: [],
};

export const listDataColumn: IListColumnData = {
  label: '',
  prefix: '',
  propType: '',
  suffix: '',
  truncateTo: '',
  value: '',
  ...listDataColumnConditionsInitialState,
  columnStyle: columnStyleInitialState,
  columnNegativeOverride: columnNegativeOverrideInitialState,
};
