import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tower360InitialState } from './../constants/tower360.constants';
import { ITower360InitialState } from './../constants/tower360.interfaces';

const fetchNavItemsReduxSlice = {
  initiateFetchNavItems: (state: ITower360InitialState) => {
    state.navItems.loading = true;
  },
  fetchNavItemsSuccess: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.navItems.selectedNavItems = action.payload?.selected;
    state.navItems.availableNavItems = action.payload?.available;
    state.navItems.hasErrors = false;
    state.navItems.loading = false;
  },
  fetchNavItemsError: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.navItems.responsePayload = action.payload;
    state.navItems.hasErrors = true;
    state.navItems.loading = false;
  },
};

const fetchNavFiltersReduxSlice = {
  initiateFetchNavFilters: (state: ITower360InitialState) => {
    state.navFilters.loading = true;
  },
  fetchNavFiltersSuccess: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.navFilters.appliedNavFilters = action.payload?.appliedNavFilters;
    state.navFilters.selectedNavFilters = action.payload?.navFilters?.selected;
    state.navFilters.availableNavFilters = action.payload?.navFilters?.available;
    state.navFilters.hasErrors = false;
    state.navFilters.loading = false;
  },
  updateAppliedNavFilter: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.navFilters.appliedNavFilters = action.payload?.appliedNavFilters;
    state.navFilters.hasErrors = false;
    state.navFilters.loading = false;
  },
  fetchNavFiltersError: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.navFilters.responsePayload = action.payload;
    state.navFilters.hasErrors = true;
    state.navFilters.loading = false;
  },
};

const updateTowerNavReduxSlice = {
  toggleEditMode: (state: ITower360InitialState) => {
    state.isEditMode = !state.isEditMode;
  },
  toggleShowFiltersConfig: (state: ITower360InitialState) => {
    // If edit mode is active and showFilters is to be turned off, disable edit mode first
    if (state.isEditMode && state.showFiltersConfig) {
      state.isEditMode = false;
    }
    state.showFiltersConfig = !state.showFiltersConfig;
  },
  closeFiltersConfig: (state: ITower360InitialState) => {
    state.showFiltersConfig = false;
    state.isEditMode = false;
  },
};

const updateFiltersReduxSlice = {
  initiateUpdateFilters: (state: ITower360InitialState) => {
    state.loading = true;
  },
  updateFiltersSuccess: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.responsePayload = action.payload;
    state.hasErrors = false;
    state.loading = false;
  },
  updateFiltersError: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.responsePayload = action.payload;
    state.hasErrors = true;
    state.loading = false;
  },
};

const updateItemsReduxSlice = {
  initiateUpdateItems: (state: ITower360InitialState) => {
    state.loading = true;
  },
  updateItemsSuccess: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.responsePayload = action.payload;
    state.hasErrors = false;
    state.loading = false;
  },
  updateItemsError: (state: ITower360InitialState, action: PayloadAction<any>) => {
    state.responsePayload = action.payload;
    state.hasErrors = true;
    state.loading = false;
  },
};

const towerSlice = createSlice({
  name: 'tower360',
  initialState: Tower360InitialState,
  reducers: {
    ...fetchNavItemsReduxSlice,
    ...fetchNavFiltersReduxSlice,
    ...updateTowerNavReduxSlice,
    ...updateFiltersReduxSlice,
    ...updateItemsReduxSlice,
  },
});

export const {
  initiateFetchNavItems,
  fetchNavItemsSuccess,
  fetchNavItemsError,
  initiateFetchNavFilters,
  fetchNavFiltersSuccess,
  fetchNavFiltersError,
  updateAppliedNavFilter,
  toggleEditMode,
  toggleShowFiltersConfig,
  closeFiltersConfig,
  initiateUpdateFilters,
  updateFiltersSuccess,
  updateFiltersError,
  initiateUpdateItems,
  updateItemsSuccess,
  updateItemsError,
} = towerSlice.actions;

export default towerSlice.reducer;
