import {
  FETCH_QUERIES_LIST,
  FETCH_QUERIES_LIST_SUCCESS,
  FETCH_QUERIES_LIST_ERROR,
  setQueriesList,
  setQueriesListIsLoading,
  setQueriesListHasLoadingError,
  READ_DOMAINS_LIST,
  READ_DOMAINS_LIST_SUCCESS,
  READ_DOMAINS_LIST_FAILED,
  setReadDomainsList,
  setReadDomainsListFailed,
  setReadDomainsListPending,
} from './actions';

import { apiRequest } from '../../Api/actions';
import url from '../../../api-config';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_QUERIES_LIST:
        dispatch(setQueriesList([]));
        dispatch(setQueriesListHasLoadingError(false));
        dispatch(setQueriesListIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            `${url.queries}/model/domain/${action.payload.domainId}`,
            action.data,
            FETCH_QUERIES_LIST_SUCCESS,
            FETCH_QUERIES_LIST_ERROR,
            {
              currentUser: action.payload.currentUser,
            }
          )
        );
        break;

      case READ_DOMAINS_LIST:
        dispatch(setReadDomainsListPending(true));
        dispatch(setReadDomainsListFailed({ status: false, error: '' }));
        dispatch(setReadDomainsList({ domains: [], drafts: [] }));

        dispatch(
          apiRequest(
            'GET',
            `${url.domain}/questions/count`,
            {},
            READ_DOMAINS_LIST_SUCCESS,
            READ_DOMAINS_LIST_FAILED
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_QUERIES_LIST_SUCCESS:
        dispatch(setQueriesListIsLoading(false));
        dispatch(setQueriesList(action.payload, action.props.currentUser));
        break;

      case FETCH_QUERIES_LIST_ERROR:
        dispatch(setQueriesListIsLoading(false));
        dispatch(setQueriesListHasLoadingError(true));
        break;

      case READ_DOMAINS_LIST_SUCCESS:
        dispatch(setReadDomainsList(action.payload));
        dispatch(setReadDomainsListPending(false));
        break;

      case READ_DOMAINS_LIST_FAILED:
        dispatch(setReadDomainsListFailed({ status: true, error: action.payload.error }));
        dispatch(setReadDomainsListPending(false));
        break;

      default:
        break;
    }
  };

export const queriesModel = [fetchData, processCollections];
